import { useEffect, useState } from "react";

import {
  CssBaseline,
  FormControlLabel,
  Box,
  Typography,
  Container,
  InputBase,
  InputLabel,
  IconButton,
  Switch,
  Avatar,
  Alert,
  Input,
  Stack,
  Button,
  Modal,
} from "@mui/material";
import MkButton from "../app/assets/theme/MkButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../app/assets/images/nineP.png";
import { ExternalLink } from "react-external-link";
import { useDispatch } from "react-redux";
import { RegisterApi } from "../features/Register/RegisterApi";
import { useSelector } from "react-redux";
import { errorMessage, resetDefault } from "../features/Register/RegisterSlice";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <ExternalLink href="https://api.9publishers.com/">
        <span> Nine Publishers</span>
      </ExternalLink>
      2022 - {new Date().getFullYear()}
    </Typography>
  );
}

export default function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, data, error } = useSelector((state) => state.AdminRegister);
  //! psw visibility
  const [isVisible, setIsVisible] = useState(false);
  const [imageData, setImageData] = useState("#");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [alert, setAlert] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "30px 10px",
    borderRadius: "10px",
  };
  // useEffect(() => {
  //   console.log(error);
  //   console.log(status);
  //   // if (status === "success") {
  //   //   navigate("/login");
  //   //   dispatch(resetDefault());
  //   // }
  //   if (error) {
  //     dispatch(errorMessage());
  //   } else if (error !== null) {
  //     navigate("/login");
  //     dispatch(resetDefault());
  //   }
  // }, []);
  const handleClose = () => setAlert(false);

  const imageInputChange = (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setImageFile(reader.result);
    };
    // setImageFile(e.target.files[0]);
    // const [file] = e.target.files;
    // console.log(file);
    // if (file) {
    // setImageChange(true);
    setImageData(URL.createObjectURL(e.target.files[0]));
    // }
  };

  //! button submit
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(imageData);
    if (
      firstname === "" ||
      lastname === "" ||
      email === "" ||
      password === "" ||
      !imageFile
    ) {
      setAlert(true);
    } else {
      const formData = new FormData();
      formData.append("first_name", firstname);
      formData.append("last_name", lastname);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("profile", imageFile ?? "");

      console.log(formData);
      dispatch(RegisterApi(formData))
        .unwrap()
        .then((result) => {
          if (result.error) {
            // dispatch(errorMessage());
            setTimeout(() => {
              dispatch(errorMessage());
            }, 1500);
          }
          if (result.admin) {
            navigate("/login");
            dispatch(resetDefault());
            // dispatch(errorMessage());
          }
        });
    }
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      <Modal
        open={alert}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-description"
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              mb: 1,
              width: "100%",
              textAlign: "center",
              color: "red",
            }}
          >
            Note!
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              fontSize: "15px",
              mb: 3,
              width: "100%",
              textAlign: "center",
              color: "red",
            }}
          >
            Please fill all fields to register!
          </Typography>
          <Stack justifyContent="center" alignItems="center">
            <MkButton
              fullWidth
              variant="outlined"
              mkcolor="linear-gradient(310deg, #2152ff, #02c6f3)"
              onClick={() => setAlert(false)}
            >
              Ok
            </MkButton>
          </Stack>
        </Box>
      </Modal>

      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "1rem",
          boxShadow: "rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem",
          padding: "50px 30px 40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <Avatar src={Logo} /> */}
        <Avatar src={Logo} sx={{ width: 56, height: 56 }} />
        <Typography component="h1" variant="h1" sx={{ mb: 2, mt: 2 }}>
          9Publishers
        </Typography>
        <Typography component="h1" variant="h2" sx={{ mb: 3 }}>
          Admin Panel (Register)
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Box sx={{ ml: 15 }}>
            <label htmlFor="contained-button-file">
              <Input
                // accept="image/*"
                inputProps={{ accept: "image/*" }}
                id="contained-button-file"
                type="file"
                name="profile"
                required
                sx={{
                  display: "none",
                }}
                onChange={imageInputChange}
              />

              <Avatar
                sx={{
                  width: 70,
                  height: 70,
                  cursor: "pointer",
                }}
                variant="rounded"
                src={imageData}
              />
            </label>
          </Box>
          <InputLabel htmlFor="firstname" sx={{ mb: 1, fontSize: "1rem" }}>
            FirstName
          </InputLabel>
          <InputBase
            required
            fullWidth
            id="firstname"
            placeholder="First Name"
            name="firstname"
            autoComplete="off"
            autoFocus
            onChange={(e) => setFirstname(e.target.value)}
            sx={{
              mb: 1,
              border: "#d2d6da solid 2px",
              "&.Mui-focused": {
                border: "#35d1f5 solid 2px",
                boxShadow: `0 0 1px 2px #81e3f9de`,
                outline: 0,
              },
            }}
          />
          <InputLabel htmlFor="lastname" sx={{ mb: 1, fontSize: "1rem" }}>
            LastName
          </InputLabel>
          <InputBase
            required
            fullWidth
            id="lastname"
            placeholder="Last Name"
            name="lastname"
            autoComplete="off"
            onChange={(e) => setLastname(e.target.value)}
            sx={{
              mb: 1,
              border: "#d2d6da solid 2px",
              "&.Mui-focused": {
                border: "#35d1f5 solid 2px",
                boxShadow: `0 0 1px 2px #81e3f9de`,
                outline: 0,
              },
            }}
          />
          <InputLabel htmlFor="email" sx={{ mb: 1, fontSize: "1rem" }}>
            Email
          </InputLabel>
          <InputBase
            required
            fullWidth
            id="email"
            // label="Email Address"
            placeholder="admin@example.com"
            name="email"
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              mb: 1,
              border: "#d2d6da solid 2px",
              "&.Mui-focused": {
                border: "#35d1f5 solid 2px",
                boxShadow: `0 0 1px 2px #81e3f9de`,
                outline: 0,
              },
            }}
          />
          <InputLabel htmlFor="password" sx={{ mb: 1, fontSize: "1rem" }}>
            Password
          </InputLabel>
          <InputBase
            required
            fullWidth
            name="password"
            // label="Password"
            placeholder="password"
            type={isVisible ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              border: "#d2d6da solid 2px",
              "&.Mui-focused": {
                border: "#35d1f5 solid 2px",
                boxShadow: `0 0 1px 2px #81e3f9de`,
                outline: 0,
              },
            }}
            endAdornment={
              <IconButton
                sx={{ p: 0 }}
                onClick={() => setIsVisible((prevState) => !prevState)}
              >
                {isVisible ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            }
          />
          {status === "pending" ? (
            <Button
              disabled
              variant="gradient"
              fullWidth
              sx={{
                mt: 2,
                color: "linear-gradient(310deg, #2152ff, #02c6f3)",
              }}
            >
              Loading
            </Button>
          ) : (
            <MkButton
              type="submit"
              fullWidth
              variant="gradient"
              mkcolor="linear-gradient(310deg, #2152ff, #02c6f3)"
              sx={{
                mt: 2,
                color: "linear-gradient(310deg, #2152ff, #02c6f3)",
              }}
            >
              Register
            </MkButton>
          )}

          <Box sx={{ marginTop: "10px", display: error ? "" : "none" }}>
            <Alert variant="filled" severity="error">
              {error}
            </Alert>
          </Box>
        </Box>
        <div
          style={{
            marginTop: "10px",
            width: "100%",
          }}
        >
          <Link to="/login">Already Have an account?</Link>
        </div>
      </Box>
      <Copyright sx={{ mt: 6 }} />
    </Container>
  );
}
