import { createAsyncThunk } from "@reduxjs/toolkit";
import useFetch, { api } from "../../app/hooks";

export const getInvoiceList = createAsyncThunk(
  "getInvoiceList/getInvoiceList",
  async (
    { page, limit, id, division_id, invoice_code, staff, start, end },
    { getState }
  ) => {
    console.log(limit);
    console.log(page);
    console.log(id);
    console.log(division_id);
    const { token, admin } = getState().loginInfo;
    const result = useFetch({
      url: `invoices?admin_id=${admin._id}&page=${page}&limit=${limit}&publisher_id=${id}&division_id=${division_id}&invoice_code=${invoice_code}&staff=${staff}&start=${start}&end=${end}`,
      method: "GET",
      token: token,
    });
    console.log(result);
    return result;
  }
);

export const addInvoices = createAsyncThunk(
  "addInvoice/addInvoice",
  async ({ data }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    console.log(data);
    const response = await fetch(
      `${api}/admin/invoices?admin_id=${admin._id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
    // const result = usePostForm({
    //   url: `users?admin_id=${admin._id}`,
    //   method: "POST",
    //   token: token,
    //   data: data,
    // });
    // return result;
  }
);
export const updateInvoices = createAsyncThunk(
  "updateInvoices/updateInvoices",
  async ({ data, id }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    console.log(data);
    const response = await fetch(
      `${api}/admin/invoices/${id}?admin_id=${admin._id}&_method=PUT`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
    // const result = usePostForm({
    //   url: `users?admin_id=${admin._id}`,
    //   method: "POST",
    //   token: token,
    //   data: data,
    // });
    // return result;
  }
);
