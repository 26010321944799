import { createAsyncThunk } from "@reduxjs/toolkit";
import useFetch, { api } from "../../app/hooks";

export const getCouriersList = createAsyncThunk(
  "getCouriersList/getCouriersList",
  async ({ page, limit, id }, { getState }) => {
    console.log(id);
    console.log(limit);
    console.log(page);
    const { token, admin } = getState().loginInfo;
    const result = useFetch({
      url: `couriers?admin_id=${admin._id}&page=${page}&limit=${limit}&publisher_id=${id}`,
      method: "GET",
      token: token,
    });
    console.log(result);
    return result;
  }
);

export const addCourier = createAsyncThunk(
  "addCourier/addCourier",
  async ({ data }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    console.log(data);
    const response = await fetch(
      `${api}/admin/couriers?admin_id=${admin._id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
    // const result = usePostForm({
    //   url: `users?admin_id=${admin._id}`,
    //   method: "POST",
    //   token: token,
    //   data: data,
    // });
    // return result;
  }
);

export const updateCourier = createAsyncThunk(
  "updateCourier/updateCourier",
  async ({ data, id }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    console.log(data);
    const response = await fetch(
      `${api}/admin/couriers/${id}?admin_id=${admin._id}&_method=PUT`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
    // const result = usePostForm({
    //   url: `users?admin_id=${admin._id}`,
    //   method: "POST",
    //   token: token,
    //   data: data,
    // });
    // return result;
  }
);

export const CourierDelete = createAsyncThunk(
  "CourierDelete/CourierDelete",
  async ({ id, publisher_id }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    const result = await useFetch({
      url: `couriers/${id}?admin_id=${admin._id}&publisher_id=${publisher_id}`,
      method: "DELETE",
      token: token,
    });
    return result;
  }
);
