import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Input,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Avatar,
  InputLabel,
  Typography,
} from "@mui/material";
import CompanyInputs from "./CompanyInputs";
import { addPublisher } from "../../features/company/CompanyApi";
import { Add } from "@mui/icons-material";
import DefaultImage from "../../app/assets/images/nineP.png";
import { handleCitiesOptions } from "../../features/location/CitySlice";
import { handleTownshipOptions } from "../../features/location/TownshipSlice";

export default function AddCompany() {
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState(true);
  const dispatch = useDispatch();
  const [imageData, setImageData] = useState(DefaultImage);
  const [bannerImageData, setBannerImageData] = useState("#");
  const [division, setDivision] = useState(null);
  const [township, setTownship] = useState(null);
  const [city, setCity] = useState(null);
  const [nameChange, setNameChange] = useState("");
  const [addressChange, setAddressChange] = useState("");
  const [phoneChange, setPhoneChange] = useState("");
  const [contactPersonChange, setContactPersonChange] = useState("");
  const [contactPersonPhChange, setContactPersonPhChange] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [bannerFile, setBannerFile] = useState(null);

  const handleClickOpen = () => {
    setCheck(true);
    setOpen(true);
    setImageData(DefaultImage);
    setBannerImageData("#");
  };

  const handleClose = () => {
    setCheck(true);
    setOpen(false);
    setImageData(DefaultImage);
    setBannerImageData("#");
    dispatch(handleCitiesOptions());
    dispatch(handleTownshipOptions());
  };

  const handleCheck = (e) => {
    setCheck(e.target.checked);
  };

  const handleAdd = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", nameChange);
    formData.append("address", addressChange);
    formData.append("phone", phoneChange);
    formData.append("contact_person", contactPersonChange);
    formData.append("contact_person_phone", contactPersonPhChange);
    formData.append("active", check === true ? Number(1) : Number(0));
    formData.append("township_id", township._id ? township._id : township.id);
    formData.append("city_id", city._id ? city._id : city.id);
    formData.append("division_id", division._id ? division._id : division.id);
    formData.append("publisher_logo", imageFile ? imageFile : "");
    formData.append("banner_image", bannerFile ? bannerFile : "");
    // for (const pair of formData.entries()) {
    //   console.log(pair);
    // }
    // return;
    // console.log(formData);
    dispatch(addPublisher({ data: formData }));
    //close the model box
    setOpen(false);
  };
  const imageInputChange = (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      // console.log(reader.result);
      setImageFile(reader.result);
      //base64encoded string
    };
    // const [file] = e.target.files;
    // console.log(e.target.files[0]);
    // setImageFile(e.target.files[0]);
    // if (file) {
    // setImageFile(file);
    setImageData(URL.createObjectURL(e.target.files[0]));
    // }
  };

  const bannerImageInputChange = (e) => {
    // const [file] = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      // console.log(reader.result);
      setBannerFile(reader.result);
      //base64encoded string
    };
    // console.log(e.target.files[0]);
    // setBannerFile(e.target.files[0]);
    // if (file) {
    // setBannerFile(file);
    setBannerImageData(URL.createObjectURL(e.target.files[0]));
    // }
  };
  // const imageInputChange = (e) => {
  //   const [file] = e.target.files;
  //   if (file) {
  //     setImageData(URL.createObjectURL(file));
  //   }
  // };

  // const bannerImageInputChange = (e) => {
  //   const [file] = e.target.files;
  //   if (file) {
  //     setBannerImageData(URL.createObjectURL(file));
  //   }
  // };

  return (
    <>
      <Button startIcon={<Add />} onClick={handleClickOpen}>
        Add
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        component="form"
        onSubmit={handleAdd}
      >
        <DialogContent>
          <Stack direction="column" spacing={1} justifyContent="space-between">
            <DialogContentText sx={{ padding: "10px 35px " }}>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
              >
                Add Publisher
              </Typography>
            </DialogContentText>
            <Box sx={{ padding: "0px 35px" }}>
              <InputLabel
                htmlFor="contained-button-file-banner"
                sx={{ fontSize: "14.5px", mb: 1 }}
              >
                Choose Publisher Profile Image
              </InputLabel>
              <label htmlFor="contained-button-file">
                <Input
                  // accept="image/*"
                  inputProps={{ accept: "image/*" }}
                  id="contained-button-file"
                  type="file"
                  name="publisher_logo"
                  sx={{
                    display: "none",
                  }}
                  onChange={imageInputChange}
                />
                <Box
                  sx={{
                    border: "1px solid black",
                    borderRadius: "10px",
                  }}
                  width={80}
                  height={80}
                >
                  <Avatar
                    sx={{
                      width: "80%",
                      height: "80%",
                      margin: "auto",
                      cursor: "pointer",
                      mt: 1,
                    }}
                    variant="rounded"
                    src={imageData}
                  >
                    9Publishers
                  </Avatar>
                </Box>
              </label>
            </Box>
          </Stack>
          <Box sx={{ padding: "0px 35px", mt: 3 }}>
            <InputLabel
              htmlFor="contained-button-file-banner"
              sx={{ fontSize: "14.5px", mb: 1 }}
            >
              Choose Publisher Banner Image
            </InputLabel>
            <label htmlFor="contained-button-file-banner">
              <Input
                // accept="image/*"
                inputProps={{ accept: "image/*" }}
                id="contained-button-file-banner"
                type="file"
                name="banner_image"
                sx={{
                  display: "none",
                }}
                onChange={bannerImageInputChange}
              />
              <Box
                sx={{
                  // border: '1px solid black',
                  borderRadius: "10px",
                }}
                width={"100%"}
                height={"250px"}
              >
                <Avatar
                  sx={{
                    width: "100%",
                    height: "100%",
                    margin: "auto",
                    cursor: "pointer",
                    fontSize: "2rem",
                  }}
                  variant="rounded"
                  src={bannerImageData}
                >
                  Publisher Banner
                </Avatar>
              </Box>
            </label>
          </Box>
          <CompanyInputs
            handleCheck={handleCheck}
            setDivision={setDivision}
            setCity={setCity}
            setTownship={setTownship}
            division={division}
            city={city}
            township={township}
            nameChange={nameChange}
            setNameChange={setNameChange}
            phoneChange={phoneChange}
            setPhoneChange={setPhoneChange}
            addressChange={addressChange}
            setAddressChange={setAddressChange}
            contactPersonChange={contactPersonChange}
            setContactPersonChange={setContactPersonChange}
            contactPersonPhChange={contactPersonPhChange}
            setContactPersonPhChange={setContactPersonPhChange}
          />
        </DialogContent>
        <DialogActions sx={{ padding: "15px 35px" }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
