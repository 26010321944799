import React, { useEffect, useState } from "react";
import ProductTable from "./ProductTable";

function ProductTabView({ setTabIndex }) {
    return (
        <>
            <ProductTable setTabIndex={setTabIndex} />
        </>
    );
}

export default ProductTabView;
