import { createAsyncThunk } from "@reduxjs/toolkit";

import useFetch from "../../app/hooks";

export const EditionDropdownList = createAsyncThunk(
  "EditionDropdownList/EditionDropdownList",
  async (_, { getState }) => {
    const { token, admin } = getState().loginInfo;
    const result = await useFetch({
      url: `get_editions?admin_id=${admin._id}`,
      method: "GET",
      token: token,
    });
    return result;
  }
);
