import React, { useState } from "react";
import { Box } from "@mui/material";
import ExpenseCategoryDrawer from "./ExpenseCategoryDrawer";
import MkButton from "../../app/assets/theme/MkButton";
import { ExpenseCategoryUpdate } from "../../features/expense_category/ExpenseCategoryApi";
import EditIcon from "@mui/icons-material/Edit";

export default function ExpenseCategoryUpdateDrawer({ inputValues }) {
  /* +++++++++++++++++ Drawer +++++++++++++++++ */

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  /* --------------------------------------------  */

  return (
    <Box>
      <ExpenseCategoryDrawer
        open={open}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        action={ExpenseCategoryUpdate}
        inputValues={{
          id: inputValues._id,
          select: inputValues.icon,
          name: inputValues.name,
        }}
      >
        {/* <MkButton
                    mkcolor={`linear-gradient(310deg, #2152ff, #02c6f3)`}
                    size="small"
                    textTransform="capitalize"
                    onClick={handleDrawerOpen}
                >
                    Edit
                </MkButton> */}
        <EditIcon
          onClick={handleDrawerOpen}
          fontSize="small"
          sx={{ cursor: "pointer", color: "#2152ff" }}
        />
      </ExpenseCategoryDrawer>
    </Box>
  );
}
