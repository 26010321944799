import { createSlice } from "@reduxjs/toolkit";
import {
  CourierDelete,
  addCourier,
  getCouriersList,
  updateCourier,
} from "./CouriersApi";

export const CouriersList = createSlice({
  name: "Courier",
  initialState: {
    addStatus: null,
    updateStatus: null,
    couriers: null,
    courierStatus: null,
    pagination: {},
    paginationData: {
      page: 1,
      limit: 10,
    },
  },
  reducers: {
    changePaginationData: (state, { payload }) => {
      state.paginationData = payload;
    },
    resetCouriers: (state) => {
      state.couriers = null;
    },
    handleCourier: (state, payload) => {
      console.log(payload);
      const { data, ...rest } = payload;
      // console.log("From handle book" + data);
      // console.log("From handle book" + rest);
      state.couriers = data;
      state.pagination = rest;
    },
    handlerCourier: (state) => {
      state.couriers = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCouriersList.fulfilled, (state, { payload }) => {
        // console.log("allBookList", payload);
        CouriersList.caseReducers.handleCourier(state, payload);
      })
      .addCase(addCourier.pending, (state, { payload }) => {
        // console.log("add Book", payload);
        state.addStatus = "pending";
      })
      .addCase(addCourier.fulfilled, (state, { payload }) => {
        // console.log("add Book", payload);
        CouriersList.caseReducers.handleCourier(state, payload);
        state.addStatus = "success";
      })
      .addCase(updateCourier.pending, (state, { payload }) => {
        console.log(payload);
        state.updateStatus = "pending";
      })
      .addCase(updateCourier.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.updateStatus = "success";
        CouriersList.caseReducers.handleCourier(state, payload);
      })
      .addCase(CourierDelete.fulfilled, (state, { payload }) => {
        CouriersList.caseReducers.handleCourier(state, payload);
      });
  },
});
export const CourierSlice = CouriersList.reducer;
export const { changePaginationData, handlerCourier, resetCouriers } =
  CouriersList.actions;
