import {
  Box,
  Divider,
  Drawer,
  IconButton,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import MkButton from "../../../app/assets/theme/MkButton";
import MkAutoComplete from "../../../app/assets/theme/MkAutoComplete";
import InputFormComponent from "../../../app/components/Form/InputFormComponent";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

export default function DivisionDrawer({
  open: drawerOpen,
  onClose: drawerOnClose,
  inputValues = null,
  action,
  children,
}) {
  const [country, setCountry] = useState({
    id: "644a27f554115945e338282a",
    name: "Myanmar",
  });
  const [name, setName] = useState(null);
  const [code, setCode] = useState(null);
  const dispatch = useDispatch();

  // extra
  const { divisionOptions, paginationData } = useSelector(
    (state) => state.DivisionListSlice
  );

  const closeNow = () => {
    drawerOnClose();
    setCode(null);
    setName(null);
    setCountry({ id: "644a27f554115945e338282a", name: "Myanmar" });
  };

  const addDivision = () => {
    const data = {
      country_id: country?.id,
      name: name,
      short_code: code,
    };
    if ((country, name, code)) {
      // For update
      if (inputValues?._id) {
        dispatch(
          action({
            data: data,
            id: inputValues?._id,
            page: paginationData.page,
            limit: paginationData.limit,
          })
        );
        closeNow();
        return;
      }
      // For add
      dispatch(
        action({
          data: data,
          page: paginationData.page,
          limit: paginationData.limit,
        })
      );
    }
    closeNow();
  };

  useEffect(() => {
    if (inputValues) {
      setName({ id: inputValues._id, name: inputValues.name });
      setCode(inputValues.short_code);
    }
  }, [drawerOpen]);

  return (
    <div>
      {children}
      <Drawer anchor="right" open={drawerOpen} onClose={closeNow}>
        <DrawerHeader>
          <IconButton onClick={closeNow}>
            <CloseIcon />
          </IconButton>

          {/* here is as your desire */}

          <Typography variant="subtitle2" sx={{ marginRight: "40px" }}>
            {inputValues?.name ? `Update ${inputValues?.name} ` : "Add "}
            Division
          </Typography>

          {/* --------------------------------------------  */}
        </DrawerHeader>
        <Divider />
        <Box sx={{ width: 450 }}>
          <Stack
            direction="column"
            spacing={2}
            justifyContent="center"
            sx={{ padding: 5 }}
          >
            <InputLabel htmlFor="select" sx={{ fontSize: "14.5px" }}>
              Select Country
            </InputLabel>
            <MkAutoComplete
              disabled={true}
              name="countries"
              noOptionsText="No related Country"
              fullWidth
              placeholder="Country"
              options={[{ id: "644a27f554115945e338282a", name: "Myanmar" }]}
              getOptionLabel={(option) => option?.name ?? option}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                setCountry(newValue);
              }}
              value={country || null}
            />
            <InputLabel htmlFor="select" sx={{ fontSize: "14.5px" }}>
              Select Division
            </InputLabel>
            <MkAutoComplete
              freeSolo={true}
              name="divisions"
              noOptionsText="No related Division"
              fullWidth
              placeholder="Division"
              options={divisionOptions}
              getOptionLabel={(option) => option?.name ?? option}
              // getOptionLabel={(option) => console.log(option)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  setName(newValue);
                  return;
                }
                setName(null);
                setCode("");
              }}
              onTextChange={(e) => {
                setName(e.target.value);
              }}
              value={name || null}
            />
            <InputFormComponent
              name="Division Short Code"
              placeholder="Division Short Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              label="Division Short Code"
            />
            <MkButton
              mkcolor={`linear-gradient(310deg, #2152ff, #02c6f3)`}
              size="small"
              onClick={addDivision}
            >
              {inputValues ? `Update` : "Add"}
            </MkButton>
          </Stack>

          {/* --------------------------------------------  */}
        </Box>
      </Drawer>
    </div>
  );
}
