import { TableCell, TableRow, Typography, Stack } from "@mui/material";
import React from "react";
import "font-awesome/css/font-awesome.min.css";
import MkButton from "../../app/assets/theme/MkButton";
import { GenreDelete } from "../../features/genre/GenreApi";
import { useDispatch } from "react-redux";
import IconWrap from "../../app/components/IconWrap";
import GenreUpdateDrawer from "./GenreUpdateDrawer";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";

function GenreTableBody({ item }) {
  const dispatch = useDispatch();
  // extra
  const { paginationData } = useSelector((state) => state.GenreSlice);
  return (
    <TableRow>
      <TableCell>
        <Typography variant="caption">{item.name}</Typography>
      </TableCell>

      {/* <TableCell sx={{ width: 120 }} align="center">
        <GenreUpdateDrawer inputValues={item} />
      </TableCell>

      <TableCell sx={{ width: 120 }} align="center">
        <MkButton
          mkcolor={`linear-gradient(310deg, #141727, #3a416f)`}
          size="small"
          textTransform="capitalize"
          onClick={() =>
            dispatch(
              GenreDelete({
                id: item.id,
                page: paginationData.page,
                limit: paginationData.limit,
              })
            )
          }
        >
          Delete
        </MkButton>
      </TableCell> */}

      <TableCell sx={{ width: 100 }} align="center">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <GenreUpdateDrawer inputValues={item} />
          <DeleteIcon
            fontSize="small"
            onClick={() =>
              dispatch(
                GenreDelete({
                  id: item._id,
                  page: paginationData.page,
                  limit: paginationData.limit,
                })
              )
            }
            sx={{ cursor: "pointer", color: "red" }}
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
}

export default GenreTableBody;
