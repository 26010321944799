import { useEffect, useState } from "react";

import { getToken } from "../features/login/LoginApi";

import {
  CssBaseline,
  FormControlLabel,
  Box,
  Typography,
  Container,
  InputBase,
  InputLabel,
  IconButton,
  Switch,
  Avatar,
  Alert,
  Button,
} from "@mui/material";
import { useDispatch } from "react-redux";
import MkButton from "../app/assets/theme/MkButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../app/assets/images/nineP.png";
import { useSelector } from "react-redux";
import { ExternalLink } from "react-external-link";
import { resetLogin } from "../features/login/LoginSlice";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <ExternalLink href="https://api.9publishers.com/">
        <span> Nine Publishers</span>
      </ExternalLink>
      2022 - {new Date().getFullYear()}
    </Typography>
  );
}

export default function Login() {
  const dispatch = useDispatch();
  const { error, status } = useSelector((state) => state.loginInfo);
  const [checked, setChecked] = useState(false);
  //! psw visibility
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const handleChange = (e) => {
    setChecked(e.target.checked);
  };
  let expiredDate = new Date();
  let currentDate = new Date();

  checked
    ? expiredDate.setDate(currentDate.getDate() + 7)
    : expiredDate.setDate(currentDate.getDate() + 1);
  expiredDate = expiredDate.toISOString().slice(0, 19).replace("T", " ");
  //! button submit
  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(expiredDate);
    const formData = new FormData(event.currentTarget);
    const data = {
      email: formData.get("email"),
      password: formData.get("password"),
      expired_date: expiredDate,
    };
    // console.log(data);
    dispatch(getToken(data));
  };
  console.log(status);
  return (
    <Container
      maxWidth="xs"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <CssBaseline />

      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "1rem",
          boxShadow: "rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem",
          padding: "50px 30px 80px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <Avatar src={Logo} /> */}
        <Avatar src={Logo} sx={{ width: 56, height: 56 }} />
        <Typography component="h1" variant="h1" sx={{ mb: 2, mt: 2 }}>
          9Publishers
        </Typography>
        <Typography component="h1" variant="h2" sx={{ mb: 5 }}>
          Admin Panel (Login)
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <InputLabel htmlFor="email" sx={{ mb: 1, fontSize: "1rem" }}>
            Email
          </InputLabel>
          <InputBase
            required
            fullWidth
            id="email"
            // label="Email Address"
            placeholder="admin@example.com"
            name="email"
            autoComplete="email"
            autoFocus
            sx={{
              mb: 3,
              border: "#d2d6da solid 2px",
              "&.Mui-focused": {
                border: "#35d1f5 solid 2px",
                boxShadow: `0 0 1px 2px #81e3f9de`,
                outline: 0,
              },
            }}
          />
          <InputLabel htmlFor="password" sx={{ mb: 1, fontSize: "1rem" }}>
            Password
          </InputLabel>
          <InputBase
            required
            fullWidth
            name="password"
            // label="Password"
            placeholder="password"
            type={isVisible ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            sx={{
              border: "#d2d6da solid 2px",
              "&.Mui-focused": {
                border: "#35d1f5 solid 2px",
                boxShadow: `0 0 1px 2px #81e3f9de`,
                outline: 0,
              },
            }}
            endAdornment={
              <IconButton
                sx={{ p: 0 }}
                onClick={() => setIsVisible((prevState) => !prevState)}
              >
                {isVisible ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            }
          />
          <FormControlLabel
            control={<Switch size="small" />}
            label="Remember me"
            onChange={handleChange}
            sx={{
              paddingLeft: "10px",
              span: { fontSize: "0.75rem" },
            }}
          />
          {status === "pending" ? (
            <Button
              disabled
              variant="gradient"
              fullWidth
              sx={{
                mt: 2,
                color: "linear-gradient(310deg, #2152ff, #02c6f3)",
              }}
            >
              Loading
            </Button>
          ) : (
            <MkButton
              type="submit"
              fullWidth
              variant="gradient"
              mkcolor="linear-gradient(310deg, #2152ff, #02c6f3)"
              sx={{
                mt: 2,
                color: "linear-gradient(310deg, #2152ff, #02c6f3)",
              }}
            >
              Login
            </MkButton>
          )}

          <Box sx={{ marginTop: "10px", display: error ? "" : "none" }}>
            <Alert variant="filled" severity="error">
              {error}
            </Alert>
          </Box>
          <div style={{ marginTop: "10px" }}>
            <Link to="/register">Create an account?</Link>
          </div>
        </Box>
      </Box>
      <Copyright sx={{ mt: 6 }} />
    </Container>
  );
}
