import { createSlice } from "@reduxjs/toolkit";
import { RegisterApi } from "./RegisterApi";
import { useNavigate } from "react-router-dom";

const registerData = {
  data: [],
  status: null,
  error: null,
};

const AdminRegisterSlice = createSlice({
  name: "registerinfo",
  initialState: {
    ...registerData,
  },
  reducers: {
    resetDefault: (state) => {
      state.status = null;
      state.error = null;
    },
    errorMessage: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RegisterApi.pending, (state, { payload }) => {
      state.status = "pending";
    });
    builder.addCase(RegisterApi.fulfilled, (state, { payload }) => {
      console.log(payload);
      state.data = payload;
      state.status = "success";
      if (payload.message) {
        state.error = payload.message;
      }
    });
  },
});

export const AdminRegister = AdminRegisterSlice.reducer;
export const { resetDefault, errorMessage } = AdminRegisterSlice.actions;
