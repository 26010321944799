import React, { useState } from "react";
import { Box } from "@mui/material";
import GenreDrawer from "./GenreDrawer";
import MkButton from "../../app/assets/theme/MkButton";
import { GenreUpdate } from "../../features/genre/GenreApi";
import EditIcon from "@mui/icons-material/Edit";

export default function GenreUpdateDrawer({ inputValues }) {
  /* +++++++++++++++++ Drawer +++++++++++++++++ */

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  /* --------------------------------------------  */

  return (
    <Box>
      <GenreDrawer
        open={open}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        action={GenreUpdate}
        inputValues={{
          id: inputValues._id,
          name: inputValues.name,
        }}
      >
        {/* <MkButton
          mkcolor={`linear-gradient(310deg, #2152ff, #02c6f3)`}
          size="small"
          textTransform="capitalize"
          onClick={handleDrawerOpen}
        >
          Edit
        </MkButton> */}
        <EditIcon
          onClick={handleDrawerOpen}
          fontSize="small"
          sx={{ cursor: "pointer", color: "#2152ff" }}
        />
      </GenreDrawer>
    </Box>
  );
}
