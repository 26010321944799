import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Toolbar,
  Typography,
  IconButton,
  TextField,
  FormControl,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Autocomplete,
  Checkbox,
  TableBody,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import InputFormComponent from "../../app/components/Form/InputFormComponent";
import MkButton from "../../app/assets/theme/MkButton";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import MkAutoComplete from "../../app/assets/theme/MkAutoComplete";
import { useDispatch } from "react-redux";
import { getCustomerList } from "../../features/customer/CustomerApi";
import AddCustomer from "./AddCustomer";
import ListCustomer from "./ListCustomer";
import { resetAddress } from "../../features/customer/CustomerSlice";
import SimpleSelect from "../../app/components/SimpleSelect";
import ClearIcon from "@mui/icons-material/Clear";
import { getCouriersList } from "../../features/couriers/CouriersApi";
import { resetCouriers } from "../../features/couriers/CouriersSlice";
import { formatMoney } from "../../app/helper/formatMoney";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { addInvoices, updateInvoices } from "../../features/invoice/InvoiceApi";
import {
  addError,
  clearError,
  hideAlert,
  showAlert,
  showSuccess,
} from "../../app/components/Alert/alertSlice";
import {
  addSaleBook,
  allInvoiceSaleBook,
  searchSaleBook,
  updateSaleBook,
} from "../../features/book/BookApi";
import {
  addSelectedBook,
  resetSaleBookList,
} from "../../features/book/BookSlice";
import SaleBookTableItem from "./SaleBookTableItem";
import { useNavigate, useParams } from "react-router-dom";
import { chooseAddress } from "../../features/customer/CustomerSlice";
import { calculateInvoiceTotalAmount } from "../../app/helper/invoiceCalculation";

const labelTheme = createTheme({
  components: {
    MuiFormControlLabel: {
      label: {
        fontSize: "14px",
      },
    },
  },
});

const Invoice = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [phNumber, setPhNumber] = useState("");
  const [publisher, setPublisher] = useState(null);
  const [error, setError] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [listOpen, setListOpen] = useState(false);
  const [name, setName] = useState("");
  const [divisionName, setDivisionName] = useState("");
  const [townshipName, setTownshipName] = useState("");
  const [cityName, setCityName] = useState("");
  const [addressName, setAddress] = useState("");
  const [date, setDate] = useState("");
  const [store, setStore] = useState("");
  const [payType, setPayType] = useState(null);
  const [delivery, setDelivery] = useState(null);
  const [trackingCode, setTrackingCode] = useState("");
  const [remark, setRemark] = useState("");
  const [selectDeliCost, setSelectDeliCost] = useState("");
  const [deliCharges, setDeliCharges] = useState("");
  const [voucherWeight, setVoucherWeight] = useState("");
  const [searchBook, setSearchBook] = useState("");
  const { publisher_list } = useSelector((state) => state.AllBookSlice);
  const { customerList, getStatus, address } = useSelector(
    (state) => state.CustomerSlice
  );
  const [payment, setPayment] = useState(0);
  const payMentData = [
    { id: 1, name: "KPAY" },
    { id: 2, name: "WAVE PAY" },
    { id: 3, name: "CB PAY" },
  ];
  const navigate = useNavigate();
  const { couriers } = useSelector((state) => state.CourierSlice);
  const { addInvoiceStatus, invoices } = useSelector(
    (state) => state.InvoiceSlice
  );
  const { admin } = useSelector((state) => state.loginInfo);
  const { saleBookList, searchStatus, selectedBooks, saleBooks } = useSelector(
    (state) => state.AllBookSlice
  );
  const [subTotal, setSubTotal] = useState(0);
  const { id } = useParams();
  const timeoutRef = useRef(null);
  console.log(id);
  const optionData = [
    { id: 0, name: "COD" },
    { id: 1, name: "None" },
  ];
  useEffect(() => {
    setName(address?.name);
    setDivisionName(address?.division?.name);
    setTownshipName(address?.township?.name);
    setCityName(address?.city?.name);
    setAddress(address?.address);
  }, [address]);
  useEffect(() => {
    setName("");
    setDivisionName("");
    setTownshipName("");
    setCityName("");
    setAddress("");
    dispatch(addSelectedBook([]));
  }, []);

  useEffect(() => {
    if (publisher?._id) {
      dispatch(getCouriersList({ id: publisher._id, page: 1, limit: 10 }));
    }
  }, [publisher]);
  const paramsId = id?.split("-")[1];
  useEffect(() => {
    if (id) {
      const invoice = invoices.find((item) => item._id === paramsId);
      console.log(invoice);
      const publisherName = publisher_list?.filter(
        (l) => l._id === invoice.publisher_id
      );
      console.log(publisherName);
      setPublisher(publisherName[0]);
      setPhNumber(invoice?.customer?.phone);
      setName(invoice?.customer?.name);
      setDivisionName(invoice?.customer?.division?.name);
      setCityName(invoice?.customer?.city?.name);
      setTownshipName(invoice?.customer?.township?.name);
      setAddress(invoice?.customer?.address);
      setTrackingCode(invoice?.tracking_code);
      setDate(invoice?.delivery_date);
      setDelivery({
        _id: invoice?.courier?.courier_id,
        name: invoice?.courier?.name,
        charges: invoice?.courier?.charges,
      });
      setSelectDeliCost(invoice?.courier?.charges);
      setDeliCharges(invoice?.delivery_charges);
      setPayType(
        invoice?.pay_type === "COD"
          ? { id: 0, name: "COD" }
          : { id: 0, name: "NONE" }
      );
      setStore(invoice?.store);
      setVoucherWeight(invoice?.voucher_weight);
      setRemark(invoice?.remark);
      dispatch(chooseAddress(invoice?.customer));
      dispatch(allInvoiceSaleBook({ id: paramsId }));
    }
  }, [id]);

  useEffect(() => {
    if (selectedBooks.length > 0) {
      const sale_books = selectedBooks.map((item) => {
        return {
          _id: item._id,
          book_title: item.book_title,
          authors: item.authors,
          sale_price: Number(item.sale_price),
          qty: Number(item.qty),
          balance_qty: item.balance_qty,
          discount: Number(item.discount),
          total_amount: Number(item.sale_price * item.qty - item.discount),
        };
      });
      const net_amount = calculateInvoiceTotalAmount({
        sale_book: { books: sale_books },
      });
      setSubTotal(net_amount);
    }
  }, [selectedBooks, deliCharges]);

  const addInvoice = (e) => {
    e.preventDefault();
    console.log(address);
    const data = {
      publisher_id: publisher?._id,
      customer: address,
      store: store ?? "",
      delivery_date: date,
      courier: {
        courier_id: delivery?._id,
        name: delivery?.name,
        charges: delivery?.charges,
      },
      pay_type: payType ? payType?.name : "NONE",
      tracking_code: trackingCode,
      remark: remark ?? "",
      delivery_charges: Number(deliCharges),
      voucher_weight: voucherWeight ?? "",
      admin: {
        id: admin?._id,
        name: `${admin?.first_name} ${admin?.last_name}`,
      },
    };
    console.log(data);
    const saleBookData = selectedBooks?.map((book) => {
      // console.log(book);
      return {
        _id: book._id,
        book_title: book.book_title,
        sale_price: book.sale_price,
        qty: book.qty,
        balance_qty: book.balance_qty,
        discount: book.discount,
        total_amount: book.qty * book.sale_price - book.discount,
        authors: book.authors,
      };
    });
    console.log(saleBooks);
    let netAmount = 0;
    for (let i = 0; i < saleBookData?.length; i++) {
      const totalAmount = saleBookData[i].total_amount;
      netAmount += totalAmount;
    }
    if (id) {
      // console.log(selectedBooks);
      // console.log(paramsId);
      // console.log(saleBookData);
      // console.log(saleBooks);
      dispatch(updateInvoices({ data: data, id: paramsId }))
        .unwrap()
        .then((result) => {
          if (result.data) {
            if (saleBooks) {
              dispatch(
                updateSaleBook({
                  id: saleBooks._id,
                  data: {
                    invoice_id: paramsId,
                    books: saleBookData,
                    net_amount: Number(netAmount) + Number(delivery?.charges),
                  },
                })
              );
            } else {
              if (selectedBooks?.length > 0) {
                dispatch(
                  addSaleBook({
                    data: {
                      invoice_id: result.data._id,
                      books: saleBookData,
                      net_amount: Number(netAmount) + Number(delivery?.charges),
                    },
                  })
                );
              }
            }
            dispatch(showSuccess("Invoice updated successfully"));
            dispatch(showAlert());
            dispatch(clearError());
            setTimeout(() => {
              dispatch(hideAlert());
            }, 1500);
          } else {
            dispatch(addError("Some Field is Required!"));
            dispatch(showAlert());
            setTimeout(() => {
              dispatch(hideAlert());
            }, 1500);
          }
        });
    } else {
      console.log(selectedBooks);
      dispatch(addInvoices({ data }))
        .unwrap()
        .then((result) => {
          if (result.data) {
            console.log(result.data._id);
            if (selectedBooks?.length > 0) {
              dispatch(
                addSaleBook({
                  data: {
                    invoice_id: result.data._id,
                    books: saleBookData,
                    net_amount: Number(netAmount) + Number(delivery?.charges),
                  },
                })
              );
            }
            navigate(`/sales/invoices-${result.data._id}`);
            //
            dispatch(showSuccess("Invoice created successfully"));
            dispatch(showAlert());
            dispatch(clearError());
            setTimeout(() => {
              dispatch(hideAlert());
            }, 1500);
          } else {
            dispatch(addError("Some Field is Required!"));
            dispatch(showAlert());
            setTimeout(() => {
              dispatch(hideAlert());
            }, 1500);
          }
        });
    }
  };

  const addPopup = () => {
    dispatch(
      getCustomerList({ publisher_id: publisher._id, phNumber: phNumber })
    )
      .unwrap()
      .then((result) => {
        if (result.length > 0) {
          setListOpen(true);
        } else {
          setEditOpen(true);
        }
      });
    // console.log("popup");
    // if (customerList.length > 0) {
    //   setListOpen(true);
    // } else {
    //   setEditOpen(true);
    // }
  };

  const f2Fuction = (e) => {
    // e.preventDefault();
    // console.log(e.key);
    if (e.key === "F2") {
      addInvoice(e);
      // e.preventDefault();
    }
  };
  // for key
  const handleKeyPress = (event) => {
    // console.log(event.keyCode);
    // Check if the pressed key is the Enter key (key code 13)
    if (event.keyCode === 113) {
      console.log("Enter key pressed!");
      // addInvoice(event);
      f2Fuction(event);
      // Do something here
    }
  };
  const determineMinusOrPlus = (val) => {
    if (val == 0) {
      return "";
    }
    if (val > 0) {
      return "+";
    }

    if (val < 0) {
      return "";
    }
  };
  useEffect(() => {
    // Attach the keydown event listener to the document
    document.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  useEffect(() => {
    dispatch(resetSaleBookList());
    console.log(searchBook.replace(/\s/g, ""));
    if (searchBook?.length === 1) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        dispatch(
          searchSaleBook({
            id: publisher?._id,
            name: searchBook.replace(/\s/g, ""),
          })
        );
      }, 100);
      return () => {
        clearTimeout(timeoutRef.current);
      };
    } else {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        dispatch(
          searchSaleBook({
            id: publisher?._id,
            name: searchBook.replace(/\s/g, ""),
          })
        );
      }, 2000);
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }
  }, [searchBook]);
  const handleSelectedBook = (item) => {
    const exists = selectedBooks.findIndex((i) => i._id === item._id);
    if (exists > -1) {
      dispatch(
        addSelectedBook(selectedBooks.filter((i) => i._id !== item._id))
      );
    } else {
      dispatch(
        addSelectedBook([
          ...selectedBooks,
          { ...item, qty: 1, discount: 0, balance_qty: 1 },
        ])
      );
    }
  };
  return (
    <Box component="form">
      <Box component={Paper} sx={{ padding: "25px", mt: 5 }}>
        <Grid
          container
          // spacing={2}
          rowSpacing={5}
          columnSpacing={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item spacing={3} xs={5}>
            <Stack direction="row" justifyContent="space-between">
              <Box sx={{ width: "48%" }}>
                <InputLabel htmlFor="select" sx={{ fontSize: "14.5px" }}>
                  Select Publisher*
                </InputLabel>
                <MkAutoComplete
                  label="Publisher List"
                  name="publisher"
                  placeholder="Choose Publisher"
                  noOptionsText="No Publisher"
                  fullWidth
                  options={publisher_list}
                  getOptionLabel={(publisher_list) =>
                    publisher_list?.name ?? publisher_list
                  }
                  isOptionEqualToValue={(publisher_list, value) =>
                    publisher_list._id === value._id
                  }
                  onChange={(event, newValue) => {
                    setPublisher(newValue);
                    dispatch(resetAddress());
                    setPhNumber("");
                    setStore("");
                    setPayType(null);
                    setDelivery(null);
                    setTrackingCode("");
                    setSelectDeliCost("");
                    setDeliCharges("");
                    setVoucherWeight("");
                    setRemark("");
                    dispatch(resetCouriers());
                    setSearchBook("");
                    setDate("");
                    dispatch(addSelectedBook([]));
                  }}
                  value={publisher}
                  onKeyDown={(e) => f2Fuction(e)}
                />
              </Box>
              <Box sx={{ width: "48%" }}>
                <InputFormComponent
                  value={phNumber}
                  onChange={(e) => {
                    setPhNumber(e.currentTarget.value);
                    dispatch(resetAddress());
                  }}
                  onKeyPress={(event) => {
                    if (
                      event.key === "Enter" &&
                      phNumber.length > 8 &&
                      phNumber.length < 12
                    ) {
                      // Perform the desired action when Enter key is pressed
                      addPopup();
                    } else {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                        setError(true);
                        console.log("invalid input value");
                      } else {
                        setError(false);
                      }
                    }
                  }}
                  // onChange={(e) => {
                  //   searchHandler(e);
                  // }}
                  onKeyDown={(e) => f2Fuction(e)}
                  label="Phone Number*"
                  name="Phone Number"
                  placeholder="Enter Your Phone Number"
                  disabled={publisher ? false : true}
                  required
                  endAdornment={
                    phNumber.length > 8 && phNumber.length < 12 ? (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={addPopup}
                          disabled={
                            getStatus === "pending" ||
                            addInvoiceStatus === "pending"
                              ? true
                              : false
                          }
                          sx={{ color: "green" }}
                        >
                          <AddBoxIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <></>
                    )
                  }
                />
                {error ? (
                  <Typography
                    sx={{
                      color: "red",
                      fontSize: "1rem",
                    }}
                  >
                    Please Type English number Only.
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
            </Stack>
            <Box>
              <InputFormComponent
                multiline={true}
                rows={2}
                value={
                  addressName
                    ? `${addressName},${townshipName},${cityName},${divisionName}`
                    : ""
                }
                onChange={(e) => setAddressChange(e.currentTarget.value)}
                label="Address"
                name="address"
                placeholder="Enter Your Address"
                required
                disabled
              />
            </Box>
            <Stack direction="row" justifyContent="space-between">
              <Box sx={{ width: "48%" }}>
                <InputFormComponent
                  value={name ?? ""}
                  onChange={(e) => setFirstName(e.currentTarget.value)}
                  label=" Name"
                  name="first_name"
                  placeholder="Name"
                  required
                  //   disabled={publisher ? false : true}
                  disabled
                />
              </Box>
              <Box sx={{ width: "48%" }}>
                <InputFormComponent
                  value={voucherWeight}
                  onChange={(e) => setVoucherWeight(e.currentTarget.value)}
                  label="Voucher Weight"
                  name="voucher-weight"
                  placeholder="Enter Voucher Weight"
                  // required
                  disabled={publisher && phNumber ? false : true}
                  onKeyDown={(e) => f2Fuction(e)}
                  // disabled
                />
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box sx={{ width: "48%" }}>
                <InputLabel sx={{ fontSize: "14.5px" }}>
                  Delivery Type*
                </InputLabel>
                <MkAutoComplete
                  name="delivery_type"
                  fullWidth
                  placeholder="Choose Delivery Type"
                  options={couriers ?? [{ id: 0, name: "Loading" }]}
                  isOptionEqualToValue={(option, value) =>
                    option?._id === value?._id
                  }
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    setDelivery(newValue);

                    if (newValue === null) {
                      setSelectDeliCost("");
                    } else {
                      setSelectDeliCost(newValue.charges);
                    }
                  }}
                  value={delivery}
                  disabled={publisher && phNumber ? false : true}
                  onKeyDown={(e) => f2Fuction(e)}
                />
              </Box>
              <Box sx={{ width: "48%" }}>
                <InputFormComponent
                  value={formatMoney(selectDeliCost)}
                  label="Delivery Cost"
                  name="delivery-cost"
                  placeholder="Enter Delivery Cost"
                  required
                  // disabled={publisher ? false : true}
                  disabled
                  onKeyDown={(e) => f2Fuction(e)}
                />
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box sx={{ width: "48%" }}>
                <InputFormComponent
                  label="Delivery Date*"
                  name="Date"
                  // required
                  fullWidth
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  placeholder="Choose Date"
                  disabled={publisher && phNumber ? false : true}
                  onKeyDown={(e) => f2Fuction(e)}
                />
              </Box>
              <Box sx={{ width: "48%" }}>
                <InputFormComponent
                  value={trackingCode}
                  label="Tracking Code"
                  name="tracking_code"
                  placeholder="Tracking Code"
                  onChange={(e) => setTrackingCode(e.target.value)}
                  // required
                  disabled={publisher && phNumber ? false : true}
                  onKeyDown={(e) => f2Fuction(e)}
                />
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box sx={{ width: "48%" }}>
                <InputFormComponent
                  value={deliCharges}
                  onChange={(e) => setDeliCharges(e.currentTarget.value)}
                  label="Delivery Charges*"
                  name="delivery-charges"
                  placeholder="Enter Delivery Charges"
                  required
                  disabled={publisher && phNumber ? false : true}
                  onKeyDown={(e) => f2Fuction(e)}
                  // disabled
                />
              </Box>
              <Box sx={{ width: "48%" }}>
                <InputLabel sx={{ fontSize: "14.5px" }}>Pay Type</InputLabel>
                <MkAutoComplete
                  name="pay_type"
                  fullWidth
                  placeholder="Choose Pay Type"
                  options={optionData}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    setPayType(newValue);
                  }}
                  value={payType}
                  disabled={publisher && phNumber ? false : true}
                  onKeyDown={(e) => f2Fuction(e)}
                />
              </Box>
            </Stack>
            <Grid item xs={5.7}>
              <Box>
                <InputLabel
                  htmlFor="payment_select"
                  sx={{ fontSize: "14.5px" }}
                >
                  Payment
                </InputLabel>
                <SimpleSelect
                  id="payment_id"
                  name="payment_select"
                  options={[
                    { id: 0, name: "Choose Payment .. " },
                    ...payMentData,
                  ]}
                  value={payment}
                  onChange={(e) => setPayment(e.target.value)}
                  getOptionLabel="name"
                  disabled={publisher && phNumber ? false : true}
                  endAdornment={
                    <IconButton
                      sx={{
                        visibility: payment ? "visible" : "hidden",
                      }}
                      onClick={() => {
                        setPayment(0);
                      }}
                    >
                      <ClearIcon
                        sx={{
                          fontSize: "14.5px",
                          marginRight: "5px",
                        }}
                      />
                    </IconButton>
                  }
                />
              </Box>
            </Grid>

            {/* <Box>
              <InputFormComponent
                value={store}
                onChange={(e) => setStore(e.currentTarget.value)}
                label="Store"
                name="Store"
                placeholder="Store"
                required
                disabled={publisher && phNumber ? false : true}
                onKeyDown={(e) => f2Fuction(e)}
                // disabled
              />
            </Box> */}
            <Box>
              <InputFormComponent
                multiline={true}
                rows={2}
                value={remark}
                onChange={(e) => setRemark(e.currentTarget.value)}
                label="Remark"
                name="remark"
                placeholder="Enter Remark"
                disabled={publisher && phNumber ? false : true}
                onKeyDown={(e) => f2Fuction(e)}
              />
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box sx={{ position: "relative", marginTop: 2, marginBottom: 3 }}>
              <TextField
                value={searchBook}
                placeholder="Search Book"
                fullWidth
                disabled={publisher ? false : true}
                autoComplete="off"
                onChange={(e) => {
                  setSearchBook(e.target.value);
                }}
              />
              {searchBook !== "" && (
                <Box
                  sx={{
                    marginTop: 1,
                    background: "grey",
                    width: "100%",
                    height: "400px",
                    position: "absolute",
                    borderRadius: "10px",
                    overflow: "hidden",
                    overflowY: "scroll",
                    zIndex: 2,
                  }}
                >
                  {searchStatus === "pending" ? (
                    <Stack
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography
                        variant="h1"
                        fontWeight={"bold"}
                        fontSize={"18px"}
                        mt={18}
                        sx={{ color: "#fff" }}
                      >
                        Searching...
                      </Typography>
                    </Stack>
                  ) : (
                    <>
                      <Stack direction="row-reverse">
                        <MkButton
                          mkcolor={`linear-gradient(310deg, #2152ff, #02c6f3)`}
                          size="lg"
                          onClick={() => setSearchBook("")}
                          sx={{
                            margin: 3,
                            width: "10%",
                            display: saleBookList.length === 0 ? "none" : "",
                          }}
                        >
                          Done
                        </MkButton>
                      </Stack>
                      {saleBookList?.map((item, index) => (
                        <Box
                          key={item._id}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            background: "#fff",
                            padding: 1,
                            borderRadius: 5,
                            cursor: "pointer",
                            my: 1,
                            width: "95%",
                            margin: "0 auto 20px auto",
                          }}
                          onClick={() => handleSelectedBook(item)}
                        >
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Checkbox
                              checked={
                                selectedBooks.findIndex(
                                  (i) => i._id === item._id
                                ) > -1
                              }
                            />
                            <Stack direction={"column"}>
                              <Typography
                                color={"white"}
                                fontSize={"15px"}
                                fontWeight={"bold"}
                              >
                                {item?.book_title}
                              </Typography>
                              <Stack direction={"row"} alignItems={"center"}>
                                {item?.authors?.map((i, index) => {
                                  return (
                                    <Typography
                                      color={"white"}
                                      fontSize={"10px"}
                                      fontWeight={"bold"}
                                    >
                                      {i?.name}
                                      {item?.authors?.length === index + 1
                                        ? ""
                                        : ","}
                                    </Typography>
                                  );
                                })}
                              </Stack>
                            </Stack>
                          </Stack>
                        </Box>
                      ))}
                    </>
                  )}
                </Box>
              )}
            </Box>
            <TableContainer sx={{ zIndex: 1 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={"30%"}>
                      <Typography fontWeight={"bold"} variant="subtitle3">
                        Book ({selectedBooks.length})
                      </Typography>
                    </TableCell>
                    <TableCell align="center" width={"20%"}>
                      <Typography fontWeight={"bold"} variant="subtitle3">
                        Sale Price
                      </Typography>
                    </TableCell>
                    <TableCell align="center" width={"15%"}>
                      <Typography fontWeight={"bold"} variant="subtitle3">
                        Qty
                      </Typography>
                    </TableCell>
                    <TableCell align="center" width={"25%"}>
                      <Typography fontWeight={"bold"} variant="subtitle3">
                        Discount
                      </Typography>
                    </TableCell>
                    <TableCell align="right" width={"10%"}>
                      <Typography fontWeight={"bold"} variant="subtitle3">
                        Total
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedBooks.length > 0 ? (
                    selectedBooks?.map((item) => {
                      return <SaleBookTableItem item={item} />;
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} rowSpan={3} align="center">
                        <Typography
                          variant="h1"
                          fontWeight={"bold"}
                          fontSize={"15px"}
                          sx={{ color: "#000" }}
                        >
                          There are no sale books added.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {selectedBooks.length > 0 && (
              <Grid container justifyContent={"end"}>
                <Grid
                  xs={6}
                  sx={{
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                  }}
                ></Grid>
                <Grid
                  item
                  xs={6}
                  padding={2}
                  sx={{
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography fontWeight={"bold"} variant="subtitle2">
                      Subtotal
                    </Typography>
                    <Typography fontWeight={"bold"} variant="subtitle3">
                      {determineMinusOrPlus(subTotal)} {formatMoney(subTotal)}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid xs={6}></Grid>
                <Grid
                  item
                  xs={6}
                  padding={2}
                  sx={{
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography fontWeight={"bold"} variant="subtitle2">
                      Delivery Charges
                    </Typography>
                    <Typography fontWeight={"bold"} variant="subtitle3">
                      {determineMinusOrPlus(deliCharges)}{" "}
                      {formatMoney(deliCharges)}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid xs={6}></Grid>
                <Grid
                  item
                  xs={6}
                  padding={2}
                  sx={{
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography fontWeight={"bold"} variant="subtitle2">
                      Total
                    </Typography>
                    <Typography fontWeight={"bold"} variant="subtitle3">
                      {determineMinusOrPlus(subTotal + deliCharges)}{" "}
                      {formatMoney(Number(subTotal) + Number(deliCharges))}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 12 }}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: "15px",
            }}
          >
            {addInvoiceStatus === "pending" ? (
              <MkButton
                size="lg"
                mkcolor={`linear-gradient(310deg, #0adc7f, #02f377)`}
                disabled
                // type="submit"
              >
                Saving
              </MkButton>
            ) : (
              <MkButton
                size="lg"
                mkcolor={`linear-gradient(310deg, #0adc7f, #02f377)`}
                // disabled={publisher ? false : true}
                onClick={(e) => addInvoice(e)}
                // type="submit"
              >
                Save (F2)
              </MkButton>
            )}

            <AddCustomer
              open={editOpen}
              setOpen={setEditOpen}
              publisher={publisher}
              phNumber={phNumber}
              setPhNumber={setPhNumber}
            ></AddCustomer>
            <ListCustomer open={listOpen} setOpen={setListOpen} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Invoice;
