import { TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import MkSwitch from "../../../app/assets/theme/MkSwitch";
import DivisionUpdateDrawer from "./DivisionUpdateDrawer";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { deleteDivision } from "../../../features/location/DivisionApi";

const labelTheme = createTheme({
  components: {
    MuiFormControlLabel: {
      label: {
        fontSize: "14px",
      },
    },
  },
});

export function DivisionTableBody({ item }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { paginationData } = useSelector((state) => state.DivisionListSlice);
  // console.log(item);
  return (
    <TableRow>
      <TableCell>
        <Typography variant="caption">{item.name}</Typography>
      </TableCell>
      {/* <TableCell>
        <Typography variant="caption">{item.short_code}</Typography>
      </TableCell> */}
      <TableCell>
        <Typography variant="caption">{item.country.country_name}</Typography>
      </TableCell>
      <TableCell sx={{ width: 120 }}>
        <ThemeProvider theme={labelTheme}>
          <MkSwitch
            switchColor={theme.palette.info.main}
            // label="Active"
            checked={item.status == 1 ? true : false}
            onChange={() => {
              dispatch(
                deleteDivision({
                  id: item._id,
                  page: paginationData.page,
                  limit: paginationData.limit,
                })
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </ThemeProvider>
      </TableCell>
      <TableCell sx={{ width: 120 }} align="center">
        <DivisionUpdateDrawer inputValues={item} />
      </TableCell>
    </TableRow>
  );
}
