import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import UserTabView from "./user/UserTabView";
import ProductTabView from "./product/ProductTabView";
import CompanyUserInfo from "./user/CompanyUserInfo";
import CouriersTabView from "./couries/CouriersTabView";
import Setting from "./setting";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabView({ tabIndex, setTabIndex }) {
  const theme = useTheme();

  return (
    <Box>
      <TabPanel value={tabIndex} index={0} dir={theme.direction}>
        <ProductTabView setTabIndex={setTabIndex} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1} dir={theme.direction}>
        <UserTabView setTabIndex={setTabIndex} />
      </TabPanel>
      <TabPanel value={tabIndex} index={2} dir={theme.direction}>
        <CouriersTabView setTabIndex={setTabIndex} />
      </TabPanel>
      <TabPanel value={tabIndex} index={3} dir={theme.direction}>
        <Setting setTabIndex={setTabIndex} />
      </TabPanel>

      {/* <TabPanel value={tabIndex} index={1} dir={theme.direction}>
                <CampaignTabView setTabIndex={setTabIndex} />
            </TabPanel>
            <TabPanel value={tabIndex} index={2} dir={theme.direction}>
                <Typography variant="body2">This is Activity</Typography>
            </TabPanel>
            <TabPanel value={tabIndex} index={3} dir={theme.direction}>
                <CompanyUserInfo />
            </TabPanel>
            <TabPanel value={tabIndex} index={4} dir={theme.direction}>
                <CampaignInfo tabIndex={tabIndex} setTabIndex={setTabIndex} />
            </TabPanel>
            <TabPanel value={tabIndex} index={5} dir={theme.direction}>
                <PromotionInfo />
            </TabPanel>
            <TabPanel value={tabIndex} index={6} dir={theme.direction}>
                <CouponInfo />
            </TabPanel>
            <TabPanel value={tabIndex} index={7} dir={theme.direction}>
                <TandcInfo />
            </TabPanel> */}
    </Box>
  );
}

export default TabView;
