import { createAsyncThunk } from "@reduxjs/toolkit";

import useFetch, { api, usePostForm } from "../../app/hooks";

export const getPublisherDropDownList = createAsyncThunk(
  "getPublisherDropDownList/getPublisherDropDownList",
  async (_, { getState }) => {
    const { token, admin } = getState().loginInfo;
    const result = await useFetch({
      url: `getPublisherDropDownList?admin_id=${admin._id}`,
      method: "GET",
      token: token,
    });
    return result;
  }
);

export const allBookList = createAsyncThunk(
  "allBookList/allBookList",
  async (
    {
      page,
      limit,
      book_title,
      author_name,
      released_year,
      publisher_id,
      genre,
    },
    { getState }
  ) => {
    const { token, admin } = getState().loginInfo;
    const result = await useFetch({
      url: `getAllBooks?admin_id=${admin._id}&page=${page}&limit=${limit}&publisher_id=${publisher_id}&book_title=${book_title}&author_name=${author_name}&released_year=${released_year}&genre=${genre}`,
      method: "GET",
      token: token,
    });
    return result;
  }
);

export const allBook = createAsyncThunk(
  "allBook/allBook",
  async (
    { id, page, limit, book_title, author_name, released_year, genre },
    { getState }
  ) => {
    console.log(id);
    const { token, admin } = getState().loginInfo;
    const result = await useFetch({
      url: `books?admin_id=${admin._id}&page=${page}&limit=${limit}&publisher_id=${id}&book_title=${book_title}&author_name=${author_name}&released_year=${released_year}&genre=${genre}`,
      method: "GET",
      token: token,
    });
    return result;
  }
);

export const BookAdd = createAsyncThunk(
  "BookAdd/BookAdd",
  async ({ data, page, limit }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    const result = await useFetch({
      url: `books?admin_id=${admin._id}&page=${page}&limit=${limit}`,
      method: "POST",
      token: token,
      data: data,
    });
    return result;
  }
);

export const BookUpdate = createAsyncThunk(
  "BookUpdate/BookUpdate",
  async ({ data, id, page, limit }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    const result = await useFetch({
      url: `books/${id}?admin_id=${admin._id}&_method=PUT&page=${page}&limit=${limit}`,
      method: "POST",
      token: token,
      data: data,
    });
    return result;
  }
);

export const BookDelete = createAsyncThunk(
  "BookDelete/BookDelete",
  async ({ id, page, limit, publisher_id }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    const result = await useFetch({
      url: `books/${id}?admin_id=${admin._id}&page=${page}&limit=${limit}&publisher_id=${publisher_id}`,
      method: "DELETE",
      token: token,
    });
    return result;
  }
);

export const allInvoiceSaleBook = createAsyncThunk(
  "allInvoiceSaleBook/allInvoiceSaleBook",
  async ({ id }, { getState }) => {
    const { admin, token } = getState().loginInfo;
    const result = await useFetch({
      url: `sale_books?admin_id=${admin._id}&invoice_id=${id}`,
      method: "GET",
      token: token,
    });
    return result;
  }
);

export const searchSaleBook = createAsyncThunk(
  "SearchSaleBook/SearchSaleBook",
  async ({ id, name }, { getState }) => {
    console.log(id);
    const { token, admin } = getState().loginInfo;
    const result = await useFetch({
      url: `bookLists?admin_id=${admin._id}&publisher_id=${id}&filter_name=${name}`,
      method: "GET",
      token: token,
    });
    return result;
  }
);

export const addSaleBook = createAsyncThunk(
  "addSaleBook/addSaleBook",
  async ({ data }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    console.log(data);
    const response = await fetch(
      `${api}/admin/sale_books?admin_id=${admin._id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
    // const result = usePostForm({
    //   url: `users?admin_id=${admin._id}`,
    //   method: "POST",
    //   token: token,
    //   data: data,
    // });
    // return result;
  }
);

export const updateSaleBook = createAsyncThunk(
  "updateSaleBook/updateSaleBook",
  async ({ data, id }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    console.log(data);
    console.log(id);
    const response = await fetch(
      `${api}/admin/sale_books/${id}?admin_id=${admin._id}&_method=PUT`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
    // const result = usePostForm({
    //   url: `users?admin_id=${admin._id}`,
    //   method: "POST",
    //   token: token,
    //   data: data,
    // });
    // return result;
  }
);
