import { createSlice } from "@reduxjs/toolkit";

import {
  addAdmin,
  editAdmin,
  getAdminList,
  passwordReset,
  statusAdmin,
} from "./AdminApi";

const adminListInitial = {
  adminList: [],
  adminPagination: null,
  selectedAdmin: {},
  message: null,
  adminStatus: null,
};

export const AdminList = createSlice({
  name: "getAdminList",
  initialState: adminListInitial,
  reducers: {
    onSelectAdmin: (state, { payload }) => {
      const splitName = payload.name.split(" ");
      state.selectedAdmin = {
        active: payload.active,
        email: payload.email,
        first_name: splitName.shift(),
        last_name: splitName.join(" "),
        id: payload.id,
        last_login: payload.last_login,
        profile: payload.profile,
      };
    },
    removeSelectedAdmin: (state, { payload }) => {
      state.selectedAdmin = {};
    },
    removeMessage: (state, { payload }) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminList.pending, (state, { payload }) => {
        state.adminStatus = "pending";
      })
      .addCase(getAdminList.fulfilled, (state, { payload }) => {
        const { data, ...rest } = payload;
        state.adminStatus = "success";
        const modifyAdminName = data.map((admin) => {
          return {
            active: admin.active,
            email: admin.email,
            name: admin.first_name + " " + admin.last_name,
            id: admin._id,
            last_login: admin.last_login,
            profile: admin.profile,
          };
        });
        state.adminList = modifyAdminName;
        state.adminPagination = rest;
      })
      .addCase(addAdmin.fulfilled, (state, { payload }) => {
        const admin = {
          active: payload.admin.active,
          email: payload.admin.email,
          name: payload.admin.first_name + " " + payload.admin.last_name,
          id: payload.admin._id,
          last_login: payload.admin.last_login,
          profile: payload.admin.profile,
        };
        state.adminList.push(admin);
      })
      .addCase(editAdmin.fulfilled, (state, { payload }) => {
        state.adminList = state.adminList.map((user) => {
          if (user._id === payload.admin._id) {
            return {
              active: payload.admin.active,
              email: payload.admin.email,
              name: payload.admin.first_name + " " + payload.admin.last_name,
              id: payload.admin._id,
              last_login: payload.admin.last_login,
              profile: payload.admin.profile,
            };
          } else {
            return { ...user };
          }
        });
      })
      .addCase(statusAdmin.fulfilled, (state, { payload }) => {
        state.adminList = state.adminList.map((admin) => {
          if (admin.id === payload.status.id) {
            return {
              ...admin,
              active: payload.status.active ? 1 : 0,
            };
          } else {
            return { ...admin };
          }
        });
      })
      .addCase(passwordReset.fulfilled, (state, { payload }) => {
        state.message = payload;
      });
  },
});

export const adminList = AdminList.reducer;
export const { onSelectAdmin, removeSelectedAdmin, removeMessage } =
  AdminList.actions;
