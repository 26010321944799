import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Input,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Avatar,
} from "@mui/material";
import { Add, Edit } from "@mui/icons-material";
import AdminInputs from "./AdminInputs";
import {
  addAdmin,
  editAdmin,
  editAdmins,
  getAdminList,
} from "../../features/admin/AdminApi";
import MkButton from "../../app/assets/theme/MkButton";
import { useTheme } from "@mui/material/styles";
import {
  onSelectAdmin,
  removeSelectedAdmin,
} from "../../features/admin/AdminSlice";
import { useSelector } from "react-redux";
import { imageApi } from "../../app/hooks";
import { Navigate, Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

export default function EditAdmin({ item, current_admin }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState(true);
  const [imageData, setImageData] = useState("#");
  const [imageChange, setImageChange] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const dispatch = useDispatch();
  const { selectedAdmin } = useSelector((state) => state.adminList);

  const user = useSelector((state) => state.loginInfo);

  const { adminList } = useSelector((state) => state.adminList);

  const [admin, setAdmin] = useState(
    adminList.find((ad) => ad.id === user.admin.id)
  );

  const handleClickOpen = () => {
    setCheck(true);
    setOpen(true);
    dispatch(onSelectAdmin(item));
  };

  const handleClose = () => {
    setCheck(true);
    setOpen(false);
    setImageChange(false);
    dispatch(removeSelectedAdmin());
  };

  const handleCheck = (e) => {
    setCheck(e.target.checked);
  };

  const imageInputChange = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setImageFile(reader.result);
    };
    const [file] = e.target.files;
    if (file) {
      setImageChange(true);
      setImageData(URL.createObjectURL(file));
    }
  };

  const handleAdd = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    formData.delete("profile");
    formData.delete("password");
    formData.append("profile", imageFile ?? "");
    dispatch(editAdmins({ data: formData, id: selectedAdmin.id }))
      .unwrap()
      .then((result) => {
        if (result.admin) {
          dispatch(getAdminList());
        }
      });
    //close the model box
    setOpen(false);
    dispatch(removeSelectedAdmin());
  };
  console.log(selectedAdmin);

  return (
    <>
      {current_admin.id === item.id ? (
        <Link to="/profile">
          {/* <MkButton
                        mkcolor={`linear-gradient(310deg, ${theme.palette.gradientDark.main}, ${theme.palette.gradientDark.light})`}
                        size="small"
                    >
                        Edit
                    </MkButton> */}
          <EditIcon
            fontSize="small"
            sx={{ cursor: "pointer", color: "#2152ff" }}
          />
        </Link>
      ) : (
        // <MkButton
        //   mkcolor={`linear-gradient(310deg, ${theme.palette.gradientDark.main}, ${theme.palette.gradientDark.light})`}
        //   size="small"
        //   onClick={handleClickOpen}
        // >
        //   Edit
        // </MkButton>
        <EditIcon
          onClick={handleClickOpen}
          fontSize="small"
          sx={{ cursor: "pointer", color: "#2152ff" }}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        component="form"
        onSubmit={handleAdd}
      >
        <DialogContent>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <DialogContentText sx={{ padding: "20px 35px " }}>
              Edit Admin
            </DialogContentText>
            <Box>
              <label htmlFor="contained-button-file">
                <Input
                  // accept="image/*"
                  inputProps={{ accept: "image/*" }}
                  id="contained-button-file"
                  type="file"
                  name="profile"
                  sx={{
                    display: "none",
                  }}
                  onChange={imageInputChange}
                />
                <Avatar
                  sx={{
                    width: 70,
                    height: 70,
                    cursor: "pointer",
                  }}
                  variant="rounded"
                  src={imageData}
                />
              </label>
            </Box>
          </Stack>
          <AdminInputs
            setImageData={setImageData}
            handleCheck={handleCheck}
            checked={check}
          />
        </DialogContent>
        <DialogActions sx={{ padding: "15px 35px" }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Edit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
