import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCouriersList } from "../../../features/couriers/CouriersApi";
import CompanyCouriers from "./CompanyCouriers";
import TableFooterPagination from "../../../app/components/Table/TableFooterPagination";
import AddCompanyCourier from "./AddCompanyCourier";

function CompanyCouriesTable({ setTabIndex }) {
  const dispatch = useDispatch();
  const { couriers, pagination } = useSelector((state) => state.CourierSlice);
  const { publisherDetail, loading } = useSelector(
    (state) => state.companyDetail
  );
  const location = useLocation();
  const str = location.pathname;
  const number = str.split("diuu-")[1];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const emptyRows =
    page > 0 ? rowsPerPage - couriers?.length : rowsPerPage - couriers?.length;
  console.log(couriers);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    dispatch(
      getCouriersList({ id: number, page: page + 1, limit: rowsPerPage })
    );
  }, []);
  return (
    <Box component={Paper} sx={{ padding: "0px 25px 25px 25px", mt: 5 }}>
      <Toolbar>
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="subtitle1"
          component="div"
        >
          Couriers List
        </Typography>
        <AddCompanyCourier />
      </Toolbar>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "20%" }}>
                <Typography variant="subtitle2">Name</Typography>
              </TableCell>
              <TableCell sx={{ width: "20%" }}>
                <Typography variant="subtitle2">Charges</Typography>
              </TableCell>
              <TableCell width="10%">
                <Typography variant="subtitle2">Status</Typography>
              </TableCell>
              <TableCell align="center" width="10%">
                <Typography variant="subtitle2">Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {couriers?.length === 0 && (
              <Typography
                sx={{
                  fontSize: "1.4rem",
                  textAlign: "center",
                  marginTop: "80px",
                }}
              >
                There is no courier.
              </Typography>
            )}
            {couriers?.map((row, index) => (
              <CompanyCouriers
                item={row}
                key={index}
                setTabIndex={setTabIndex}
                companyDetail={publisherDetail}
              />
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53.3 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
          {couriers?.length > 0 && (
            <TableFooterPagination
              rowsPerPageOptions={[10, 20, 30]}
              tableList={pagination?.total}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}

export default CompanyCouriesTable;
