import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CompanyCouriesTable from "./CompanyCouriesTable";

function CouriersTabView({ setTabIndex }) {
  return (
    <>
      <CompanyCouriesTable setTabIndex={setTabIndex} />
    </>
  );
}

export default CouriersTabView;
