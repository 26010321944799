import React, { useState } from "react";
import { Box } from "@mui/material";
import AuthorDrawer from "./AuthorDrawer";
import MkButton from "../../app/assets/theme/MkButton";
import { AuthorAdd } from "../../features/author/AuthorApi";

export default function AuthorAddDrawer() {
    /* +++++++++++++++++ Drawer +++++++++++++++++ */

    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    /* --------------------------------------------  */

    return (
        <Box>
            <AuthorDrawer
                open={open}
                onClose={handleDrawerClose}
                onOpen={handleDrawerOpen}
                action={AuthorAdd}
            >
                <MkButton
                    mkcolor={`linear-gradient(310deg, #2152ff, #02c6f3)`}
                    size="small"
                    textTransform="capitalize"
                    onClick={handleDrawerOpen}
                >
                    Add
                </MkButton>
            </AuthorDrawer>
        </Box>
    );
}
