import {
  Box,
  Chip,
  Stack,
  TableCell,
  TableRow,
  Typography,
  List,
  ListItem,
  ListItemText,
  Popover,
  Avatar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import "font-awesome/css/font-awesome.min.css";
import MkButton from "../../../app/assets/theme/MkButton";
import { useDispatch } from "react-redux";
import IconWrap from "../../../app/components/IconWrap";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { formatMoney } from "../../../app/helper/formatMoney";
import { BookDelete } from "../../../features/book/BookApi";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteAlert from "../../../app/components/AlertBox/DeleteAlert";
import { imageApi } from "../../../app/hooks";
import MkSwitch from "../../../app/assets/theme/MkSwitch";
import EditIcon from "@mui/icons-material/Edit";
import UpdateCompanyCourier from "./UpdateCompanyCourier";
import { CourierDelete } from "../../../features/couriers/CouriersApi";

const labelTheme = createTheme({
  components: {
    MuiFormControlLabel: {
      label: {
        fontSize: "14px",
      },
    },
  },
});
function CompanyCouriers({ item, companyDetail }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  // extra
  const { paginationData } = useSelector((state) => state.CourierSlice);
  const { publisherDetail } = useSelector((state) => state.companyDetail);
  const [check, setCheck] = useState(item.status == 0 ? false : true);
  const [editOpen, setEditOpen] = useState(false);
  const handleCheck = (e) => {
    setCheck(e.target.checked);
    console.log(check);
    console.log(item);
    dispatch(CourierDelete({ id: item._id, publisher_id: publisherDetail._id }))
      .unwrap()
      .then((result) => {
        if (result.data) {
          dispatch(
            getCouriersList({ id: publisherDetail._id, page: 1, limit: 10 })
          );
        }
      });
  };

  const handleClick = () => {
    setEditOpen(true);
  };
  console.log({ item });
  console.log({ companyDetail });
  console.log({ paginationData });
  return (
    <TableRow key={item._id}>
      <TableCell>
        <Typography variant="caption" sx={{ marginBottom: "3px" }}>
          {item.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption" sx={{ marginBottom: "3px" }}>
          {formatMoney(item.charges)}
        </Typography>
      </TableCell>
      <TableCell>
        <ThemeProvider theme={labelTheme}>
          <MkSwitch
            switchColor={theme.palette.info.main}
            checked={check}
            onChange={(e) => {
              handleCheck(e);
            }}
            inputProps={{
              "aria-label": "controlled",
            }}
          />
        </ThemeProvider>
      </TableCell>
      <TableCell align="center">
        <EditIcon
          fontSize="small"
          sx={{ cursor: "pointer", color: "#2152ff" }}
          onClick={handleClick}
        />
      </TableCell>
      <UpdateCompanyCourier open={editOpen} setOpen={setEditOpen} item={item} />
    </TableRow>
  );
}

export default CompanyCouriers;
