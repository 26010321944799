import { createAsyncThunk } from "@reduxjs/toolkit";

import useFetch, { api, usePostForm } from "../../app/hooks";

export const ExpensesAdd = createAsyncThunk(
  "ExpensesAdd/ExpensesAdd",
  async ({ data }, { getState }) => {
    console.log(data);
    const { token, admin } = getState().loginInfo;
    const response = await fetch(
      `${api}/admin/expense_categories?admin_id=${admin._id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
    // const result = await usePostForm({
    //   url: `expense_categories?admin_id=${admin._id}`,
    //   method: "POST",
    //   token: token,
    //   data: data,
    // });
    // return result;
  }
);

export const ExpensesUpdate = createAsyncThunk(
  "ExpensesUpdate/ExpensesUpdate",
  async ({ data, id }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    const response = await fetch(
      `${api}/admin/expense_categories/${id}?admin_id=${admin._id}&_method=PUT`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
    // const result = await usePostForm({
    //   url: `expenses/${id}?admin_id=${admin._id}&_method=PUT`,
    //   method: "POST",
    //   token: token,
    //   data: data,
    // });
    // return result;
  }
);
export const ExpensesDelete = createAsyncThunk(
  "ExpensesDelete/ExpensesDelete",
  async ({ expense, publisher_id, book_id, expense_list_id }, { getState }) => {
    console.log(expense);
    console.log(publisher_id);
    console.log(book_id);
    console.log(expense_list_id);
    const data = {
      expense,
      publisher_id,
      book_id,
      expense_list_id,
    };
    console.log(data);
    const { token, admin } = getState().loginInfo;
    const response = await fetch(
      `${api}/admin/expense_categories/delete_expense?admin_id=${admin._id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
    // const { token, admin } = getState().loginInfo;
    // const result = await usePostForm({
    //   url: `expense_categories/${id}?admin_id=${admin._id}`,
    //   method: "DELETE",
    //   token: token,
    // });
    // return result;
  }
);

// export const ExpensesDelete = createAsyncThunk(
//   "ExpensesDelete/ExpensesDelete",
//   async ({ id, expense_category_id }, { getState }) => {
//     const { token, admin } = getState().loginInfo;
//     const result = await usePostForm({
//       url: `expense_categories/${id}?admin_id=${admin._id}&expense_category_id=${expense_category_id}`,
//       method: "DELETE",
//       token: token,
//     });
//     return result;
//   }
// );

export const GetExpensesList = createAsyncThunk(
  "GetExpensesList/GetExpensesList",
  async ({ book_id, publisher_id }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    const result = await useFetch({
      url: `expense_categories?admin_id=${admin._id}&book_id=${book_id}&publisher_id=${publisher_id}`,
      method: "GET",
      token: token,
    });
    return result;
  }
);

export const GetExpensesDetail = createAsyncThunk(
  "GetExpensesDetail/GetExpensesDetail",
  async ({ id, book_id, publisher_id }, { getState }) => {
    console.log("id", id);
    const { token, admin } = getState().loginInfo;
    const result = await useFetch({
      url: `expense_categories/${id}?admin_id=${admin._id}&book_id=${book_id}&publisher_id=${publisher_id}`,
      method: "GET",
      token: token,
    });
    return result;
  }
);
