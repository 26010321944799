import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from "@mui/material";
import { React, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Toolbar,
  Typography,
  IconButton,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import InputFormComponent from "../../app/components/Form/InputFormComponent";
import MkButton from "../../app/assets/theme/MkButton";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import MkAutoComplete from "../../app/assets/theme/MkAutoComplete";
import { citiesOptions } from "../../features/location/CityApi";
import { handleCitiesOptions } from "../../features/location/CitySlice";
import { handleTownshipOptions } from "../../features/location/TownshipSlice";
import { townshipOptions } from "../../features/location/TownshipApi";
import {
  addCustomer,
  searchCustomer,
  updateCustomer,
} from "../../features/customer/CustomerApi";
import ListCustomer from "./ListCustomer";
import { resetUpdateAddress } from "../../features/customer/CustomerSlice";
import faceBook from "../../app/assets/images/Facebook A.svg";
import telegram from "../../app/assets/images/Telegram.svg";
import viber from "../../app/assets/images/Viber A.svg";
import whatApp from "../../app/assets/images/What App A.svg";
const labelTheme = createTheme({
  components: {
    MuiFormControlLabel: {
      label: {
        fontSize: "14px",
      },
    },
  },
});
const UpdateCustomer = ({ open, setOpen, inputValue }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
    console.log("close");
    setFirstName("");
    setDivision("");
    setTownship("");
    setCity("");
    setAddressChange("");
    setAlert(false);
    dispatch(resetUpdateAddress());
  };
  // console.log(inputValue[0]);
  const { divisionOptions } = useSelector((state) => state.DivisionListSlice);
  const { cityOptions } = useSelector((state) => state.CitiesListSlice);
  const { townshipOption } = useSelector((state) => state.TownshipListSlice);
  const { status, updateAddress } = useSelector((state) => state.CustomerSlice);
  const [firstName, setFirstName] = useState("");
  const [division, setDivision] = useState("");
  const [township, setTownship] = useState("");
  const [city, setCity] = useState("");
  const [addressChange, setAddressChange] = useState("");
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [listOpen, setListOpen] = useState(false);
  const [state, setState] = useState(false);
  const [result, setResult] = useState([]);
  const [faceBookCheck, setFaceBookCheck] = useState(false);
  const [viberCheck, setViberCheck] = useState(false);
  const [telegramCheck, setTelegramCheck] = useState(false);
  const [whatAppCheck, setWhatAppCheck] = useState(false);
  const [kPay, setKPay] = useState(false);
  const [wavePay, setWavePay] = useState(false);
  const [cbPay, setCBpay] = useState(false);
  const [ayaPay, setAYAPay] = useState(false);
  console.log({ updateAddress });
  useEffect(() => {
    console.log(updateAddress?.social_medias?.facebook);
    setFirstName(updateAddress?.name);
    setDivision({
      id: updateAddress?.division?.division_id,
      name: updateAddress?.division?.name,
    });
    setTownship({
      id: updateAddress?.township?.township_id,
      name: updateAddress?.township?.name,
    });
    setCity({
      id: updateAddress?.city?.city_id,
      name: updateAddress?.city?.name,
    });
    setAddressChange(updateAddress?.address);
    setFaceBookCheck(updateAddress?.social_medias?.facebook);
    setViberCheck(updateAddress?.social_medias?.viber);
    setTelegramCheck(updateAddress?.social_medias?.telegram);
    setWhatAppCheck(updateAddress?.social_medias?.whatsapp);
    setKPay(updateAddress?.customer_payment?.kPay);
    setWavePay(updateAddress?.customer_payment?.wavePay);
    setAYAPay(updateAddress?.customer_payment?.ayaPay);
    setCBpay(updateAddress?.customer_payment?.cbPay);
  }, [updateAddress]);

  const handleAdd = (event) => {
    event.preventDefault();
    const data = {
      publisher_id: updateAddress?.publisher_id,
      name: firstName,
      phone: updateAddress?.phone,
      division: {
        division_id: division._id,
        name: division.name,
      },
      city: {
        city_id: city._id,
        name: city.name,
      },
      township: {
        township_id: township._id,
        name: township.name,
      },
      address: addressChange,
      social_medias: {
        facebook: faceBookCheck,
        viber: viberCheck,
        telegram: telegramCheck,
        whatsapp: whatAppCheck,
      },
      customer_payment: {
        kPay: kPay,
        wavePay: wavePay,
        cbPay: cbPay,
        ayaPay: ayaPay,
      },
    };

    dispatch(updateCustomer({ data: data, id: updateAddress?._id }))
      .unwrap()
      .then((result) => {
        if (result.data) {
          setState(true);
          setOpen(false);
          setResult(result.data);
          setOpen(false);
          console.log("close");
          setFirstName("");
          setDivision("");
          setTownship("");
          setCity("");
          setAddressChange("");
          setAlert(false);
        }
      });

    // dispatch(addCustomer({ data: data }));
  };
  return (
    <Dialog
      open={open}
      maxWidth="md"
      component="form"
      onClose={handleClose}
      onSubmit={handleAdd}
    >
      <DialogContent>
        <DialogContentText sx={{ padding: "10px 35px " }}>
          <Typography
            sx={{
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            Update Customer
          </Typography>
        </DialogContentText>
        <Box sx={{ padding: "25px", mt: 5 }}>
          {/* <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              sx={{ paddingBottom: 2 }}
            >
              Sales Invoice
            </Stack> */}
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 12 }}
          >
            <Grid item xs={12} md={6}>
              <InputFormComponent
                value={updateAddress?.phone}
                //   onChange={(e) => setPhNumber(e.currentTarget.value)}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                    setError(true);
                    console.log("invalid input value");
                  } else {
                    setError(false);
                  }
                }}
                disabled
                onChange={(e) => {
                  setPhNumber(e.target.value);
                }}
                label="Phone Number"
                name="Phone Number"
                placeholder="Enter Your Phone Number"
                // disabled={publisher ? false : true}
                required
                //   endAdornment={
                //     phNumber.length > 8 && phNumber.length < 12 ? (
                //       <InputAdornment position="end">
                //         <IconButton edge="end">
                //           <AddBoxIcon onClick={addPopup} />
                //         </IconButton>
                //       </InputAdornment>
                //     ) : (
                //       <></>
                //     )
                //   }
              />
              {error ? (
                <Typography
                  sx={{
                    color: "red",
                    fontSize: "1rem",
                  }}
                >
                  Please Type English number Only.
                </Typography>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <InputFormComponent
                value={firstName}
                onChange={(e) => setFirstName(e.currentTarget.value)}
                label=" Name"
                name="first_name"
                placeholder="Enter Your  Name"
                required
                //   disabled={publisher ? false : true}
                //   disabled
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="select" sx={{ fontSize: "14.5px" }}>
                Select Division
              </InputLabel>
              <MkAutoComplete
                name="divisions"
                noOptionsText="No related Division"
                fullWidth
                placeholder="Division"
                required
                //   disabled={publisher ? false : true}
                //   disabled
                options={divisionOptions}
                getOptionLabel={(option) => option?.name ?? option}
                isOptionEqualToValue={(option, value) => {
                  option._id === value.id;
                }}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setDivision(newValue);
                    setCity(null);
                    setTownship(null);
                    dispatch(citiesOptions({ id: newValue._id }));
                    return;
                  }
                  setTownship(null);
                  setDivision(newValue);
                  setCity(null);
                  dispatch(handleCitiesOptions());
                  dispatch(handleTownshipOptions());
                }}
                value={division}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="select" sx={{ fontSize: "14.5px" }}>
                Select City
              </InputLabel>
              <MkAutoComplete
                name="cities"
                noOptionsText="No related City"
                fullWidth
                required
                placeholder="City"
                //   disabled
                //   disabled={publisher ? false : true}
                options={cityOptions}
                getOptionLabel={(option) => option?.name ?? option}
                // isOptionEqualToValue={(option, value) => {
                //   console.log(option);
                //   console.log(value);
                // }}
                isOptionEqualToValue={(option, value) => {
                  option._id === value.id;
                }}
                onChange={(event, newValue) => {
                  if (newValue) {
                    console.log(newValue);
                    setCity(newValue);
                    setTownship(null);
                    dispatch(
                      townshipOptions({
                        id: newValue._id,
                      })
                    );
                    return;
                  }
                  if (newValue === null) {
                    setCity(null);
                    setTownship(null);
                    dispatch(handleTownshipOptions());
                    return;
                  }
                }}
                value={city}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="select" sx={{ fontSize: "14.5px" }}>
                Select Township
              </InputLabel>
              <MkAutoComplete
                name="townships"
                noOptionsText="No related Township"
                fullWidth
                required
                placeholder="Township"
                //   disabled
                //   disabled={publisher ? false : true}
                options={townshipOption}
                getOptionLabel={(option) => option?.name ?? option}
                // isOptionEqualToValue={(option, value) => {
                //   console.log(option);
                //   console.log(value);
                // }}
                isOptionEqualToValue={(option, value) => {
                  option._id === value.id;
                }}
                onChange={(event, newValue) => {
                  setTownship(newValue);
                }}
                value={township}
              />
            </Grid>
            <Grid item xs={12}>
              <InputFormComponent
                multiline={true}
                rows={2}
                value={addressChange}
                onChange={(e) => setAddressChange(e.currentTarget.value)}
                label="Address"
                name="address"
                placeholder="Enter Your Address"
                required
                //   disabled
              />
            </Grid>
            <Grid item xs={6}>
              <FormLabel>Social</FormLabel>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value={faceBookCheck}
                  control={<Checkbox checked={faceBookCheck ? true : false} />}
                  onChange={(e) => {
                    setFaceBookCheck(e.target.checked);
                  }}
                  label={
                    <img
                      src={faceBook}
                      alt="facebook"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  }
                />
                <FormControlLabel
                  value={viberCheck}
                  control={<Checkbox checked={viberCheck ? true : false} />}
                  onChange={(e) => setViberCheck(e.target.checked)}
                  label={
                    <img
                      src={viber}
                      alt="viber"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  }
                />
                <FormControlLabel
                  value={telegramCheck}
                  control={<Checkbox checked={telegramCheck ? true : false} />}
                  onChange={(e) => setTelegramCheck(e.target.checked)}
                  label={
                    <img
                      src={telegram}
                      alt="telegram"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  }
                />
                <FormControlLabel
                  value={whatAppCheck}
                  control={<Checkbox checked={whatAppCheck ? true : false} />}
                  onChange={(e) => setWhatAppCheck(e.target.checked)}
                  label={
                    <img
                      src={whatApp}
                      alt="whatApp"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormLabel>Payment</FormLabel>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="Kpay"
                  control={<Checkbox checked={kPay ? true : false} />}
                  onChange={(e) => setKPay(e.target.checked)}
                  label="KBZ Pay"
                  // label={
                  //   <img
                  //     src={faceBook}
                  //     alt="facebook"
                  //     style={{
                  //       display: "flex",
                  //       justifyContent: "center",
                  //       width: "20px",
                  //       height: "20px",
                  //     }}
                  //   />
                  // }
                />
                <FormControlLabel
                  value="wavePay"
                  control={<Checkbox checked={wavePay ? true : false} />}
                  onChange={(e) => setWavePay(e.target.checked)}
                  label="Wave Pay"
                  // label={
                  //   <img
                  //     src={viber}
                  //     alt="viber"
                  //     style={{
                  //       display: "flex",
                  //       justifyContent: "center",
                  //       width: "20px",
                  //       height: "20px",
                  //     }}
                  //   />
                  // }
                />
                <FormControlLabel
                  value="CBPay"
                  control={<Checkbox checked={cbPay ? true : false} />}
                  onChange={(e) => setCBpay(e.target.checked)}
                  label="CB Pay"
                  // label={
                  //   <img
                  //     src={telegram}
                  //     alt="telegram"
                  //     style={{
                  //       display: "flex",
                  //       justifyContent: "center",
                  //       width: "20px",
                  //       height: "20px",
                  //     }}
                  //   />
                  // }
                />
                <FormControlLabel
                  value="AYAPay"
                  control={<Checkbox checked={ayaPay ? true : false} />}
                  onChange={(e) => setAYAPay(e.target.checked)}
                  label="AYA Pay"
                  // label={
                  //   <img
                  //     src={whatApp}
                  //     alt="whatApp"
                  //     style={{
                  //       display: "flex",
                  //       justifyContent: "center",
                  //       width: "20px",
                  //       height: "20px",
                  //     }}
                  //   />
                  // }
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Box>
        {alert && (
          <Typography sx={{ color: "red" }}>
            *Please Type Different Address*
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: "15px 35px" }}>
        <Button onClick={handleClose}>Cancel</Button>
        {status === "pending" ? (
          <Button disabled>Updating</Button>
        ) : (
          <Button type="submit">Update</Button>
        )}
      </DialogActions>
      {state && (
        <ListCustomer open={listOpen} setOpen={setListOpen}></ListCustomer>
      )}
    </Dialog>
  );
};

export default UpdateCustomer;
