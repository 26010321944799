import React, { useState } from "react";
import { Box } from "@mui/material";
import AuthorDrawer from "./AuthorDrawer";
import MkButton from "../../app/assets/theme/MkButton";
import { AuthorUpdate } from "../../features/author/AuthorApi";
import EditIcon from "@mui/icons-material/Edit";

export default function AuthorUpdateDrawer({ inputValues }) {
  /* +++++++++++++++++ Drawer +++++++++++++++++ */

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  /* --------------------------------------------  */

  return (
    <Box>
      <AuthorDrawer
        open={open}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        action={AuthorUpdate}
        inputValues={inputValues}
      >
        {/* <MkButton
                    mkcolor={`linear-gradient(310deg, #2152ff, #02c6f3)`}
                    size="small"
                    textTransform="capitalize"
                    onClick={handleDrawerOpen}
                >
                    Edit
                </MkButton> */}

        <EditIcon
          onClick={handleDrawerOpen}
          fontSize="small"
          sx={{ cursor: "pointer", color: "#2152ff" }}
        />
      </AuthorDrawer>
    </Box>
  );
}
