import {
  Avatar,
  Box,
  Button,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MkSwitch from "../../../app/assets/theme/MkSwitch";
import { getUserRole } from "../../../app/helper/getUserRole";
import { imageApi } from "../../../app/hooks";
import { companyUserDetailActions } from "../../../features/company/CompanySlice";
import { statusPublisherUser } from "../../../features/company/CompanyApi";
import EditCompanyUser from "./EditCompanyUser";
import MkButton from "../../../app/assets/theme/MkButton";
import EditIcon from "@mui/icons-material/Edit";

const labelTheme = createTheme({
  components: {
    MuiFormControlLabel: {
      label: {
        fontSize: "14px",
      },
    },
  },
});

function CompanyUserList({ item }) {
  const navigate = useNavigate();
  const { roles } = useSelector((state) => state.companyUserRole);
  const { publisherDetail } = useSelector((state) => state.companyDetail);
  const { user } = useSelector((state) => state.loginInfo);
  const { changeUserInfo } = companyUserDetailActions;
  const theme = useTheme();
  const dispatch = useDispatch();

  const itemStatus = item?.authorities?.map((r) => r);
  const userStatus = itemStatus?.filter(
    (i) => i.publisher_id === publisherDetail._id
  );
  // console.log(userStatus);
  const [check, setCheck] = useState(userStatus[0].status == 0 ? false : true);
  const handleCheck = (e) => {
    setCheck(e.target.checked);
    console.log(check);
    // setCheck(userStatus[0].status);
    // console.log(userStatus.status);
    // console.log(userStatus?.status);
    // console.log(item?.status);
    dispatch(
      statusPublisherUser({
        id: item._id,
        company_id: publisherDetail._id,
        status: Number(!userStatus[0].status),
      })
    );
  };
  // console.log(item);
  const itemRole = item?.authorities.find(
    (itm) => itm.publisher_id === publisherDetail._id
  );
  // console.log(itemRole.role.description);
  // const userRole = roles.find((itm) => itm._id === item?.role._id);
  const userRole = roles.find((itm) => itm._id === itemRole?._id);

  const loginUser = user?._id === item?._id;

  const handleUserInfo = () => {
    // setTabIndex(3);
    dispatch(changeUserInfo(item));
  };

  useEffect(() => {
    setCheck(userStatus[0].status == 0 ? false : true);
    //return arrry value get value[0].id
  }, [item]);
  // console.log(item);
  return (
    <TableRow>
      <TableCell>
        <Avatar
          sx={{
            width: 40,
            height: 40,
            cursor: "pointer",
          }}
          variant="rounded"
          alt={`${item?.first_name}`}
          src={`${imageApi}${item?.profile}`}
        />
      </TableCell>
      <TableCell>
        <Typography
          variant="caption"
          component="div"
          sx={{
            fontWeight: "bold",
            textAlign: "left",
          }}
        >
          {`${item?.name}`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption" component="div" textAlign="left">
          {item?.phone}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{itemRole.role.description}</Typography>
      </TableCell>
      <TableCell>
        <ThemeProvider theme={labelTheme}>
          <MkSwitch
            switchColor={theme.palette.info.main}
            checked={check}
            onChange={handleCheck}
            inputProps={{
              "aria-label": "controlled",
            }}
            disabled={loginUser}
          />
        </ThemeProvider>
      </TableCell>
      <TableCell align="center">
        {loginUser ? (
          // <MkButton
          //   mkcolor={`linear-gradient(310deg, #2152ff, #02c6f3)`}
          //   size="small"
          //   onClick={() => navigate("/profile", { state: true })}
          // >
          //   Edit
          // </MkButton>
          <EditIcon
            // onClick={handleClickOpen}
            fontSize="small"
            sx={{ cursor: "pointer", color: "#2152ff" }}
          />
        ) : (
          <EditCompanyUser item={item} roles={roles} userRole={userRole} />
        )}
      </TableCell>
    </TableRow>
  );
}

export default CompanyUserList;
