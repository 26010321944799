import {
  Box,
  Chip,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import "font-awesome/css/font-awesome.min.css";
import MkButton from "../../app/assets/theme/MkButton";
import { AuthorDelete } from "../../features/author/AuthorApi";
import { useDispatch } from "react-redux";
import IconWrap from "../../app/components/IconWrap";
import AuthorUpdateDrawer from "./AuthorUpdateDrawer";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";

function AuthorTableBody({ item }) {
  const dispatch = useDispatch();
  // extra
  const { paginationData } = useSelector((state) => state.AuthorSlice);
  return (
    <TableRow>
      <TableCell>
        <Typography variant="caption">
          {item.registration_id ?? "N/A"}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.name}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant="caption">{item.alias ?? "N/A"}</Typography>
      </TableCell>

      <TableCell>
        <Box
          sx={{
            display: "flex",
            columnGap: "10px",
            alignItems: "center",
            flexWrap: "wrap",
            flexDirection: "row",
          }}
        >
          {item?.name_tag
            ?.split("#")
            .slice(1)
            .map((i, index) => {
              return (
                <Chip
                  key={index}
                  label={i}
                  variant="outlined"
                  sx={{
                    my: 1,
                    color: "#fff",
                    border: "none",
                    backgroundImage:
                      "linear-gradient(310deg, #2152ff, #02c6f3);",
                  }}
                />
              );
            })}
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.note ?? "N/A"}</Typography>
      </TableCell>

      {/* <TableCell sx={{width: 120}} align="center">
                <AuthorUpdateDrawer inputValues={item} />
            </TableCell>

            <TableCell sx={{width: 120}} align="center">
                <MkButton
                    mkcolor={`linear-gradient(310deg, #141727, #3a416f)`}
                    size="small"
                    textTransform="capitalize"
                    onClick={() =>
                        dispatch(
                            AuthorDelete({
                                id: item.id,
                                page: paginationData.page,
                                limit: paginationData.limit,
                            })
                        )
                    }
                >
                    Delete
                </MkButton>
            </TableCell> */}

      <TableCell sx={{ width: 100 }} align="center">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <AuthorUpdateDrawer inputValues={item} />
          <DeleteIcon
            fontSize="small"
            onClick={() =>
              dispatch(
                AuthorDelete({
                  id: item._id,
                  page: paginationData.page,
                  limit: paginationData.limit,
                })
              )
            }
            sx={{ cursor: "pointer", color: "red" }}
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
}

export default AuthorTableBody;
