import React, {useState} from 'react';
import {Box} from '@mui/material';
import ProductDrawer from './ProductDrawer';
import MkButton from '../../../app/assets/theme/MkButton';
import {BookAdd} from '../../../features/book/BookApi';

export default function ProductAddDrawer() {
    /* +++++++++++++++++ Drawer +++++++++++++++++ */

    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    /* --------------------------------------------  */

    return (
        <Box>
            <ProductDrawer
                open={open}
                onClose={handleDrawerClose}
                onOpen={handleDrawerOpen}
                action={BookAdd}
            >
                <MkButton
                    mkcolor={`linear-gradient(310deg, #2152ff, #02c6f3)`}
                    size="small"
                    //textTransform="capitalize"
                    onClick={handleDrawerOpen}
                >
                    Add
                </MkButton>
            </ProductDrawer>
        </Box>
    );
}
