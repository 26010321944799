import React, {useState} from 'react';
import {Box} from '@mui/material';
import MkButton from '../../../app/assets/theme/MkButton';
import DivisionDrawer from './DivisionDrawer';
import {addDivision} from '../../../features/location/DivisionApi';

export default function DivisionAddDrawer() {
    /* +++++++++++++++++ Drawer +++++++++++++++++ */

    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    /* --------------------------------------------  */

    return (
        <Box>
            <DivisionDrawer
                open={open}
                onClose={handleDrawerClose}
                onOpen={handleDrawerOpen}
                action={addDivision}
            >
                <MkButton
                    mkcolor={`linear-gradient(310deg, #2152ff, #02c6f3)`}
                    size="small"
                    textTransform="capitalize"
                    onClick={handleDrawerOpen}
                >
                    Add
                </MkButton>
            </DivisionDrawer>
        </Box>
    );
}
