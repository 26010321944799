import { TableBar } from "@mui/icons-material";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import TableFooterPagination from "../app/components/Table/TableFooterPagination";
import { allTownship } from "../features/location/TownshipApi";
import TownshipTableBody from "../components/location/township/TownshipTableBody";
import TownshipAddDrawer from "../components/location/township/TownshipAdd";

export default function Township() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();

  const { townships, pagination } = useSelector(
    (state) => state.TownshipListSlice
  );

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? rowsPerPage - townships.length : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(allTownship({ page: page + 1, limit: rowsPerPage }));
  }, [page, rowsPerPage]);

  return (
    <Box component={Paper} sx={{ padding: "0px 25px 25px 25px" }}>
      <Stack
        direction="row"
        gap={2}
        justifyContent="space-between"
        alignItems="center"
        py={2}
        px={1}
      >
        <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
          Townships
        </Typography>
        {/* here is need search  */}
        <TownshipAddDrawer />
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell>
                                <Typography variant="subtitle2">
                                    Country Name
                                </Typography>
                            </TableCell> */}
              {/* <TableCell>
                                <Typography variant="subtitle2">
                                    Division Name
                                </Typography>
                            </TableCell> */}
              <TableCell>
                <Typography variant="subtitle2">Township Name</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">City Name</Typography>
              </TableCell>

              {/* <TableCell>
                                <Typography variant="subtitle2">
                                    Township Code
                                </Typography>
                            </TableCell> */}
              <TableCell>
                <Typography variant="subtitle2">Status</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {townships?.map((row, index) => (
              <TownshipTableBody item={row} key={index} />
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53.3 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>

          {townships?.length > 0 && (
            <TableFooterPagination
              rowsPerPageOptions={[10, 20, 30]}
              tableList={pagination?.total}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}
