import { createSlice } from "@reduxjs/toolkit";
import { getToken } from "./LoginApi";
import { removeToken } from "../logout/LogoutApi";

let logoutTimer;
const initialLoginData = {
  status: null,
  admin: {},
  token: null,
  error: null,
};

const loginData = createSlice({
  name: "loginInfo",
  initialState: {
    ...initialLoginData,
  },
  reducers: {
    onSetValid: (state, { payload }) => {
      state.admin = payload.admin;
      state.token = payload.admin.auth_token;
    },
    onLogoutHandler: (state) => {
      localStorage.removeItem("auth");
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
      state.admin = {};
      state.token = null;
      state.status = null;
      // console.log("second");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getToken.pending, (state, { payload }) => {
        state.status = "pending";
      })
      .addCase(getToken.fulfilled, (state, { payload }) => {
        // console.log(payload);
        state.status = "success";
        if (payload.message) {
          state.error = payload.message;
        }
      })
      .addCase(removeToken.fulfilled, (state, { payload }) => {
        //from logout api
        state.error = null;
        loginData.caseReducers.onLogoutHandler(state);
      });
  },
});

export const loginInfo = loginData.reducer;
export const { onSetValid, onLogoutHandler } = loginData.actions;

// calculate the expired time
export const calExpiredTime = (expiredTime) => {
  const currentTime = new Date().getTime();
  const exTime = new Date(expiredTime).getTime();
  return exTime - currentTime;
};

export default calExpiredTime;

//* for authentication reload
export const reloadHandler = () => async (dispatch) => {
  let auth = localStorage.getItem("auth");
  console.log({ auth });
  if (auth) {
    auth = JSON.parse(auth);
    dispatch(
      onSetValid({
        admin: auth.admin,
        token: auth.token,
      })
    );
    //* checking expire time
    dispatch(localStorageHandler(auth.expiredTime));
  }
};

// localstorage function
export const localStorageHandler =
  (expiredTime) => async (dispatch, getState) => {
    const { loginInfo } = getState();
    console.log({ loginInfo });
    console.log({ expiredTime });
    // console.log(loginInfo.token);

    if (loginInfo.token) {
      localStorage.setItem(
        "auth",
        JSON.stringify({
          token: loginInfo.token,
          admin: loginInfo.admin,
          expiredTime: expiredTime,
        })
      );

      const remainingTime = calExpiredTime(expiredTime);
      console.log({ remainingTime });
      //* logout after remainingTime
      logoutTimer = setTimeout(
        () => dispatch(onLogoutHandler()),
        remainingTime
      );
    }
  };
