import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { getPublisherList } from "../features/company/CompanyApi";
import TableFooterPagination from "../app/components/Table/TableFooterPagination";
import TableToolbar from "../app/components/Table/TableToolbar";
import CompanyItem from "../components/company/CompanyItem";
import PaperComponent from "../app/assets/theme/PaperComponent";

export default function Company() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();
  const { publisherList, publisherPagination, publisherStatus } = useSelector(
    (state) => state.PublisherListSlice
  );
  console.log(publisherList);
  const { usersList } = useSelector((state) => state.companyUser);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? rowsPerPage - publisherList.length : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(getPublisherList({ page: page, limit: rowsPerPage }));
  }, [page, rowsPerPage, usersList]);

  return (
    <>
      <Box
        sx={{ borderRadius: "10px", padding: "5px 25px 25px 25px" }}
        component={Paper}
      >
        <TableToolbar>
          <Typography variant="subtitle1">Publisher Lists</Typography>
        </TableToolbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "25%" }}>
                  <Typography variant="subtitle2">Publisher</Typography>
                </TableCell>
                <TableCell sx={{ width: "30%" }}>
                  <Typography variant="subtitle2">Publisher Address</Typography>
                </TableCell>
                <TableCell sx={{ width: "30%" }}>
                  <Typography variant="subtitle2">Publisher Contact</Typography>
                </TableCell>
                <TableCell sx={{ width: "15%" }}>
                  <Typography variant="subtitle2">Publisher Status</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {publisherStatus === "pending" ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 10,
                    marginLeft: 40,
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {publisherList?.map((row, index) => (
                    <CompanyItem item={row} key={index} />
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 85 * emptyRows }}>
                      <TableCell colSpan={5} />
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
            {publisherStatus === "pending" ? (
              <></>
            ) : (
              <TableFooterPagination
                tableList={publisherPagination?.total}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 15, 30]}
              />
            )}
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
