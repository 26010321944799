import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../app/hooks";

export const removeToken = createAsyncThunk(
  "removeToken/logout",
  async (id) => {
    const response = await fetch(`${api}/admin/logout?admin_id=${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const result = await response.json();
    console.log(result);
    return result;
  }
);
