import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import SimpleInput from "../../app/components/SimpleInput";
import MkButton from "../../app/assets/theme/MkButton";
import IconPicker from "../../app/components/IconPicker";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import IconWrap from "../../app/components/IconWrap";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

export default function ExpenseCategoryDrawer({
  open: drawerOpen,
  onClose: drawerOnClose,
  inputValues = null,
  action,
  children,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();

  // extra
  const { paginationData } = useSelector((state) => state.ExpenseCategorySlice);

  /* +++++++++++++++++ ExpenseCategory +++++++++++++++++ */

  const [select, setSelect] = useState(null);
  const [expense_categoryName, setExpense_categoryName] = useState(null);

  const closeNow = () => {
    setSelect(null);
    setExpense_categoryName(null);
    drawerOnClose();
  };
  console.log(inputValues);
  const expense_categoryClick = () => {
    const data = {
      name: expense_categoryName,
      icon: select,
    };
    if (expense_categoryName && select) {
      // For update
      if (inputValues?._id) {
        dispatch(
          action({
            data: data,
            id: inputValues?._id,
            page: paginationData.page,
            limit: paginationData.limit,
          })
        );
        closeNow();
        return;
      }
      // For add
      dispatch(
        action({
          data: data,
          page: paginationData.page,
          limit: paginationData.limit,
        })
      );
    }
    closeNow();
  };

  /* ----------------------------------------------------  */

  /* +++++++++++++++++ This will set up default value +++++++++++++++++ */

  useEffect(() => {
    if (inputValues) {
      setSelect(inputValues.select);
      setExpense_categoryName(inputValues.name);
    }
  }, [drawerOpen]);

  /* --------------------------------------------  */

  return (
    <div>
      {children}
      <Drawer anchor="right" open={drawerOpen} onClose={closeNow}>
        <DrawerHeader>
          <IconButton onClick={closeNow}>
            <CloseIcon />
          </IconButton>

          {/* here is as your desire */}

          <Typography variant="subtitle2" sx={{ marginRight: "40px" }}>
            {inputValues?.name ? `Update ${inputValues?.name} ` : "Add "}
            Expense Category
          </Typography>

          {/* --------------------------------------------  */}
        </DrawerHeader>
        <Divider />
        <Box sx={{ width: 450 }}>
          {/* here is as your desire */}

          <Stack
            direction="column"
            spacing={2}
            justifyContent="center"
            sx={{ padding: 5 }}
          >
            {inputValues && (
              <Box sx={{ alignSelf: "center", mb: 3 }}>
                <IconWrap class={select} sx={{ fontSize: 45 }} />
              </Box>
            )}
            <SimpleInput
              name="name"
              placeholder="Expense Category Name"
              autoFocus={true}
              value={expense_categoryName || ""}
              onChange={(e) => setExpense_categoryName(e.target.value)}
            />
            <IconPicker select={select} setSelect={setSelect} />
            <MkButton
              mkcolor={`linear-gradient(310deg, #2152ff, #02c6f3)`}
              size="small"
              onClick={expense_categoryClick}
            >
              {inputValues ? `Update` : "Add"}
            </MkButton>
          </Stack>

          {/* --------------------------------------------  */}
        </Box>
      </Drawer>
    </div>
  );
}
