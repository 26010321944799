import { createSlice } from "@reduxjs/toolkit";
import { addInvoices, getInvoiceList, updateInvoices } from "./InvoiceApi";

export const InvoiceList = createSlice({
  name: "Invoice",
  initialState: {
    addInvoiceStatus: null,
    updateInvoiceStatus: null,
    invoices: [],
    invoiceStatus: null,
    pagination: {},
    paginationData: {
      page: 1,
      limit: 10,
    },
  },
  reducers: {
    changePaginationData: (state, { payload }) => {
      state.paginationData = payload;
    },
    handleInvoice: (state, payload) => {
      console.log(payload);
      const { data, ...rest } = payload;
      // console.log("From handle book" + data);
      // console.log("From handle book" + rest);
      state.invoices = data;
      state.pagination = rest;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvoiceList.pending, (state, { payload }) => {
        state.invoiceStatus = "pending";
      })
      .addCase(getInvoiceList.fulfilled, (state, { payload }) => {
        state.invoiceStatus = "success";
        InvoiceList.caseReducers.handleInvoice(state, payload);
      })
      .addCase(addInvoices.pending, (state, { payload }) => {
        state.addInvoiceStatus = "pending";
      })
      .addCase(addInvoices.fulfilled, (state, { payload }) => {
        // InvoiceList.caseReducers.handleInvoice(state, payload);
        console.log(payload);
        if (payload.data) {
          state.invoices.push(payload.data);
        }
        state.addInvoiceStatus = "success";
      })
      .addCase(updateInvoices.pending, (state, { payload }) => {
        state.addInvoiceStatus = "pending";
      })
      .addCase(updateInvoices.fulfilled, (state, { payload }) => {
        state.addInvoiceStatus = "success";
        // console.log(payload);
      });
  },
});
export const InvoiceSlice = InvoiceList.reducer;
export const { changePaginationData } = InvoiceList.actions;
