import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Input,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Avatar,
  IconButton,
} from "@mui/material";
import {
  addPublisherUser,
  getPublisherDetail,
  getPublisherUserByEmail,
  getPublisherUserByPhone,
} from "../../../features/company/CompanyApi";
import { Add, Close } from "@mui/icons-material";
import AddUserInputs from "./AddUserInputs";
import AddUserEmail from "./AddUserEmail";
import AddUserRole from "./AddUserRole";
import {
  removeUserByEmail,
  removeUserByPhone,
} from "../../../features/company/CompanySlice";
import AlertBox from "../../../app/components/AlertBox/AlertBox";
import AddUserPhone from "./AddUserPhone";
import DefaultImage from "../../../app/assets/images/nineP.png";

export default function AddCompanyUser() {
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState(true);
  const dispatch = useDispatch();
  const [imageFile, setImageFile] = useState(null);
  const [imageData, setImageData] = useState(DefaultImage);
  console.log(imageData);
  //get company user search by email
  const { userByEmail, userByPhone } = useSelector(
    (state) => state.companyUser
  );
  console.log(userByPhone);
  //get email from addemail input and pass to add user input email file
  const [currentEmail, setCurrentEmail] = useState("");
  const [currentPhone, setCurrentPhone] = useState("");

  //get company id to add user
  const { publisherDetail } = useSelector((state) => state.companyDetail);
  //get role id from autocomplete
  const [roleID, setRoleID] = useState(null);
  const [role, setRole] = useState("");
  //alertState
  const [alertState, setAlertState] = useState();
  const [nameChange, setNameChange] = useState("");
  const [phone, setPhone] = useState(currentPhone);
  //user create success
  const [success, setSuccess] = useState(false);

  const handleClickOpen = () => {
    setCheck(true);
    setOpen(true);
  };

  const handleClose = () => {
    setCheck(true);
    setOpen(false);
    setImageData(DefaultImage);
    dispatch(removeUserByEmail());
    dispatch(removeUserByPhone());
    setImageFile(null);
  };

  const handleCheck = (e) => {
    setCheck(e.target.checked);
  };

  // const handleCheckEmail = (event) => {
  //     event.preventDefault();
  //     const formData = new FormData(event.currentTarget);
  //     const formEmail = formData.get('email');
  //     const data = {
  //         email: formEmail,
  //         company_id: publisherDetail.id,
  //     };
  //     dispatch(getPublisherUserByEmail(data));
  //     setSuccess(false);
  // };

  const handleCheckPhone = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const phone = formData.get("phone");
    const data = {
      phone: phone,
      publisher_id: publisherDetail._id,
    };
    // dispatch(getPublisherUserByPhone(data));
    // setSuccess(false);
    if (phone.length >= 9 && phone.length < 12 && phone.length != 10) {
      dispatch(getPublisherUserByPhone(data));
      setSuccess(false);
      console.log(userByPhone);
    } else {
      setAlertState({
        open: true,
        vertical: "top",
        horizontal: "right",
        variant: "error",
        message: "Phone Number must be between  9 and 11",
      });
    }
  };

  const imageInputChange = (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      console.log(reader.result);
      setImageFile(reader.result);
      //base64encoded string
    };

    setImageData(URL.createObjectURL(e.target.files[0]));
  };

  // const handleAdd = (event) => {
  //     event.preventDefault();
  //     const formData = new FormData(event.currentTarget);
  //     formData.append('authority_role_id', roleID);
  //     formData.append('authority_company_id', publisherDetail.id);
  //     formData.append('user_id', userByEmail?.user?.id ?? 0);
  //     formData.append(
  //         'status',
  //         check == true ? Number(1) : Number(0)
  //     );
  //     dispatch(addPublisherUser({data: formData}));
  //     setOpen(false);
  //     setSuccess(true);
  // };
  console.log(userByPhone);
  const handleAdd = (event) => {
    event.preventDefault();
    console.log(nameChange);
    console.log(phone);
    console.log(roleID);
    console.log(role.label);

    const userData = {
      user_id: userByPhone?.user?._id ?? "",
      name: nameChange,
      phone: phone,
      profile: imageFile ? imageFile : "",
      role: {
        _id: roleID,
        description: role.label,
      },
      publisher_id: publisherDetail._id,
    };
    // const formData = new FormData(event.currentTarget);
    // formData.append("authority_role_id", roleID);
    // formData.append("authority_publisher_id", publisherDetail._id);
    // formData.append("user_id", userByPhone?.user?._id ?? 0);
    // formData.append("status", check == true ? Number(1) : Number(0));
    // // for (const pair of formData.entries()) {
    // //     console.log(pair);
    // // }
    // // dispatch(addPublisherUser({data: formData}));
    // // handleClose();
    // // setSuccess(true);
    // const phone = formData.get("phone");
    if (
      phone.length >= 9 &&
      phone.length < 12 &&
      phone.length != 10 &&
      roleID
    ) {
      // console.log(userData);
      // const data = JSON.stringify(userData);
      // console.log(data);
      // dispatch(addPublisherUser({ data: formData }))
      dispatch(addPublisherUser({ data: userData }))
        .unwrap()
        .then((result) => {
          //   console.log(result);
          if (result.errors) {
            setAlertState({
              open: true,
              vertical: "top",
              horizontal: "right",
              variant: "error",
              message: "Your phone number already exit",
            });
            setSuccess(false);
            return;
          }
          handleClose();
          setSuccess(true);
        });
    } else {
      setAlertState({
        open: true,
        vertical: "top",
        horizontal: "right",
        variant: "error",
        message: "Phone Number must be between  9 and 11!",
      });
      setSuccess(false);
    }
  };

  useEffect(() => {
    if (userByPhone?.exit) {
      if (!success) {
        setAlertState({
          open: true,
          vertical: "top",
          horizontal: "right",
          variant: "error",
          message: "User Already exits!",
        });
      }
    }
  }, [userByPhone]);

  return (
    <>
      <Button startIcon={<Add />} onClick={handleClickOpen}>
        Add
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        component="form"
        onSubmit={
          userByPhone?.exit || userByPhone === null
            ? handleCheckPhone
            : handleAdd
        }
      >
        <DialogContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <DialogContentText
              sx={{
                padding: "20px 0px",
                fontSize: "1.3rem",
                fontWeight: "bold",
              }}
            >
              Add Publisher User
            </DialogContentText>
            {userByPhone === null || userByPhone.exit ? (
              <IconButton
                onClick={handleClose}
                size="large"
                sx={{ margin: "20px 35px" }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            ) : (
              <Box>
                <label htmlFor="contained-button-file">
                  <Input
                    // accept="image/*"
                    inputProps={{ accept: "image/*" }}
                    id="contained-button-file"
                    type="file"
                    name="profile"
                    sx={{
                      display: "none",
                    }}
                    onChange={imageInputChange}
                  />
                  <Box
                    sx={{
                      width: 100,
                      height: 100,
                      border: "1px solid",
                      borderRadius: "10px",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      variant="rounded"
                      src={imageData}
                    />
                  </Box>
                </label>
              </Box>
            )}
          </Stack>
          {userByPhone === null || userByPhone.exit ? (
            <AddUserPhone setCurrentPhone={setCurrentPhone} />
          ) : (
            <AddUserInputs
              handleCheck={handleCheck}
              setRoleID={setRoleID}
              role={role}
              setRole={setRole}
              roleID={roleID}
              currentEmail={currentEmail}
              currentPhone={currentPhone}
              checked={check}
              nameChange={nameChange}
              setNameChange={setNameChange}
              phone={phone}
              setPhone={setPhone}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ padding: "15px 35px" }}>
          {userByPhone === null || userByPhone.exit ? (
            ""
          ) : (
            <Button onClick={() => dispatch(removeUserByPhone())}>Back</Button>
          )}
          <Button type="submit">
            {userByPhone === null || userByPhone.exit ? "Next" : "Add user"}
          </Button>
        </DialogActions>
      </Dialog>
      <AlertBox alertState={alertState} />
    </>
  );
}
