import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import MkButton from "../../app/assets/theme/MkButton";
import InputFormComponent from "../../app/components/Form/InputFormComponent";
import { useSelector, useDispatch } from "react-redux";
import { updateProfile } from "../../features/company/CompanyApi";
import {
  localStorageHandler,
  onSetValid,
  reloadHandler,
} from "../../features/login/LoginSlice";
import { imageApi } from "../../app/hooks";
import { editAdmin, getAdminList } from "../../features/admin/AdminApi";

const labelTheme = createTheme({
  components: {
    MuiFormControlLabel: {
      label: {
        fontSize: "14px",
      },
    },
  },
});

function ProfileInputs() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { adminList } = useSelector((state) => state.adminList);
  const { admin } = useSelector((state) => state.loginInfo);
  const currentAdmin = adminList.find((us) => us.id === admin._id);
  const name = currentAdmin.name.split(" ");
  const [email, setEmail] = useState(currentAdmin?.email ?? "");
  const [firstName, setFirstName] = useState(name?.shift() ?? "");
  const [lastName, setLastName] = useState(name?.join("") ?? "");
  const [password, setPassword] = useState("");
  const [imageData, setImageData] = useState(currentAdmin?.profile ?? "#");
  const [imageChange, setImageChange] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  let auth = localStorage.getItem("auth");
  let authData = JSON.parse(auth);

  //language options
  const options = [
    { code: "US", label: "English" },
    { code: "MM", label: "Myanmar" },
  ];
  const [language, setLanguage] = useState(options[0]);

  const imageInputChange = (e) => {
    console.log(e.target.files[0]);
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setImageFile(reader.result);
    };
    const [file] = e.target.files;
    if (file) {
      setImageChange(true);
      setImageData(URL.createObjectURL(file));
    }
  };

  const handleUpdate = (event) => {
    console.log(imageFile);
    event.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("profile", imageFile ?? "");
    // const formData = new FormData(event.currentTarget);
    // formData.append("active", currentAdmin.active);
    // if (!imageChange) {
    //   formData.delete("profile");
    // }
    dispatch(editAdmin({ data: formData }))
      .unwrap()
      .then((result) => {
        console.log(result.admin.auth_token);
        if (result.admin) {
          dispatch(onSetValid(result));
          console.log(authData);
          localStorage.setItem(
            "auth",
            JSON.stringify({
              token: result.admin.auth_token,
              admin: result.admin,
              expiredTime: authData.expiredTime,
            })
          );
          dispatch(getAdminList());
        }
      });
    setPassword("");
    setImageChange(false);
  };

  return (
    <Box onSubmit={handleUpdate} component="form">
      <Box component={Paper} sx={{ padding: "25px", mt: 5 }}>
        <Stack direction="row" spacing={1} justifyContent="space-between">
          Admin Information
          <Box>
            <label htmlFor="contained-button-file">
              <Input
                // accept="image/*"
                inputProps={{ accept: "image/*" }}
                id="contained-button-file"
                type="file"
                name="profile"
                sx={{
                  display: "none",
                }}
                onChange={imageInputChange}
              />
              <Avatar
                sx={{
                  width: 70,
                  height: 70,
                  cursor: "pointer",
                }}
                variant="rounded"
                src={
                  currentAdmin?.profile
                    ? imageChange
                      ? imageData
                      : `${imageApi}${currentAdmin?.profile}`
                    : imageData
                }
              />
            </label>
          </Box>
        </Stack>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 12 }}>
          <Grid item xs={12} md={6}>
            <InputFormComponent
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              label="Email"
              name="email"
              placeholder="Enter Your Email"
              focus={true}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputFormComponent
              value={firstName}
              onChange={(e) => setFirstName(e.currentTarget.value)}
              label="First Name"
              name="first_name"
              placeholder="Enter Your First Name"
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputFormComponent
              value={lastName}
              onChange={(e) => setLastName(e.currentTarget.value)}
              label="Last Name"
              name="last_name"
              placeholder="Enter Your Last Name"
              required
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
                    <InputLabel id="select-helper-label">Language</InputLabel>
                    <MkAutoComplete
                        value={language}
                        onChange={(event, newValue) => setLanguage(newValue)}
                        getOptionLabel={(option) => option.label}
                        options={options}
                        renderOption={(props, option) => (
                            <Box
                                component="li"
                                sx={{
                                    "& > img": {
                                        mr: 2,
                                        flexShrink: 0,
                                    },
                                }}
                                {...props}
                            >
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                />
                                {option.label}
                            </Box>
                        )}
                    />
                </Grid> */}
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: "15px",
            }}
          >
            <MkButton
              mkcolor={`linear-gradient(310deg, ${theme.palette.gradientDark.main}, ${theme.palette.gradientDark.light})`}
              size="lg"
              // onClick={companyEdit}
              type="submit"
            >
              Update
            </MkButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ProfileInputs;
