import { createSlice } from "@reduxjs/toolkit";
import {
  addCustomer,
  deleteCustomer,
  getCustomerList,
  updateCustomer,
} from "./CustomerApi";

export const CustomerList = createSlice({
  name: "Customer",
  initialState: {
    customerList: [],
    status: null,
    getStatus: null,
    address: {},
    updateAddress: {},
    newAddress: {},
  },
  reducers: {
    resetAddress: (state) => {
      state.address = {};
    },
    resetUpdateAddress: (state) => {
      state.updateAddress = {};
    },
    chooseAddress: (state, { payload }) => {
      state.address = payload;
    },
    chooseUpdateAddress: (state, { payload }) => {
      state.updateAddress = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerList.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.customerList = payload;
        state.getStatus = "success";
      })
      .addCase(getCustomerList.pending, (state) => {
        state.getStatus = "pending";
      })
      .addCase(addCustomer.pending, (state) => {
        state.status = "pending";
      })
      .addCase(addCustomer.fulfilled, (state, { payload }) => {
        console.log(payload);
        console.log("dispatch");
        state.status = "success";
        state.customerList = payload.data;
      })
      .addCase(updateCustomer.fulfilled, (state, { payload }) => {
        console.log(payload);
        console.log("dispatch");
        state.status = "success";
        state.customerList = payload.data;
      })
      .addCase(deleteCustomer.fulfilled, (state, { payload }) => {
        state.customerList = state.customerList.filter(
          (l) => l._id !== payload.data._id
        );
      });
  },
});
export const CustomerSlice = CustomerList.reducer;
export const {
  resetAddress,
  chooseAddress,
  chooseUpdateAddress,
  resetUpdateAddress,
} = CustomerList.actions;
