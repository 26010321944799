import {
  Checkbox,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { formatMoney } from "../../app/helper/formatMoney";

const InvoiceTable = ({ item }) => {
  console.log(item);
  const { paginationData } = useSelector((state) => state.InvoiceSlice);
  const { publisher_list } = useSelector((state) => state.AllBookSlice);
  const publisherName = publisher_list?.filter(
    (l) => l._id === item.publisher_id
  );
  // console.log(item?.sale_book?.books);
  let netAmount = 0;
  for (let i = 0; i < item?.sale_book?.books.length; i++) {
    const totalAmount = item?.sale_book?.books[i].total_amount;
    netAmount += totalAmount;
  }
  let totalQTY = 0;
  for (let i = 0; i < item?.sale_book?.books.length; i++) {
    const totalAmount = item?.sale_book?.books[i].qty;
    totalQTY += totalAmount;
  }
  let totalDiscount = 0;
  for (let i = 0; i < item?.sale_book?.books.length; i++) {
    const totalAmount = item?.sale_book?.books[i].discount;
    totalDiscount += totalAmount;
  }
  return (
    <TableRow key={item._id}>
      <TableCell align="center">
        <Checkbox />
      </TableCell>
      <TableCell align="center">
        <Stack sx={{ marginBottom: "3px" }} spacing={1}>
          <Typography variant="caption" fontWeight={"bold"}>
            {item?.invoice_code}
          </Typography>
          <Typography variant="caption">
            {new Date(item?.created_at).toISOString().substring(0, 10)}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell align="center">
        <Typography variant="caption" sx={{ marginBottom: "3px" }}>
          {publisherName[0]?.name}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="caption" sx={{ marginBottom: "3px" }}>
          {item?.user?.name ?? "Admin"}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="caption" sx={{ marginBottom: "3px" }}>
          {item?.customer?.name}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="caption" sx={{ marginBottom: "3px" }}>
          {totalQTY}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="caption" sx={{ marginBottom: "3px" }}>
          {formatMoney(netAmount)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="caption" sx={{ marginBottom: "3px" }}>
          {item?.tracking_code ?? "N/A"}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="caption" sx={{ marginBottom: "3px" }}>
          {formatMoney(item?.courier?.charges)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="caption" sx={{ marginBottom: "3px" }}>
          {formatMoney(item?.delivery_charges)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="caption" sx={{ marginBottom: "3px" }}>
          {item?.courier?.name}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="caption" sx={{ marginBottom: "3px" }}>
          {formatMoney(totalDiscount)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="caption" sx={{ marginBottom: "3px" }}>
          {item?.delivery_date}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="caption" sx={{ marginBottom: "3px" }}>
          {formatMoney(item?.net_amount ?? "0")}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Link to={`invoices-${item._id}`}>
          <EditIcon
            fontSize="small"
            sx={{ cursor: "pointer", color: "#2152ff" }}
          />
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default InvoiceTable;
