// api
export const api = "https://api.9publishers.com/api";

export const imageApi = "https://api.9publishers.com";
// export const api = "http://192.168.2.120:8001/api";
// export const imageApi = "http://192.168.2.120:8001";
// export const api = 'http://192.168.2.103:8085/api';
// export const imageApi = 'http://192.168.2.103:8085';
// export const api =
//     'http://192.168.100.27/OfficeProject/nineP/public/api';
// export const imageApi =
//     'http://192.168.100.27/OfficeProject/nineP/public';
// export const api = "http://localhost:8000/api";
// export const imageApi = "http://localhost:8000";
//useFetch
async function useFetch({ url, method, data = null, token }) {
    const generalData = (token, method) => {
        // console.log(url);
        return {
            method: `${method}`,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        };
    };
    const test = generalData(token, method);
    if (data) {
        const post = JSON.stringify(data);
        test["body"] = post;
    }
    // console.log(data);
    const response = await fetch(`${api}/admin/${url}`, test);
    const result = await response.json();
    // console.log(result);
    return result;
}
export default useFetch;

export const usePostForm = async ({ url, method, data, token }) => {
    const generalData = (token, method) => {
        return {
            method: `${method}`,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        };
    };
    const test = generalData(token, method);
    if (data) {
        test["body"] = data;
    }
    // console.log(test);
    const response = await fetch(`${api}/admin/${url}`, test);
    const result = await response.json();
    // console.log(result);
    return result;
};

// image fetching url
