import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMagnifyingGlass,
  faPuzzlePiece,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { reloadHandler } from "./features/login/LoginSlice";
import Login from "./pages/Login";
import Register from "./pages/Register";
import PrivateRoute from "./routes/PrivateRoute";

function App() {
  const { token } = useSelector((state) => state.loginInfo);
  console.log(token);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(reloadHandler());
  }, []);
  // dispatch(reloadHandler());
  library.add(faPuzzlePiece, faMagnifyingGlass);
  return (
    <Routes>
      {token ? (
        <>
          <Route path="/*" element={<PrivateRoute />} />
          <Route index element={<Navigate to="/dashboard" />} />
        </>
      ) : (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </>
      )}
    </Routes>
  );
}

export default App;
