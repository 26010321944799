import {
  Box,
  Stack,
  Typography,
  Paper,
  Avatar,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import MkButton from "../../app/assets/theme/MkButton";
import { useTheme } from "@mui/material/styles";
import CompanyLinkTab from "./CompanyLinkTab";
import { useParams } from "react-router-dom";
import TabView from "./TabView";
import { useDispatch, useSelector } from "react-redux";
import { getPublisherDetail } from "../../features/company/CompanyApi";
import EditCompany from "./EditCompany";
import Loading from "../../app/components/Loading/Loading";
import { imageApi } from "../../app/hooks";
import { resetLoading } from "../../features/company/CompanySlice";
import { allExpenseCategories } from "../../features/expense_category/ExpenseCategoryApi";
import { getCouriersList } from "../../features/couriers/CouriersApi";

function CompanyDetail() {
  const theme = useTheme();
  const { name } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [editOpen, setEditOpen] = useState(false);

  const dispatch = useDispatch();
  const { publisherDetail, loading } = useSelector(
    (state) => state.companyDetail
  );
  // console.log(`From Detail ${publisherDetail}`);
  console.log(loading);
  const id = name.split("diuu-")[1];
  console.log(id);
  const companyEdit = () => {
    setEditOpen(true);
  };

  useEffect(() => {
    dispatch(getPublisherDetail({ id }));
    dispatch(resetLoading());
    dispatch(getCouriersList({ id: id, page: 1, limit: 10 }));
  }, []);

  const renderCompanyDetail = (
    // loading ? (
    //   <Stack direction="row" spacing={3} alignItems="center">
    //     <Box
    //       sx={{
    //         borderRadius: 2.5,
    //         width: 65,
    //         height: 65,
    //         objectFit: "cover",
    //       }}
    //     >
    //       <Loading
    //         variant="rectangular"
    //         width="100%"
    //         height="100%"
    //         borderRadius={2.5}
    //       />
    //     </Box>
    //     <Box>
    //       <Typography component="div" variant="h3" sx={{ fontWeight: "bold" }}>
    //         <Loading
    //           borderRadius={1.2}
    //           variant="text"
    //           width="350px"
    //           height="20px"
    //         />
    //       </Typography>
    //       <Stack spacing={3} direction="row">
    //         <Typography variant="body2" component="div">
    //           <Loading
    //             borderRadius={1}
    //             variant="text"
    //             width="100px"
    //             height="15px"
    //           />
    //         </Typography>
    //         <Typography variant="body2" component="div">
    //           <Loading
    //             borderRadius={1}
    //             variant="text"
    //             width="100px"
    //             height="15px"
    //           />
    //         </Typography>
    //         <Typography variant="body2" component="div">
    //           <Loading
    //             borderRadius={1}
    //             variant="text"
    //             width="100px"
    //             height="15px"
    //           />
    //         </Typography>
    //       </Stack>
    //     </Box>
    //   </Stack>
    // ) : (
    <Stack direction="column" spacing={3}>
      <Box sx={{ position: "relative", mb: 1 }}>
        <Avatar
          sx={{
            borderRadius: 2.5,
            width: 1000,
            height: 220,
            objectFit: "cover",
            margin: "auto",
          }}
          src={`${imageApi}${publisherDetail?.banner_image}`}
          alt={publisherDetail?.name}
        />
        <Avatar
          sx={{
            borderRadius: 2.5,
            width: 100,
            height: 100,
            objectFit: "cover",
            position: "absolute",
            top: 10,
            left: 200,
            border: "2px solid black",
          }}
          src={`${imageApi}${publisherDetail?.publisher_logo}`}
          alt={publisherDetail?.name}
        />
      </Box>
      <Box>
        <Typography
          sx={{
            width: 1000,
            margin: "auto",
          }}
          component="div"
          variant="subtitle1"
          fontWeight={"bold"}
        >
          {publisherDetail?.name}
        </Typography>
        <Stack
          sx={{
            width: 1000,
            margin: "auto",
          }}
          spacing={3}
          direction="row"
        >
          <Typography variant="subtitle2" component="div">
            {publisherDetail?.contact_person}
          </Typography>
          <Typography variant="subtitle2" component="div">
            {publisherDetail?.phone}
          </Typography>
          <Typography variant="subtitle2" component="div">
            {publisherDetail?.address}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
  // );

  return (
    <Box sx={{ minHeight: "calc(100vh - 110px)" }}>
      {loading ? (
        <Box
          sx={{ width: 1600, height: 400, padding: "25px 25px 0px 25px" }}
          component={Paper}
        >
          <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            sx={{ paddingTop: "100px" }}
          >
            <Box
              sx={{
                borderRadius: 2.5,
                width: 65,
                height: 65,
                // width: 1000,
                // height: 220,
                objectFit: "cover",
              }}
            >
              <Loading
                variant="rectangular"
                width="100%"
                height="100%"
                borderRadius={2.5}
              />
            </Box>
            <Box>
              <Typography
                component="div"
                variant="h3"
                sx={{ fontWeight: "bold" }}
              >
                <Loading
                  borderRadius={1.2}
                  variant="text"
                  width="350px"
                  height="20px"
                />
              </Typography>
              <Stack spacing={3} direction="row">
                <Typography variant="body2" component="div">
                  <Loading
                    borderRadius={1}
                    variant="text"
                    width="100px"
                    height="15px"
                  />
                </Typography>
                <Typography variant="body2" component="div">
                  <Loading
                    borderRadius={1}
                    variant="text"
                    width="100px"
                    height="15px"
                  />
                </Typography>
                <Typography variant="body2" component="div">
                  <Loading
                    borderRadius={1}
                    variant="text"
                    width="100px"
                    height="15px"
                  />
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Box>
      ) : (
        // <Box
        //   sx={{
        //     width: "100%",
        //     display: "flex",
        //     alignItems: "center",
        //     justifyContent: "center",
        //     // marginTop: 10,
        //     // marginLeft: 40,
        //   }}
        // >
        //   <CircularProgress />
        // </Box>
        <Box sx={{ padding: "25px 25px 0px 25px" }} component={Paper}>
          <Box>
            <Stack direction="column" justifyContent="center">
              <Stack direction="row" spacing={2} justifyContent="end">
                <MkButton
                  mkcolor={`linear-gradient(310deg, ${theme.palette.gradientDark.main}, ${theme.palette.gradientDark.light})`}
                  size="small"
                  onClick={companyEdit}
                >
                  Edit
                </MkButton>
              </Stack>
              {renderCompanyDetail}
            </Stack>
          </Box>
          <CompanyLinkTab tabIndex={tabIndex} setTabIndex={setTabIndex} />
        </Box>
      )}

      <Box>
        <TabView tabIndex={tabIndex} setTabIndex={setTabIndex} />
      </Box>

      {/*  Edit company model box */}
      {publisherDetail ? (
        <EditCompany
          open={editOpen}
          setOpen={setEditOpen}
          companyDetail={publisherDetail}
          // companyDetail={publisherDetail}
        />
      ) : (
        <></>
      )}
    </Box>
  );
}

export default CompanyDetail;
