import { createSlice } from "@reduxjs/toolkit";
import { updatePrintSetting } from "./SettingApi";
import { getPublisherDetail } from "./CompanyApi";

const settingInitial = {
  setting: null,
};

export const Setting = createSlice({
  name: "setting",
  initialState: settingInitial,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPublisherDetail.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.setting = payload.publisher.print_setting;
      })
      .addCase(updatePrintSetting.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.setting = payload.data.print_setting;
      });
  },
});

export const SettingSlice = Setting.reducer;
