import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "../app/components/layout/Layout.jsx";
import CompanyDetail from "../components/company/CompanyDetail.jsx";
import Admin from "../pages/Admin.jsx";
import Author from "../pages/Author";
import Book from "../pages/Book.jsx";
import City from "../pages/City.jsx";
import Company from "../pages/Company.jsx";
import Dashboard from "../pages/Dashboard.jsx";
import Division from "../pages/Division.jsx";
import ExpenseCategory from "../pages/ExpenseCategory";
import Genre from "../pages/Genre.jsx";
import Profile from "../pages/Profile.jsx";
import Township from "../pages/Township.jsx";
import Sales from "../pages/Sales.jsx";
import Invoice from "../components/customer/Invoice.jsx";

function PrivateRoute() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="admins" element={<Admin />} />
        <Route path="profile" element={<Profile />} />
        <Route path="cities" element={<City />} />
        <Route path="divisions" element={<Division />} />
        <Route path="townships" element={<Township />} />
        <Route path="books" element={<Book />} />
        <Route path="expense_category" element={<ExpenseCategory />} />
        <Route path="genre" element={<Genre />} />
        <Route path="author" element={<Author />} />
        <Route path="sales">
          <Route path="invoice" element={<Invoice />} />
          <Route path=":id" element={<Invoice />} />
          <Route index element={<Sales />} />
        </Route>
        <Route path="publishers">
          <Route path=":name" element={<CompanyDetail />} />
          <Route index element={<Company />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
}

export default PrivateRoute;
