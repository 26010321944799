import { TableCell, TableRow, Typography, Stack } from "@mui/material";
import React from "react";
import "font-awesome/css/font-awesome.min.css";
import MkButton from "../../app/assets/theme/MkButton";
import { ExpenseCategoryDelete } from "../../features/expense_category/ExpenseCategoryApi";
import { useDispatch } from "react-redux";
import IconWrap from "../../app/components/IconWrap";
import ExpenseCategoryUpdateDrawer from "./ExpenseCategoryUpdateDrawer";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";

function ExpenseCategoryTableBody({ item }) {
  const dispatch = useDispatch();
  // extra
  const { paginationData } = useSelector((state) => state.ExpenseCategorySlice);
  return (
    <TableRow>
      <TableCell>
        <IconWrap class={`fa ${item.icon}`} size="lg" />
      </TableCell>

      <TableCell>
        <Typography variant="caption">{item.name}</Typography>
      </TableCell>
      {/* <TableCell>
        <Typography variant="caption">{item._id}</Typography>
      </TableCell> */}

      {/* <TableCell sx={{ width: 120 }} align="center">
        <ExpenseCategoryUpdateDrawer inputValues={item} />
      </TableCell> */}

      {/* <TableCell sx={{ width: 120 }} align="center">
        <MkButton
                    mkcolor={`linear-gradient(310deg, #141727, #3a416f)`}
                    size="small"
                    textTransform="capitalize"
                    onClick={() =>
                        dispatch(
                            ExpenseCategoryDelete({
                                id: item.id,
                                page: paginationData.page,
                                limit: paginationData.limit,
                            })
                        )
                    }
                >
                    Delete
                </MkButton>
      </TableCell> */}

      <TableCell sx={{ width: 100 }} align="center">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <ExpenseCategoryUpdateDrawer inputValues={item} />
          <DeleteIcon
            fontSize="small"
            onClick={() =>
              dispatch(
                ExpenseCategoryDelete({
                  id: item._id,
                  page: paginationData.page,
                  limit: paginationData.limit,
                })
              )
            }
            sx={{ cursor: "pointer", color: "red" }}
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
}

export default ExpenseCategoryTableBody;
