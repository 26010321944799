import { createAsyncThunk } from "@reduxjs/toolkit";

import useFetch, { api, usePostForm } from "../../app/hooks";

export const getCustomerList = createAsyncThunk(
  "getCustomerList/getCustomerList",
  async ({ publisher_id, phNumber }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    const result = useFetch({
      url: `customers/${publisher_id}?admin_id=${admin._id}&phone=${phNumber}`,
      method: "GET",
      token: token,
    });
    return result;
  }
);
export const addCustomer = createAsyncThunk(
  "addCustomer/addCustomer",
  async ({ data }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    console.log(data);
    const response = await fetch(
      `${api}/admin/customers?admin_id=${admin._id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
    // const result = usePostForm({
    //   url: `users?admin_id=${admin._id}`,
    //   method: "POST",
    //   token: token,
    //   data: data,
    // });
    // return result;
  }
);
export const updateCustomer = createAsyncThunk(
  "updateCustomer/updateCustomer",
  async ({ data, id }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    console.log(data);
    const response = await fetch(
      `${api}/admin/customers/${id}?admin_id=${admin._id}&_method=PUT`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
    // const result = usePostForm({
    //   url: `users?admin_id=${admin._id}`,
    //   method: "POST",
    //   token: token,
    //   data: data,
    // });
    // return result;
  }
);

export const deleteCustomer = createAsyncThunk(
  "deleteCustomer/deleteCustomer",
  async ({ id }, { getState }) => {
    console.log(id);
    const { token, admin } = getState().loginInfo;
    const result = useFetch({
      url: `customers/${id}?admin_id=${admin._id}`,
      method: "DELETE",
      token: token,
    });
    return result;
  }
);
