import { createAsyncThunk } from "@reduxjs/toolkit";
import useFetch from "../../app/hooks";

export const updatePrintSetting = createAsyncThunk(
  "updatePrintSetting/updatePrintSetting",
  async ({ data }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    const result = useFetch({
      url: `updateSetting?admin_id=${admin._id}`,
      method: "POST",
      token: token,
      data: data,
    });
    return result;
  }
);
