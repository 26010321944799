import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Stack,
  IconButton,
  InputLabel,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ProductAddDrawer from "../../../components/company/product/ProductAddDrawer";
import MkButton from "../../../app/assets/theme/MkButton/index";
import TableBar from "../../../app/components/Table/TableBar";
import { allBook } from "../../../features/book/BookApi";
import ProductTableBody from "./ProductTableBody";
import TableFooterPagination from "../../../app/components/Table/TableFooterPagination";
import { changePaginationData } from "../../../features/book/BookSlice";
import SimpleInput from "../../../app/components/SimpleInput";
import YearPicker from "../../../app/components/YearPicker";
import MkAutoComplete from "../../../app/assets/theme/MkAutoComplete";
import CircularProgress from "@mui/material/CircularProgress";
import { LocalBar } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

function ProductTable() {
  const dispatch = useDispatch();
  const { books, pagination, getStatus } = useSelector(
    (state) => state.BookSlice
  );
  const { publisherDetail, loading } = useSelector(
    (state) => state.companyDetail
  );

  // const { genres_dropdown } = useSelector((state) => state.GenreSlice);

  console.log("all books ", books);
  // console.log("genres_dropdown ", genres_dropdown);
  // console.log("publisherDetail ", publisherDetail);
  /* +++++++++++++++++ Pagination +++++++++++++++++ */

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [bookTitle, setBookTitle] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [releaseYear, setReleaseYear] = useState(null);
  const [genreOption, setGenreOption] = useState("");
  const { genres_dropdown } = useSelector((state) => state.GenreSlice);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? rowsPerPage - books?.length : rowsPerPage - books?.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  console.log(publisherDetail);
  const location = useLocation();
  console.log(location);
  const str = location.pathname;
  const number = str.split("diuu-")[1];

  console.log(number);
  useEffect(() => {
    dispatch(
      allBook({
        // id: publisherDetail?._id,
        id: number,
        page: page + 1,
        limit: rowsPerPage,
        book_title: "",
        author_name: "",
        released_year: "",
        genre: "",
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      allBook({
        // id: publisherDetail?._id,
        id: number,
        page: page + 1,
        limit: rowsPerPage,
        book_title: "",
        author_name: "",
        released_year: "",
        genre: "",
      })
    );

    // mark to where page reach
    dispatch(changePaginationData({ page: page + 1, limit: rowsPerPage }));
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (books?.length === 0) {
      setRowsPerPage(10);
      setPage(0);
    }
  }, [pagination]);

  useEffect(() => {
    dispatch(
      allBook({
        // id: publisherDetail?._id,
        // id: combinedString,
        id: number,
        page: page + 1,
        limit: rowsPerPage,
        book_title: bookTitle ?? "",
        author_name: authorName ?? "",
        released_year: releaseYear?.$y ?? "",
        genre: genreOption ?? "",
      })
    );
  }, [publisherDetail, loading]);

  return (
    <Box component={Paper} sx={{ padding: "0px 25px 25px 25px" }} mt={5}>
      <TableBar>
        <Stack direction="row" alignItems={"center"} sx={{ flex: "1 1 100%" }}>
          <Typography variant="h4" component="div">
            <Typography variant="subtitle1"> Books List</Typography>
          </Typography>

          {/* <Box sx={{ width: "230px", marginLeft: "20px" }}>
            <MkAutoComplete
              name="genre"
              placeholder="Choose Genre"
              noOptionsText="No Genre"
              fullWidth
              options={genres_dropdown}
              getOptionLabel={(genres_dropdown) =>
                genres_dropdown?.name ?? genres_dropdown
              }
              isOptionEqualToValue={(genres_dropdown, value) =>
                genres_dropdown.id === value.id
              }
              onChange={(event, newValue) => {
                setGenreOption(newValue);
                if (newValue == null || newValue.length < 0) {
                  dispatch(
                    allBook({
                      id: publisherDetail.id,
                      page: page + 1,
                      limit: rowsPerPage,
                      book_title: bookTitle ?? "",
                      author_name: authorName ?? "",
                      released_year: releaseYear?.$y ?? "",
                      genre: "",
                    })
                  );
                }
              }}
              value={genreOption}
            />
          </Box> */}

          <Box sx={{ width: "200px", marginLeft: "20px" }}>
            {/* <SimpleInput
              name="genre"
              placeholder="Type Genre"
              value={genreOption}
              onChange={(e) => setGenreOption(e.target.value)}
              endAdornment={
                <IconButton
                  sx={{
                    visibility: genreOption ? "visible" : "hidden",
                  }}
                  onClick={() => {
                    setGenreOption("");
                    dispatch(
                      allBook({
                        id: publisherDetail._id,
                        page: page + 1,
                        limit: rowsPerPage,
                        book_title: bookTitle ?? "",
                        author_name: authorName ?? "",
                        released_year: releaseYear?.$y ?? "",
                        genre: "",
                      })
                    );
                  }}
                >
                  <ClearIcon
                    sx={{
                      fontSize: "14.5px",
                    }}
                  />
                </IconButton>
              }
            /> */}
            <MkAutoComplete
              name="genres"
              fullWidth
              placeholder="Choose Genres"
              options={genres_dropdown}
              isOptionEqualToValue={(option, value) =>
                option?._id === value?.id
              }
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                console.log("from on change", newValue);
                setGenreOption(newValue);
                if (newValue === null) {
                  setGenreOption("");
                  dispatch(
                    allBook({
                      id: publisherDetail._id,
                      page: page + 1,
                      limit: rowsPerPage,
                      book_title: bookTitle ?? "",
                      author_name: authorName ?? "",
                      released_year: releaseYear?.$y ?? "",
                      genre: "",
                    })
                  );
                }
              }}
              value={genreOption || null}
            />
          </Box>

          <Box sx={{ width: "150px", marginLeft: "20px" }}>
            <SimpleInput
              name="book_title"
              placeholder="Book Name"
              value={bookTitle}
              onChange={(e) => setBookTitle(e.target.value)}
              endAdornment={
                <IconButton
                  sx={{
                    visibility: bookTitle ? "visible" : "hidden",
                  }}
                  onClick={() => {
                    setBookTitle("");
                    dispatch(
                      allBook({
                        id: publisherDetail._id,
                        page: page + 1,
                        limit: rowsPerPage,
                        book_title: "",
                        author_name: authorName ?? "",
                        released_year: releaseYear?.$y ?? "",
                        genre: genreOption._id ?? "",
                      })
                    );
                  }}
                >
                  <ClearIcon
                    sx={{
                      fontSize: "14.5px",
                    }}
                  />
                </IconButton>
              }
            />
          </Box>

          <Box sx={{ width: "150px", marginLeft: "20px" }}>
            <SimpleInput
              name="author_name"
              placeholder="Author Name"
              value={authorName}
              onChange={(e) => setAuthorName(e.target.value)}
              endAdornment={
                <IconButton
                  sx={{
                    visibility: authorName ? "visible" : "hidden",
                  }}
                  onClick={() => {
                    setAuthorName("");
                    dispatch(
                      allBook({
                        id: publisherDetail._id,
                        page: page + 1,
                        limit: rowsPerPage,
                        book_title: bookTitle ?? "",
                        author_name: "",
                        released_year: releaseYear?.$y ?? "",
                        genre: genreOption._id ?? "",
                        // genre: genreOption?.id ?? "",
                      })
                    );
                  }}
                >
                  <ClearIcon
                    sx={{
                      fontSize: "14.5px",
                    }}
                  />
                </IconButton>
              }
            />
          </Box>

          <Box
            sx={{
              width: "155px",
              marginLeft: "20px",
            }}
          >
            <YearPicker
              views={["year"]}
              placeholder="Release Year"
              value={releaseYear}
              onChange={(newValue) => {
                setReleaseYear(newValue);
                dispatch(
                  allBook({
                    id: publisherDetail._id,
                    page: page + 1,
                    limit: rowsPerPage,
                    book_title: bookTitle ?? "",
                    author_name: authorName ?? "",
                    released_year: "",
                    // released_year: releaseYear ?? "",
                    genre: genreOption._id ?? "",
                  })
                );
              }}
            />
          </Box>

          <MkButton
            sx={{ marginLeft: "10px" }}
            mkcolor={`linear-gradient(310deg, #0adc7f, #02f377)`}
            size="large"
            textTransform="capitalize"
            onClick={() => {
              dispatch(
                allBook({
                  id: publisherDetail._id,
                  page: page + 1,
                  limit: rowsPerPage,
                  book_title: bookTitle ?? "",
                  author_name: authorName ?? "",
                  released_year: releaseYear?.$y ?? "",
                  genre: genreOption._id ?? "",
                })
              );
            }}
          >
            Search
          </MkButton>
        </Stack>
        <ProductAddDrawer />
      </TableBar>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">Cover Image</Typography>
              </TableCell>
              <TableCell sx={{ width: "200px" }}>
                <Typography variant="subtitle2">Title</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">ISBN</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Edition</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Release</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Release Date</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" textAlign="center">
                  COGS <br />
                  (Cost Of Goods Sold)
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Units</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">UCost</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" align="left">
                  Expenses
                </Typography>
              </TableCell>
              {/* <TableCell align="center">
                <Typography variant="subtitle2">Edit</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">Delete</Typography>
              </TableCell> */}
              <TableCell align="center">
                <Typography variant="subtitle2">Action</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          {console.log(books)}
          <TableBody>
            {loading || getStatus === "pending" ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 10,
                  marginLeft: 40,
                }}
              >
                <CircularProgress />
              </Box>
            ) : books?.length === 0 ? (
              <Typography
                sx={{
                  fontSize: "1.4rem",
                  textAlign: "center",
                  marginTop: "80px",
                }}
              >
                There is no book.
              </Typography>
            ) : (
              books?.map((row, index) => (
                <ProductTableBody
                  item={row}
                  key={index}
                  companyDetail={publisherDetail}
                />
              ))
            )}
            {/* {loading && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 10,
                  marginLeft: 40,
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {!loading && books?.length === 0 && (
              <Typography
                sx={{
                  fontSize: "1.4rem",
                  textAlign: "center",
                  marginTop: "80px",
                }}
              >
                There is no book.
              </Typography>
            )}
            {!loading && books?.length > 0 && (
              <>
                {books?.map((row, index) => {
                  return (
                    <ProductTableBody
                      item={row}
                      key={index}
                      companyDetail={publisherDetail}
                    />
                  );
                })}
              </>
            )} */}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53.3 * emptyRows }}>
                <TableCell colSpan={11} />
              </TableRow>
            )}
          </TableBody>

          {books?.length > 0 && (
            <TableFooterPagination
              rowsPerPageOptions={[10, 20, 30]}
              tableList={pagination?.total}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}

export default ProductTable;
