import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import MkButton from "../app/assets/theme/MkButton";
import TableBar from "../app/components/Table/TableBar";
import { allGenres } from "../features/genre/GenreApi";
import GenreTableBody from "../components/genre/GenreTableBody";
import TableFooterPagination from "../app/components/Table/TableFooterPagination";
import GenreAddDrawer from "../components/genre/GenreAddDrawer";
import { changePaginationData } from "../features/genre/GenreSlice";

function Genre() {
  const dispatch = useDispatch();
  const { genres, pagination } = useSelector((state) => state.GenreSlice);

  /* +++++++++++++++++ Pagination +++++++++++++++++ */

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? rowsPerPage - genres?.length : rowsPerPage - genres?.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(allGenres({ page: page + 1, limit: rowsPerPage }));

    // mark to where page reach
    dispatch(changePaginationData({ page: page + 1, limit: rowsPerPage }));
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (genres?.length === 0) {
      console.log(pagination, genres);
      setRowsPerPage(10);
      setPage(0);
    }
  }, [pagination]);

  /* --------------------------------------------  */

  return (
    <Box component={Paper} sx={{ padding: "0px 25px 25px 25px" }}>
      <TableBar>
        Book Genre Lists
        <Stack direction="row" gap={2} alignItems="center">
          {/* here is need search  */}
          <GenreAddDrawer />
        </Stack>
      </TableBar>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">Name</Typography>
              </TableCell>
              {/* <TableCell align="center">
                <Typography variant="subtitle2">Edit</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">Delete</Typography>
              </TableCell> */}

              <TableCell align="center">
                <Typography variant="subtitle2">Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {genres?.map((row, index) => (
              <GenreTableBody item={row} key={index} />
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53.3 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>

          {genres?.length > 0 && (
            <TableFooterPagination
              rowsPerPageOptions={[10, 20, 30]}
              tableList={pagination?.total}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Genre;
