import { createSlice, current } from "@reduxjs/toolkit";
import {
  addPublisher,
  editPublisher,
  getPublisherDetail,
  getPublisherList,
  statusPublisher,
  getPublisherUserByEmail,
  getPublisherUserRole,
  addPublisherUser,
  editPublisherUserDetail,
  statusPublisherUser,
  getPublisherUserByPhone,
  deletePublisherUser,
} from "./CompanyApi";

const publisherListInitial = {
  publisherList: [],
  publisherPagination: null,
  publisherStatus: null,
};

export const PublisherList = createSlice({
  name: "PublisherList",
  initialState: publisherListInitial,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPublisherList.pending, (state, { payload }) => {
        state.publisherStatus = "pending";
      })
      .addCase(getPublisherList.fulfilled, (state, { payload }) => {
        const { data, ...rest } = payload;
        state.publisherList = data;
        state.publisherPagination = rest;
        state.publisherStatus = "success";
      })
      .addCase(addPublisher.fulfilled, (state, { payload }) => {
        if (
          state.publisherPagination.current_page ===
          state.publisherPagination.last_page
        ) {
          state.publisherList.push(payload.publisher);
        }
      })
      .addCase(statusPublisher.fulfilled, (state, { payload }) => {
        state.publisherList = state.publisherList.map((publisher) => {
          if (publisher.id === payload.publisher.id) {
            return {
              ...publisher,
              active: payload.publisher.active ? 1 : 0,
            };
          } else {
            return { ...publisher };
          }
        });
      });
  },
});

export const PublisherListSlice = PublisherList.reducer;

const publisherDetailInitial = {
  publisherDetail: null,
  loading: true,
  error: null,
  editStatus: null,
};

export const CompanyDetail = createSlice({
  name: "CompanyDetail",
  initialState: { ...publisherDetailInitial },
  reducers: {
    resetLoading: (state) => {
      state.loading = true;
    },
    setPrintSetting: (state, { payload }) => {
      state.companyDetail.print_setting = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(getPublisherDetail.pending, (state, { payload }) => {
      //   state.loading = true;
      //   //pending process remove pervious state company detail
      //   state.publisherDetail = {};
      // })
      .addCase(getPublisherDetail.fulfilled, (state, { payload }) => {
        const { publisher } = payload;
        console.log(publisher);
        state.loading = false;
        state.publisherDetail = publisher;
      })
      .addCase(editPublisher.pending, (state) => {
        state.editStatus = "pending";
      })
      .addCase(editPublisher.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.publisherDetail = payload.publisher;
        state.error = payload.error;
        state.editStatus = "success";
      });
  },
});

export const companyDetail = CompanyDetail.reducer;
export const { resetLoading, setPrintSetting } = CompanyDetail.actions;

const initialCompanyUser = {
  usersList: [],
  loading: true,
  userByEmail: null,
  userByPhone: null,
};

export const companyUserList = createSlice({
  name: "companyUserList",
  initialState: { ...initialCompanyUser },
  reducers: {
    removeUserByEmail: (state, { payload }) => {
      state.userByEmail = null;
    },
    removeUserByPhone: (state, { payload }) => {
      state.userByPhone = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPublisherDetail.fulfilled, (state, { payload }) => {
        const { users } = payload;
        state.usersList = users;
      })
      .addCase(addPublisherUser.fulfilled, (state, { payload }) => {
        state.usersList.push(payload.user);
        state.userByEmail = null;
      })
      .addCase(getPublisherUserByEmail.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getPublisherUserByEmail.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userByEmail = payload;
      })
      .addCase(deletePublisherUser.fulfilled, (state, { payload }) => {
        state.usersList = state.usersList.map((user) => {
          console.log(payload.user);
          if (user._id == payload.user._id) {
            return {
              ...user,
              active: payload.user.active,
              // status: payload.user.status,
            };
          } else {
            return { ...user };
          }
        });
      })
      .addCase(getPublisherUserByPhone.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.loading = false;
        state.userByPhone = payload;
      })
      .addCase(editPublisherUserDetail.fulfilled, (state, { payload }) => {
        state.usersList = state.usersList.map((user) => {
          if (user?._id === payload?.user?._id) {
            return payload.user;
          } else {
            return user;
          }
        });
      })
      .addCase(statusPublisherUser.fulfilled, (state, { payload }) => {
        state.usersList = state.usersList.map((user) => {
          console.log(payload.user);
          if (user._id == payload.user._id) {
            return {
              ...user,
              authorities: payload.user.authorities,
              // status: payload.user.status,
            };
          } else {
            return { ...user };
          }
        });
      });
  },
});

export const companyUser = companyUserList.reducer;
export const { removeUserByEmail, removeUserByPhone } = companyUserList.actions;

const initialCompanyRole = {
  roles: [],
};

const companyUserRoleList = createSlice({
  name: "companyUserRole",
  initialState: { ...initialCompanyRole },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPublisherUserRole.fulfilled, (state, { payload }) => {
      const options = payload.map((data) => {
        return {
          label: data.description,
          id: data._id,
        };
      });
      state.roles = options;
    });
  },
});
export const companyUserRole = companyUserRoleList.reducer;
export const { getRoles } = companyUserRoleList.actions;

export const initialCompanyUserDetail = {
  userInfo: {},
};
export const companyUserDetail = createSlice({
  name: "companyUserDetail",
  initialState: { ...initialCompanyUserDetail },
  reducers: {
    initCompanyUserDetail: (state) => {
      return initialCompanyUserDetail;
    },
    changeUserInfo: (state, { payload }) => {
      state.userInfo = payload;
    },
  },
});
export const companyUserInfo = companyUserDetail.reducer;
export const companyUserDetailActions = companyUserDetail.actions;
