import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Alert from "./../../components/Alert";
import { Routes } from "./Data";

import {
  Box,
  CssBaseline,
  Divider,
  IconButton,
  List,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Avatar,
  ListItemText,
  Collapse,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ExpandLess, ExpandMore, Menu, MenuOpen } from "@mui/icons-material";
import PublicIcon from "@mui/icons-material/Public";

import NavItem from "./NavItem";
import LeftNav from "./LeftNav";
import RightNav from "./RightNav";
import { useDispatch } from "react-redux";
import { getPublisherUserRole } from "../../../features/company/CompanyApi";
import { Cities } from "../../../features/cites/CitiesApi";
import Footer from "../Footer";
import logo from "../../assets/images/nineP.png";
import {
  allDivision,
  divisionOptions,
} from "../../../features/location/DivisionApi";
import { citiesDropdown } from "../../../features/location/CityApi";
import {
  allExpenseCategories,
  allExpenseCategory,
} from "../../../features/expense_category/ExpenseCategoryApi";
import { getPublisherDropDownList } from "../../../features/book/BookApi";
import { EditionDropdownList } from "../../../features/edition/EditionApi";
import { ReleaseDropdownList } from "../../../features/release/ReleaseApi";
import { GenreDropdownList } from "../../../features/genre/GenreApi";
import { useSelector } from "react-redux";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  height: "65px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...(!open && { justifyContent: "center" }),
}));

const Main = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // display: "flex",
  // flexDirection: "column",
  position: "relative",
  width: "100%",
  minHeight: "100vh",
  padding: "0 40px",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const SideNav = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Layout() {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getPublisherUserRole());
    dispatch(Cities());
    dispatch(divisionOptions());
    dispatch(citiesDropdown());
    dispatch(allExpenseCategories());
    dispatch(getPublisherDropDownList());
    dispatch(GenreDropdownList());
    dispatch(EditionDropdownList());
    dispatch(ReleaseDropdownList());
  }, []);

  const [group, setGroup] = useState(false);
  const handleGroup = () => {
    if (open) {
      setGroup(!group);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <SideNav
        variant="permanent"
        open={open}
        sx={{
          "& .MuiDrawer-paper": {
            bgcolor: "#fff",
          },
        }}
      >
        <List open={open}>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                transitionProperty: "justifyContent",
                transitionDuration: "3s",
                minHeight: 48,
                justifyContent: open ? "space-between" : "center",
                px: 2.5,
              }}
            >
              <Avatar
                sx={{
                  transitionProperty: "display",
                  transitionDuration: "3s",
                  display: open ? "block" : "none",
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
                src={logo}
                alt="Logo"
              />

              {open ? (
                <IconButton
                  aria-label="close drawer"
                  onClick={handleDrawerClose}
                >
                  <MenuOpen style={{ color: "#000000" }} />
                </IconButton>
              ) : (
                <IconButton aria-label="open drawer" onClick={handleDrawerOpen}>
                  <Menu style={{ color: "#000000" }} />
                </IconButton>
              )}
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          {Routes.map((item) => (
            <NavItem item={item} key={item.text} />
          ))}

          <Link to={`/townships`}>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={handleGroup}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PublicIcon style={{ color: "#000000" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Locations"
                  sx={{
                    opacity: open ? 1 : 0,
                    color: "#000000 !important",
                  }}
                />
                {open ? group ? <ExpandLess /> : <ExpandMore /> : null}
              </ListItemButton>
            </ListItem>
          </Link>

          <Collapse
            in={group && open}
            timeout="auto"
            unmountOnExit
            sx={{ ml: "30px" }}
          >
            <List component="div" disablePadding>
              {/* first */}
              {/* <Link to={`/country`}>
                                <ListItem
                                    disablePadding
                                    sx={{display: 'block'}}
                                >
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open
                                                ? 'initial'
                                                : 'center',
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent:
                                                    'center',
                                            }}
                                        >
                                            <i
                                                classNameName="fa-solid fa-hyphen"
                                                style={{
                                                    color: '#000000',
                                                    fontSize: '30px',
                                                }}
                                            ></i>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Countries"
                                            sx={{
                                                opacity: open ? 1 : 0,
                                                color: '#000000 !important',
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </Link> */}

              {/* second */}
              <Link to={`/divisions`}>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <i
                        classNameName="fa-solid fa-hyphen"
                        style={{
                          color: "#000000",
                          fontSize: "30px",
                        }}
                      ></i>
                    </ListItemIcon>
                    <ListItemText
                      primary="Divisions"
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "#000000 !important",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>

              {/* third */}
              <Link to={`/cities`}>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: group ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: group ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <i
                        classNameName="fa-solid fa-hyphen"
                        style={{
                          color: "#000000",
                          fontSize: "30px",
                        }}
                      ></i>
                    </ListItemIcon>
                    <ListItemText
                      primary="Cities"
                      sx={{
                        opacity: group ? 1 : 0,
                        color: "#000000 !important",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>

              {/* fourth */}
              <Link to={`/townships`}>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: group ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: group ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <i
                        classNameName="fa-solid fa-hyphen"
                        style={{
                          color: "#000000",
                          fontSize: "30px",
                        }}
                      ></i>
                    </ListItemIcon>
                    <ListItemText
                      primary="Townships"
                      sx={{
                        opacity: group ? 1 : 0,
                        color: "#000000 !important",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            </List>
          </Collapse>
        </List>
      </SideNav>
      <Main open={open}>
        <Box
          component="header"
          sx={{
            borderRadius: "7px",
            height: "65px",
            padding: "0 1.5rem",
            mb: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <LeftNav />
          <RightNav />
        </Box>
        <Box mb={7}>
          <Outlet />
        </Box>

        {/* alert */}
        <Alert />

        {/* footer */}
        <Box sx={{ height: "50px" }} />
        <Footer />
      </Main>
    </Box>
  );
}
