import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CompanyUser from './CompanyUser';
import AddCompanyUser from './AddCompanyUser';

function CompanyUserTable({setTabIndex}) {
    const dispatch = useDispatch();
    const {usersList} = useSelector((state) => state.companyUser);

    useEffect(() => {
        // dispatch(getCompanyList({ page: page, limit: rowsPerPage }));
    }, []);
    return (
        <Box
            component={Paper}
            sx={{padding: '0px 25px 25px 25px', mt: 5}}
        >
            <Toolbar>
                <Typography
                    sx={{flex: '1 1 100%'}}
                    variant="subtitle1"
                    component="div"
                >
                    Publisher User List
                </Typography>

                <AddCompanyUser />
            </Toolbar>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width: '10%'}}>
                                <Typography variant="subtitle2">
                                    Profile
                                </Typography>
                            </TableCell>
                            <TableCell sx={{width: '20%'}}>
                                <Typography variant="subtitle2">
                                    Name
                                </Typography>
                            </TableCell>
                            <TableCell sx={{width: '20%'}}>
                                <Typography variant="subtitle2">
                                    Phone
                                </Typography>
                            </TableCell>
                            <TableCell sx={{width: '20%'}}>
                                <Typography variant="subtitle2">
                                    Role
                                </Typography>
                            </TableCell>
                            <TableCell width="10%">
                                <Typography variant="subtitle2">
                                    Status
                                </Typography>
                            </TableCell>
                            <TableCell align="center" width="10%">
                                <Typography variant="subtitle2">
                                    Actions
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersList?.map((row, index) => (
                            <CompanyUser
                                item={row}
                                key={index}
                                setTabIndex={setTabIndex}
                            />
                        ))}
                        {usersList?.length <= 5 && (
                            <TableRow
                                style={{
                                    height:
                                        85 * (5 - usersList.length),
                                }}
                            >
                                <TableCell colSpan={5} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default CompanyUserTable;
