import { configureStore } from "@reduxjs/toolkit";
import { loginInfo } from "../features/login/LoginSlice";
import { AlertControl } from "../app/components/Alert/alertSlice";
import {
  PublisherListSlice,
  companyDetail,
  companyUser,
  companyUserInfo,
  companyUserRole,
} from "../features/company/CompanySlice";
import { adminList } from "../features/admin/AdminSlice";
import { citiesData } from "../features/cites/CitiesSlice";
import { dragSlice } from "../components/company/dragNdrop/dragSlice";
import { csvexport } from "../features/csvexport/csvSlice";
import { ExpenseCategorySlice } from "../features/expense_category/ExpenseCategorySlice";
import { AuthorSlice, SearchAuthorSlice } from "../features/author/AuthorSlice";
import { ProductSlice } from "../features/product/ProductSlice";
import { DivisionListSlice } from "../features/location/DivisionSlice";
import { CitiesListSlice } from "../features/location/CitySlice";
import { TownshipListSlice } from "../features/location/TownshipSlice";
import { BookSlice, AllBookSlice } from "../features/book/BookSlice";
import { GenreSlice } from "../features/genre/GenreSlice";
import { EditionSlice } from "../features/edition/EditionSlice";
import { ReleaseSlice } from "../features/release/ReleaseSlice";
import { SelectCategoryListSlice } from "../components/company/product/SelectCategoriesFullScreenSlice";
import { ExpensesSlice } from "../features/expenses/expensesSlice";
import { AdminRegister } from "../features/Register/RegisterSlice";
import { CustomerSlice } from "../features/customer/CustomerSlice";
import { CourierSlice } from "../features/couriers/CouriersSlice";
import { InvoiceSlice } from "../features/invoice/InvoiceSlice";
import { SettingSlice } from "../features/company/SettingSlice";
const store = configureStore({
  reducer: {
    AdminRegister,
    AlertControl,
    loginInfo,
    PublisherListSlice,
    companyDetail,
    adminList,
    companyUser,
    companyUserInfo,
    companyUserRole,
    citiesData,
    dragSlice,
    csvexport,
    ExpenseCategorySlice,
    AuthorSlice,
    ProductSlice,
    SearchAuthorSlice,
    DivisionListSlice,
    CitiesListSlice,
    TownshipListSlice,
    BookSlice,
    AllBookSlice,
    GenreSlice,
    EditionSlice,
    ReleaseSlice,
    SelectCategoryListSlice,
    ExpensesSlice,
    CustomerSlice,
    CourierSlice,
    InvoiceSlice,
    SettingSlice,
  },
});

export default store;
