import {
  DialogContent,
  Dialog,
  Button,
  Typography,
  Card,
  CardContent,
  Stack,
  Checkbox,
  FormControl,
  FormControlLabel,
  Divider,
  Box,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  chooseAddress,
  chooseUpdateAddress,
  resetAddress,
  resetCustomerList,
} from "../../features/customer/CustomerSlice";
import { Add } from "@mui/icons-material";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCustomer from "./AddCustomer";
import UpdateCustomer from "./UpdateCustomer";
import { deleteCustomer } from "../../features/customer/CustomerApi";

const ListCustomer = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const { customerList, updateAddress, address } = useSelector(
    (state) => state.CustomerSlice
  );
  const handleClose = () => {
    setOpen(false);
    // dispatch(resetCustomerList());
  };
  const handleClick = (list) => {
    dispatch(chooseAddress(list));
    setOpen(false);
  };

  const handleAdd = () => {
    console.log("to add");
    // console.log(customerList);
    setAddOpen(true);
    // setOpen(false);
  };
  const handleUpdate = (list) => {
    setEditOpen(true);
    dispatch(chooseUpdateAddress(list));
  };

  const handleDelete = (id) => {
    console.log(id);
    dispatch(deleteCustomer({ id: id }));
    dispatch(resetAddress());
  };

  console.log({ customerList });
  return (
    <Dialog
      open={open}
      maxWidth="1000px"
      component="form"
      onClose={handleClose}
      //   onSubmit={handleAdd}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ padding: " 10px 25px" }}
        alignItems="center"
      >
        <Typography variant="h1">
          <strong>Customer Info</strong>
        </Typography>
        <Button startIcon={<Add />} onClick={handleAdd}>
          Add
        </Button>
      </Stack>
      <Typography variant="h2" sx={{ padding: " 10px 25px" }}>
        Select Address
      </Typography>
      <DialogContent sx={{ maxHeight: "400px", overflowY: "auto" }}>
        {customerList?.map((list) => (
          <Box key={list._id}>
            <Card
              sx={{
                width: "500px",
                cursor: "pointer",
                userSelect: "none",
                marginTop: 2,
                marginBottom: 3,
                background: {},
                "&:hover": {
                  background: "whitesmoke",
                },
                boxShadow:
                  "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
              }}
            >
              <CardContent>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box onClick={() => handleClick(list)}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ paddingBottom: 3 }}
                    ></Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="h3" sx={{ paddingBottom: 2 }}>
                        <strong>Name</strong> : {list.name} ({list.phone})
                      </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="h3" sx={{ paddingBottom: 2 }}>
                        <strong>Address</strong>: {list.address},
                        {list.township.name},{list.city.name},
                        {list.division.name}
                      </Typography>
                    </Stack>
                  </Box>
                  <Box>
                    <Stack spacing={5}>
                      <EditIcon
                        fontSize="small"
                        sx={{ cursor: "pointer", color: "blue" }}
                        onClick={() => {
                          handleUpdate(list);
                        }}
                      />
                      <DeleteIcon
                        fontSize="small"
                        sx={{ cursor: "pointer", color: "red" }}
                        onClick={() => {
                          handleDelete(list._id);
                        }}
                      />
                    </Stack>
                  </Box>
                </Stack>
              </CardContent>
            </Card>
            <Divider sx={{ margin: "10px 0 10px 0", background: "black" }} />
          </Box>
        ))}

        <AddCustomer
          open={addOpen}
          setOpen={setAddOpen}
          inputValue={customerList}
        ></AddCustomer>
        <UpdateCustomer open={editOpen} setOpen={setEditOpen}></UpdateCustomer>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}> Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ListCustomer;
