import {
  Box,
  Chip,
  Stack,
  TableCell,
  TableRow,
  Typography,
  List,
  ListItem,
  ListItemText,
  Popover,
  Avatar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import MkButton from "../../../app/assets/theme/MkButton";
import { useDispatch } from "react-redux";
import IconWrap from "../../../app/components/IconWrap";
import ProductUpdateDrawer from "./ProductUpdateDrawer";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { formatMoney } from "../../../app/helper/formatMoney";
import { BookDelete } from "../../../features/book/BookApi";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteAlert from "../../../app/components/AlertBox/DeleteAlert";
import { imageApi } from "../../../app/hooks";

function ProductTableBody({ item, companyDetail }) {
  const dispatch = useDispatch();
  // extra
  const { paginationData } = useSelector((state) => state.BookSlice);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);

  const first_three_author = item?.authors.slice(0, 2).map((i) => {
    return (
      <Typography key={i.id} variant="subtitle">
        {i.name}{" "}
      </Typography>
    );
  });

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  //   console.log("companyDetail", companyDetail);
  console.log({ item });
  return (
    <TableRow key={item._id}>
      <TableCell>
        {/* {item?.cover_image} */}
        <Avatar
          sx={{ width: 40, height: 40 }}
          variant="rounded"
          // src={`${item?.cover_image}`}
          src={`${imageApi}${item?.cover_image}`}
          alt={item?.book_title}
        />
      </TableCell>

      <TableCell>
        <Typography variant="caption" sx={{ marginBottom: "3px" }}>
          {item.book_title}
        </Typography>

        <Box sx={{ marginTop: "10px" }}>
          {first_three_author}
          {first_three_author.length >= 2 ? (
            <Typography
              variant="subtitle"
              color="secondary"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              sx={{ cursor: "pointer", marginTop: "10px" }}
            >
              More ...
            </Typography>
          ) : (
            ""
          )}

          <Popover
            id="mouse-over-popover"
            sx={{
              width: "500px",
              pointerEvents: "none",
              "&.MuiPopover-root	": {
                margin: "-4px 0px",
              },
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Box
              sx={{
                position: "relative",
                mt: "10px",
              }}
            />
            {item?.authors?.length > 0 ? (
              <List>
                <ListItem>
                  <ListItemText
                    primary="Authors"
                    primaryTypographyProps={{
                      fontSize: "12px",
                      fontWeight: 900,
                    }}
                  />
                </ListItem>
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    flexWrap: "wrap",
                    width: "400px",
                    padding: "0px 20px",
                  }}
                >
                  {item?.authors.map((item, index) => {
                    return (
                      <i
                        key={index}
                        className="pop-icon"
                        dangerouslySetInnerHTML={{
                          __html: item?.name,
                        }}
                      ></i>
                    );
                  })}
                </Box>
              </List>
            ) : (
              ""
            )}
          </Popover>
        </Box>
      </TableCell>

      {!companyDetail ? (
        <TableCell>
          <Typography variant="caption" align="center">
            {item.publisher.name}
          </Typography>
        </TableCell>
      ) : (
        ""
      )}

      <TableCell>
        <Typography variant="caption">{item.serial ?? "N/A"}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant="caption">{item.edition.name}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant="caption">
          {/* {item.release} */}
          {item.release ? formatMoney(item.release) : 0} ကြိမ်
          {/* {item.release_id ? formatMoney(item.release_id) : 0} ကြိမ် */}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="caption">
          {item.book_release_date
            ? new Date(item.book_release_date)
                .toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
                .replace(/\//g, "-")
            : "N/A"}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="caption">
          {item.sale_price ? formatMoney(item.sale_price) : 0}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="caption">
          {formatMoney(item.units) ?? "N/A"}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="caption" align="right">
          {item.units > 0
            ? formatMoney(Math.round(item.total_expenses / item.units))
            : "N/A"}
        </Typography>
      </TableCell>
      {companyDetail ? (
        <TableCell>
          <Typography variant="caption" align="right">
            {item?.total_expenses ? formatMoney(item?.total_expenses) : 0}
          </Typography>
        </TableCell>
      ) : (
        ""
      )}

      {/* <TableCell>
        <Box
          sx={{
            display: "flex",
            columnGap: "10px",
            alignItems: "center",
            flexWrap: "wrap",
            flexDirection: "row",
          }}
        >
          {item?.authors?.map((i, index) => {
            return (
              <Chip
                key={index}
                label={i?.name}
                variant="outlined"
                sx={{
                  my: 1,
                  color: "#fff",
                  border: "none",
                  backgroundImage: "linear-gradient(310deg, #2152ff, #02c6f3);",
                }}
              />
            );
          })}
        </Box>
      </TableCell> */}

      {/* {companyDetail ? (
        <TableCell sx={{ width: 120 }} align="center">
          <ProductUpdateDrawer inputValues={item} />
        </TableCell>
      ) : (
        ""
      )}

      {companyDetail ? (
        <TableCell sx={{ width: 120 }} align="center">
          <MkButton
            mkcolor={`linear-gradient(310deg, #141727, #3a416f)`}
            size="small"
            textTransform="capitalize"
            onClick={() =>
              dispatch(
                BookDelete({
                  id: item.id,
                  page: paginationData.page,
                  limit: paginationData.limit,
                  publisher_id: companyDetail?.id ?? item.publisher_id,
                })
              )
            }
          >
            Delete
          </MkButton>
        </TableCell>
      ) : (
        ""
      )} */}

      {companyDetail ? (
        <TableCell sx={{ width: 100 }} align="center">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <ProductUpdateDrawer inputValues={item} />
            <DeleteIcon
              fontSize="small"
              onClick={() => setOpenAlert(true)}
              sx={{ cursor: "pointer", color: "red" }}
            />

            <DeleteAlert
              openAlert={openAlert}
              setOpenAlert={setOpenAlert}
              companyDetail={companyDetail}
              item={item}
            />
          </Stack>
        </TableCell>
      ) : (
        ""
      )}
    </TableRow>
  );
}

export default ProductTableBody;
