import { createSlice } from "@reduxjs/toolkit";
import {
  allBook,
  BookAdd,
  BookDelete,
  BookUpdate,
  allBookList,
  getPublisherDropDownList,
  searchSaleBook,
  addSaleBook,
  allInvoiceSaleBook,
} from "./BookApi";

export const BookList = createSlice({
  name: "Book",
  initialState: {
    // books: [],
    books: null,
    // expenses: [],
    // expensesList: [],
    // expensesDetail: [],
    getStatus: null,
    bookStatus: null,
    pagination: {},
    paginationData: {
      page: 1,
      limit: 10,
    },
  },
  reducers: {
    changePaginationData: (state, { payload }) => {
      state.paginationData = payload;
    },
    handleBook: (state, payload) => {
      console.log(payload);
      const { data, ...rest } = payload;
      // console.log("From handle book" + data);
      // console.log("From handle book" + rest);
      state.books = data;
      state.pagination = rest;
    },
    handlerBook: (state) => {
      state.books = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allBook.pending, (state, { payload }) => {
        // console.log("allBook", payload);
        // console.log(payload);
        state.getStatus = "pending";
      })
      .addCase(allBook.fulfilled, (state, { payload }) => {
        // console.log("allBook", payload);
        // console.log(payload);
        state.getStatus = "success";
        BookList.caseReducers.handleBook(state, payload);
      })
      .addCase(BookAdd.pending, (state, { payload }) => {
        // console.log("add Book", payload);
        state.bookStatus = "pending";
        // BookList.caseReducers.handleBook(state, payload);
      })
      .addCase(BookAdd.fulfilled, (state, { payload }) => {
        // console.log("add Book", payload);
        BookList.caseReducers.handleBook(state, payload);
        state.bookStatus = "success";
      })
      .addCase(BookUpdate.pending, (state, { payload }) => {
        // console.log("add Book", payload);
        // BookList.caseReducers.handleBook(state, payload);
        state.bookStatus = "pending";
      })
      .addCase(BookUpdate.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.bookStatus = "success";
        state.books = state.books.map((book) => {
          if (book._id === payload.book._id) {
            return payload.book;
          } else {
            return book;
          }
        });
      })
      .addCase(BookDelete.fulfilled, (state, { payload }) => {
        BookList.caseReducers.handleBook(state, payload);
      });
    // .addCase(
    //     GetBookExpenses.fulfilled,
    //     (state, { payload }) => {
    //         state.expenses = payload.book;
    //     }
    // ).addCase(
    //     GetExpensesList.fulfilled,
    //     (state, { payload }) => {
    //         state.expensesList = payload;
    //     }
    // ).addCase(
    //     GetExpensesDetail.fulfilled,
    //     (state, { payload }) => {
    //         console.log(payload, "get expenses detail");
    //         state.expensesDetail = payload;
    //     }
    // );
  },
});
export const BookSlice = BookList.reducer;
export const { changePaginationData, handlerBook } = BookList.actions;

export const AllBookList = createSlice({
  name: "Book List",
  initialState: {
    book_list: [],
    publisher_list: [],
    saleBookList: [],
    searchStatus: null,
    pagination: {},
    saleBooks: null,
    selectedBooks: [],
    paginationData: {
      page: 1,
      limit: 10,
    },
    allBookStatus: null,
  },
  reducers: {
    changePaginationAllBookData: (state, { payload }) => {
      state.paginationData = payload;
    },
    handleBook: (state, payload) => {
      const { data, ...rest } = payload;
      state.book_list = data;
      state.pagination = rest;
    },
    resetSaleBookList: (state) => {
      state.saleBookList = [];
    },
    addSelectedBook: (state, { payload }) => {
      state.selectedBooks = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allBookList.pending, (state, { payload }) => {
        // console.log("allBookList", payload);
        state.allBookStatus = "pending";
      })
      .addCase(allBookList.fulfilled, (state, { payload }) => {
        // console.log("allBookList", payload);
        state.allBookStatus = "success";
        AllBookList.caseReducers.handleBook(state, payload);
      })
      .addCase(getPublisherDropDownList.fulfilled, (state, { payload }) => {
        console.log("allPublisherListing", payload);
        state.publisher_list = payload.publishers;
      })
      .addCase(searchSaleBook.pending, (state, { payload }) => {
        state.searchStatus = "pending";
      })
      .addCase(searchSaleBook.fulfilled, (state, { payload }) => {
        state.searchStatus = "success";
        console.log(payload);
        state.saleBookList = payload.data;
      })
      .addCase(allInvoiceSaleBook.fulfilled, (state, { payload }) => {
        state.selectedBooks = payload.data ? payload.data.books : [];
        state.saleBooks = payload.data;
      });
  },
});
export const AllBookSlice = AllBookList.reducer;
export const {
  changePaginationAllBookData,
  resetSaleBookList,
  addSelectedBook,
} = AllBookList.actions;
