import { createAsyncThunk } from "@reduxjs/toolkit";
import useFetch, { api, usePostForm } from "../../app/hooks";

export const getPublisherList = createAsyncThunk(
  "getPublisherList/getPublisherList",
  async ({ page, limit }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    const result = useFetch({
      url: `publishers?admin_id=${admin._id}&page=${page + 1}&limit=${limit}`,
      method: "GET",
      token: token,
    });
    return result;
  }
);

export const addPublisher = createAsyncThunk(
  "addPublisher/addPublisher",
  async ({ data }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    const result = usePostForm({
      url: `publishers?admin_id=${admin._id}`,
      method: "POST",
      token: token,
      data: data,
    });
    return result;
  }
);

export const editPublisher = createAsyncThunk(
  "editPublisher/editPublisher",
  async ({ data, id }, { getState }) => {
    for (const pair of data.entries()) {
      console.log(pair);
    }
    const { token, admin } = getState().loginInfo;
    const result = usePostForm({
      url: `publishers/${id}?admin_id=${admin._id}&_method=PUT`,
      method: "POST",
      token: token,
      data: data,
    });
    return result;
  }
);

export const statusPublisher = createAsyncThunk(
  "statusPublisher/statusPublisher",
  async ({ id }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    const result = useFetch({
      url: `publishers/${id}?admin_id=${admin._id}`,
      method: "DELETE",
      token: token,
    });
    return result;
  }
);

export const getPublisherDetail = createAsyncThunk(
  "getPublisherDetail/getPublisherDetail",
  async ({ id }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    const result = useFetch({
      url: `publishers/${id}?admin_id=${admin._id}`,
      method: "GET",
      token: token,
    });
    console.log(result);
    return result;
  }
);

export const addPublisherUser = createAsyncThunk(
  "addPublisherUser/addPublisherUser",
  async ({ data }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    console.log(data);
    const response = await fetch(`${api}/admin/users?admin_id=${admin._id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
        credentials: "include",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    console.log(result);
    return result;
    // const result = usePostForm({
    //   url: `users?admin_id=${admin._id}`,
    //   method: "POST",
    //   token: token,
    //   data: data,
    // });
    // return result;
  }
);

// export const getCompanyUserDetail = createAsyncThunk(
//     'getCompanyUserDetail/getCompanyUserDetail',
//     async ({id}, {getState}) => {
//         const {token} = getState().loginInfo;
//         const result = useFetch({
//             url: `users/${id}`,
//             method: 'GET',
//             token: token,
//         });
//         return result;
//     }
// );

export const editPublisherUserDetail = createAsyncThunk(
  "editPublisherUserDetail/editPublisherUserDetail",
  async ({ id, data }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    const response = await fetch(
      `${api}/admin/users/${id}?admin_id=${admin._id}&_method=PUT`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
          credentials: "include",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    console.log(result);
    return result;
    // const result = usePostForm({
    //   url: `users/${id}?admin_id=${admin._id}&_method=PUT`,
    //   method: "POST",
    //   token: token,
    //   data: data,
    // });
    // return result;
  }
);

export const deletePublisherUser = createAsyncThunk(
  "deletePublisherUser/deletePublisherUser",
  async ({ data }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    const result = usePostForm({
      url: `delete_user?admin_id=${admin._id}`,
      method: "POST",
      token: token,
      data: data,
    });
    return result;
  }
);

export const getPublisherUserByEmail = createAsyncThunk(
  "getPublisherUserByEmail/getPublisherUserByEmail",
  async (data, { getState }) => {
    // console.log(data.publisher_id);
    const { token, admin } = getState().loginInfo;
    const result = useFetch({
      url: `users/getByEmail?admin_id=${admin._id}`,
      method: "POST",
      token: token,
      data: data,
    });
    return result;
  }
);

export const getPublisherUserByPhone = createAsyncThunk(
  "getPublisherUserByPhone/getPublisherUserByPhone",
  async (data, { getState }) => {
    console.log(data.publisher_id);
    const { token, admin } = getState().loginInfo;
    const result = useFetch({
      url: `users/getByPhone?admin_id=${admin._id}`,
      method: "POST",
      token: token,
      data: data,
    });
    return result;
  }
);

export const getPublisherUserRole = createAsyncThunk(
  "getPublisherUserRoles/getPublisherUserRoles",
  async (_, { getState }) => {
    const { token, admin } = getState().loginInfo;
    const result = useFetch({
      url: `roles?admin_id=${admin._id}`,
      method: "GET",
      token: token,
    });
    return result;
  }
);

export const statusPublisherUser = createAsyncThunk(
  "statusPublisherUser/statusPublisherUser",
  async ({ id, company_id, status }, { getState }) => {
    const { token, admin } = getState().loginInfo;
    console.log(status);
    const result = useFetch({
      url: `users/${id}?admin_id=${admin._id}&publisher_id=${company_id}&status=${status}`,
      method: "DELETE",
      token: token,
    });
    return result;
  }
);
