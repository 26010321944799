import {
  Box,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableBody,
  CircularProgress,
  InputLabel,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TableToolbar from "../app/components/Table/TableToolbar";
import MkButton from "../app/assets/theme/MkButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getInvoiceList } from "../features/invoice/InvoiceApi";
import InvoiceTable from "../components/customer/InvoiceTable";
import TableFooterPagination from "../app/components/Table/TableFooterPagination";
import { changePaginationData } from "../features/invoice/InvoiceSlice";
import MkAutoComplete from "../app/assets/theme/MkAutoComplete";
import InputFormComponent from "../app/components/Form/InputFormComponent";
import { getPublisherDetail } from "../features/company/CompanyApi";
import SimpleDateRangePicker from "../app/components/SimpleDateRangePicker";
import format from "date-fns/format";
import {
  addError,
  clearError,
  showAlert,
} from "../app/components/Alert/alertSlice";
const Sales = () => {
  const dispatch = useDispatch();
  const { invoices, pagination, invoiceStatus } = useSelector(
    (state) => state.InvoiceSlice
  );
  const navigate = useNavigate();
  const { publisher_list } = useSelector((state) => state.AllBookSlice);
  const { divisionOptions } = useSelector((state) => state.DivisionListSlice);
  const { usersList } = useSelector((state) => state.companyUser);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [publisher, setPublisher] = useState(null);
  const [division, setDivision] = useState(null);
  const [invoiceCode, setInvoiceCode] = useState("");
  const [staff, setStaff] = useState(null);
  const [showDateRange, setShowDateRange] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const emptyRows =
    page > 0 ? rowsPerPage - invoices?.length : rowsPerPage - invoices?.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleKeyPress = (event) => {
    console.log(event.keyCode);
    // Check if the pressed key is the Enter key (key code 13)
    if (event.keyCode === 112) {
      console.log("Enter key pressed!");
      event.preventDefault();
      navigate("/sales/invoice");
      // addInvoice(event);
      // Do something here
    }
  };
  const handleClose = () => {
    setShowDateRange(false);
  };
  const handleClick = () => {
    setShowDateRange(!showDateRange);
  };

  const handleDateRange = (data) => {
    const { startDate, endDate } = data;
    setStartDate(format(startDate, "yyyy-MM-dd"));
    setEndDate(format(endDate, "yyyy-MM-dd"));
  };
  const showPublisherAlert = () => {
    console.log(publisher);
    if (publisher === null) {
      dispatch(addError("Please Choose Publisher First!"));
      dispatch(showAlert());
    }
  };
  useEffect(() => {
    // Attach the keydown event listener to the document
    document.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  useEffect(() => {
    dispatch(getPublisherDetail({ id: publisher?._id }));
  }, [publisher]);
  useEffect(() => {
    dispatch(
      getInvoiceList({
        page: page + 1,
        limit: rowsPerPage,
        id: "",
        division_id: "",
        invoice_code: "",
        staff: "",
        start: startDate,
        end: endDate,
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      getInvoiceList({
        page: page + 1,
        limit: rowsPerPage,
        id: publisher?._id ?? "",
        division_id: division?._id ?? "",
        invoice_code: invoiceCode,
        staff: staff?._id ?? "",
        start: startDate,
        end: endDate,
      })
    );

    // mark to where page reach
    dispatch(changePaginationData({ page: page + 1, limit: rowsPerPage }));
  }, [
    page,
    rowsPerPage,
    publisher,
    division,
    invoiceCode,
    staff,
    startDate,
    endDate,
  ]);
  useEffect(() => {
    if (invoices?.length === 0) {
      setRowsPerPage(10);
      setPage(0);
    }
  }, [pagination]);
  return (
    <Box component={Paper} sx={{ padding: "0px 25px 25px 25px" }}>
      <TableToolbar>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ flex: "1 1 100%", margin: "30px 0 30px 0" }}
        >
          <Typography variant="h2" sx={{ fontWeight: "bold" }}>
            Invoices List
          </Typography>
          <Link to="invoice">
            <MkButton
              mkcolor={`linear-gradient(310deg, #0adc7f, #02f377)`}
              size="large"
              textTransform="capitalize"
            >
              Create (F1)
            </MkButton>
          </Link>
        </Stack>
      </TableToolbar>
      <Stack
        sx={{ marginBottom: 3 }}
        direction={"row"}
        alignItems={"center"}
        spacing={2}
        justifyContent="flex-start"
      >
        <Box
          sx={{
            position: "relative",
            mt: 0.5,
            width: "20%",
          }}
        >
          <InputLabel htmlFor="date" sx={{ fontSize: "14.5px" }}>
            Select Date
          </InputLabel>
          <Chip
            sx={{
              padding: "20px",
              fontSize: "14px",
            }}
            label={`Start ${startDate} ~ End ${endDate}`}
            onClick={handleClick}
            variant="outlined"
          />
          <Box
            sx={{
              position: "absolute",
              top: 65,
              zIndex: 10000,
            }}
          >
            <SimpleDateRangePicker
              show={showDateRange}
              close={handleClose}
              handleDateRange={handleDateRange}
            />
          </Box>
        </Box>
        <Box sx={{ width: "20%" }}>
          <InputFormComponent
            value={invoiceCode}
            onChange={(e) => setInvoiceCode(e.currentTarget.value)}
            label="Enter Invoice Code"
            name="invoice_code"
            placeholder="Enter Invoice Code"
          />
        </Box>
        <Box width="20%">
          <InputLabel htmlFor="divisions" sx={{ fontSize: "14.5px" }}>
            Select Division
          </InputLabel>
          <MkAutoComplete
            name="divisions"
            noOptionsText="No related Division"
            fullWidth
            placeholder="Division"
            options={divisionOptions}
            getOptionLabel={(option) => option?.name ?? option}
            isOptionEqualToValue={(option, value) => option._id === value.id}
            onChange={(event, newValue) => {
              setDivision(newValue);
            }}
            value={division}
          />
        </Box>
        <Box width="20%">
          <InputLabel htmlFor="select" sx={{ fontSize: "14.5px" }}>
            Select Publisher
          </InputLabel>
          <MkAutoComplete
            label="Publisher List"
            name="publisher"
            placeholder="Choose Publisher"
            noOptionsText="No Publisher"
            fullWidth
            options={publisher_list}
            getOptionLabel={(publisher_list) =>
              publisher_list?.name ?? publisher_list
            }
            isOptionEqualToValue={(publisher_list, value) =>
              publisher_list._id === value._id
            }
            onChange={(event, newValue) => {
              setPublisher(newValue);
              setStaff(null);
            }}
            value={publisher}
          />
        </Box>
        <Box sx={{ width: "20%" }} onClick={showPublisherAlert}>
          <InputLabel htmlFor="staff" sx={{ fontSize: "14.5px" }}>
            Select Staff
          </InputLabel>
          <MkAutoComplete
            name="staffs"
            fullWidth
            placeholder="Staff"
            options={usersList}
            getOptionLabel={(option) => option?.name ?? option}
            isOptionEqualToValue={(option, value) => {
              option._id === value._id;
            }}
            onChange={(event, newValue) => {
              setStaff(newValue);
            }}
            value={staff}
            disabled={publisher ? false : true}
          />
        </Box>
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  Select
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  Invoice Date
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  Publisher
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  Staff
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  Customer
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  Qty
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  Amount
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  Tracking Code
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  Deli Cost
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  Deli Charges
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  Deli Type
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  Discount
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  Deli Date
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  Net Amount
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  Action
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          {invoiceStatus === "pending" ? (
            <TableRow>
              <TableCell colSpan={15} rowSpan={3} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {invoices?.length > 0 ? (
                <>
                  <TableBody>
                    {invoices?.map((row, index) => (
                      <InvoiceTable item={row} key={index} />
                    ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53.3 * emptyRows }}>
                        <TableCell colSpan={5} />
                      </TableRow>
                    )}
                  </TableBody>
                  {invoices?.length > 0 && (
                    <TableFooterPagination
                      rowsPerPageOptions={[10, 20, 30]}
                      tableList={pagination?.total}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  )}
                </>
              ) : (
                <>There is No Invoice</>
              )}
            </>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Sales;
