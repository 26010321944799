import {
  Avatar,
  Box,
  listClasses,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import MkChip from "../../app/assets/theme/MkChip";
import { Link } from "react-router-dom";
import EditAdmin from "./EditAdmin";
import { useDispatch, useSelector } from "react-redux";
import { statusAdmin } from "../../features/admin/AdminApi";
import { imageApi } from "../../app/hooks";
import { getAdminList } from "../../features/admin/AdminApi";
import { ConstructionOutlined } from "@mui/icons-material";
export default function AdminLists({ item }) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(statusAdmin({ id: item.id }))
      .unwrap()
      .then((result) => {
        if (result.status) {
          dispatch(getAdminList());
        }
      });
  };

  const { adminList } = useSelector((state) => state.adminList);
  const user = useSelector((state) => state.loginInfo);
  const current_admin = adminList.find((admin) => admin.id === user.admin._id);
  // console.log(current_admin);
  console.log(item);
  return (
    <TableRow>
      <TableCell>
        <Avatar
          sx={{
            width: 40,
            height: 40,
            cursor: "pointer",
          }}
          variant="rounded"
          alt={item.name}
          src={`${imageApi}${item.profile}`}
        />
      </TableCell>
      <TableCell align="right">
        {/* <Link
          to={`/admins/${item.name?.toLowerCase().split(" ").join("")}-diuu-${
            item.id
          }`}
        > */}
        <Typography
          variant="caption"
          component="div"
          sx={{
            textAlign: "left",
          }}
        >
          {item.name}
        </Typography>
        {/* </Link> */}
      </TableCell>
      <TableCell>{item.email}</TableCell>
      <TableCell>
        {item.last_login !== null ? item.last_login : "N/A"}
      </TableCell>

      <TableCell align="center">
        <EditAdmin item={item} current_admin={current_admin} />
      </TableCell>
      <TableCell align="center">
        <div onClick={handleClick} sx={{ cursor: "pointer" }}>
          {current_admin.id === item.id ? (
            "N/A"
          ) : (
            <MkChip active={item.active} />
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}
