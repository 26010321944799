export const icons = [
    { name: "fa-500px", type: "fab" },
    { name: "fa-accessible-icon", type: "fab" },
    { name: "fa-accusoft", type: "fab" },
    { name: "fa-ad", type: "fas" },
    { name: "fa-address-book", type: "far" },
    { name: "fa-address-book", type: "fas" },
    { name: "fa-address-card", type: "far" },
    { name: "fa-address-card", type: "fas" },
    { name: "fa-adjust", type: "fas" },
    { name: "fa-adn", type: "fab" },
    { name: "fa-adversal", type: "fab" },
    { name: "fa-affiliatetheme", type: "fab" },
    { name: "fa-air-freshener", type: "fas" },
    { name: "fa-airbnb", type: "fab" },
    { name: "fa-algolia", type: "fab" },
    { name: "fa-align-center", type: "fas" },
    { name: "fa-align-justify", type: "fas" },
    { name: "fa-align-left", type: "fas" },
    { name: "fa-align-right", type: "fas" },
    { name: "fa-alipay", type: "fab" },
    { name: "fa-allergies", type: "fas" },
    { name: "fa-amazon", type: "fab" },
    { name: "fa-amazon-pay", type: "fab" },
    { name: "fa-ambulance", type: "fas" },
    { name: "fa-american-sign-language-interpreting", type: "fas" },
    { name: "fa-amilia", type: "fab" },
    { name: "fa-anchor", type: "fas" },
    { name: "fa-android", type: "fab" },
    { name: "fa-angellist", type: "fab" },
    { name: "fa-angle-double-down", type: "fas" },
    { name: "fa-angle-double-left", type: "fas" },
    { name: "fa-angle-double-right", type: "fas" },
    { name: "fa-angle-double-up", type: "fas" },
    { name: "fa-angle-down", type: "fas" },
    { name: "fa-angle-left", type: "fas" },
    { name: "fa-angle-right", type: "fas" },
    { name: "fa-angle-up", type: "fas" },
    { name: "fa-angry", type: "far" },
    { name: "fa-angry", type: "fas" },
    { name: "fa-angrycreative", type: "fab" },
    { name: "fa-angular", type: "fab" },
    { name: "fa-ankh", type: "fas" },
    { name: "fa-app-store", type: "fab" },
    { name: "fa-app-store-ios", type: "fab" },
    { name: "fa-apper", type: "fab" },
    { name: "fa-apple", type: "fab" },
    { name: "fa-apple-alt", type: "fas" },
    { name: "fa-apple-pay", type: "fab" },
    { name: "fa-archive", type: "fas" },
    { name: "fa-archway", type: "fas" },
    { name: "fa-arrow-alt-circle-down", type: "far" },
    { name: "fa-arrow-alt-circle-down", type: "fas" },
    { name: "fa-arrow-alt-circle-left", type: "far" },
    { name: "fa-arrow-alt-circle-left", type: "fas" },
    { name: "fa-arrow-alt-circle-right", type: "far" },
    { name: "fa-arrow-alt-circle-right", type: "fas" },
    { name: "fa-arrow-alt-circle-up", type: "far" },
    { name: "fa-arrow-alt-circle-up", type: "fas" },
    { name: "fa-arrow-circle-down", type: "fas" },
    { name: "fa-arrow-circle-left", type: "fas" },
    { name: "fa-arrow-circle-right", type: "fas" },
    { name: "fa-arrow-circle-up", type: "fas" },
    { name: "fa-arrow-down", type: "fas" },
    { name: "fa-arrow-left", type: "fas" },
    { name: "fa-arrow-right", type: "fas" },
    { name: "fa-arrow-up", type: "fas" },
    { name: "fa-arrows-alt", type: "fas" },
    { name: "fa-arrows-alt-h", type: "fas" },
    { name: "fa-arrows-alt-v", type: "fas" },
    { name: "fa-artstation", type: "fab" },
    { name: "fa-assistive-listening-systems", type: "fas" },
    { name: "fa-asterisk", type: "fas" },
    { name: "fa-asymmetrik", type: "fab" },
    { name: "fa-at", type: "fas" },
    { name: "fa-atlas", type: "fas" },
    { name: "fa-atlassian", type: "fab" },
    { name: "fa-atom", type: "fas" },
    { name: "fa-audible", type: "fab" },
    { name: "fa-audio-description", type: "fas" },
    { name: "fa-autoprefixer", type: "fab" },
    { name: "fa-avianex", type: "fab" },
    { name: "fa-aviato", type: "fab" },
    { name: "fa-award", type: "fas" },
    { name: "fa-aws", type: "fab" },
    { name: "fa-baby", type: "fas" },
    { name: "fa-baby-carriage", type: "fas" },
    { name: "fa-backspace", type: "fas" },
    { name: "fa-backward", type: "fas" },
    { name: "fa-bacon", type: "fas" },
    { name: "fa-balance-scale", type: "fas" },
    { name: "fa-ban", type: "fas" },
    { name: "fa-band-aid", type: "fas" },
    { name: "fa-bandcamp", type: "fab" },
    { name: "fa-barcode", type: "fas" },
    { name: "fa-bars", type: "fas" },
    { name: "fa-baseball-ball", type: "fas" },
    { name: "fa-basketball-ball", type: "fas" },
    { name: "fa-bath", type: "fas" },
    { name: "fa-battery-empty", type: "fas" },
    { name: "fa-battery-full", type: "fas" },
    { name: "fa-battery-half", type: "fas" },
    { name: "fa-battery-quarter", type: "fas" },
    { name: "fa-battery-three-quarters", type: "fas" },
    { name: "fa-battle-net", type: "fab" },
    { name: "fa-bed", type: "fas" },
    { name: "fa-beer", type: "fas" },
    { name: "fa-behance", type: "fab" },
    { name: "fa-behance-square", type: "fab" },
    { name: "fa-bell", type: "far" },
    { name: "fa-bell", type: "fas" },
    { name: "fa-bell-slash", type: "far" },
    { name: "fa-bell-slash", type: "fas" },
    { name: "fa-bezier-curve", type: "fas" },
    { name: "fa-bible", type: "fas" },
    { name: "fa-bicycle", type: "fas" },
    { name: "fa-bimobject", type: "fab" },
    { name: "fa-binoculars", type: "fas" },
    { name: "fa-biohazard", type: "fas" },
    { name: "fa-birthday-cake", type: "fas" },
    { name: "fa-bitbucket", type: "fab" },
    { name: "fa-bitcoin", type: "fab" },
    { name: "fa-bity", type: "fab" },
    { name: "fa-black-tie", type: "fab" },
    { name: "fa-blackberry", type: "fab" },
    { name: "fa-blender", type: "fas" },
    { name: "fa-blender-phone", type: "fas" },
    { name: "fa-blind", type: "fas" },
    { name: "fa-blog", type: "fas" },
    { name: "fa-blogger", type: "fab" },
    { name: "fa-blogger-b", type: "fab" },
    { name: "fa-bluetooth", type: "fab" },
    { name: "fa-bluetooth-b", type: "fab" },
    { name: "fa-bold", type: "fas" },
    { name: "fa-bolt", type: "fas" },
    { name: "fa-bomb", type: "fas" },
    { name: "fa-bone", type: "fas" },
    { name: "fa-bong", type: "fas" },
    { name: "fa-book", type: "fas" },
    { name: "fa-book-dead", type: "fas" },
    { name: "fa-book-medical", type: "fas" },
    { name: "fa-book-open", type: "fas" },
    { name: "fa-book-reader", type: "fas" },
    { name: "fa-bookmark", type: "far" },
    { name: "fa-bookmark", type: "fas" },
    { name: "fa-bootstrap", type: "fab" },
    { name: "fa-bowling-ball", type: "fas" },
    { name: "fa-box", type: "fas" },
    { name: "fa-box-open", type: "fas" },
    { name: "fa-boxes", type: "fas" },
    { name: "fa-braille", type: "fas" },
    { name: "fa-brain", type: "fas" },
    { name: "fa-bread-slice", type: "fas" },
    { name: "fa-briefcase", type: "fas" },
    { name: "fa-briefcase-medical", type: "fas" },
    { name: "fa-broadcast-tower", type: "fas" },
    { name: "fa-broom", type: "fas" },
    { name: "fa-brush", type: "fas" },
    { name: "fa-btc", type: "fab" },
    { name: "fa-buffer", type: "fab" },
    { name: "fa-bug", type: "fas" },
    { name: "fa-building", type: "far" },
    { name: "fa-building", type: "fas" },
    { name: "fa-bullhorn", type: "fas" },
    { name: "fa-bullseye", type: "fas" },
    { name: "fa-burn", type: "fas" },
    { name: "fa-buromobelexperte", type: "fab" },
    { name: "fa-bus", type: "fas" },
    { name: "fa-bus-alt", type: "fas" },
    { name: "fa-business-time", type: "fas" },
    { name: "fa-buysellads", type: "fab" },
    { name: "fa-calculator", type: "fas" },
    { name: "fa-calendar", type: "far" },
    { name: "fa-calendar", type: "fas" },
    { name: "fa-calendar-alt", type: "far" },
    { name: "fa-calendar-alt", type: "fas" },
    { name: "fa-calendar-check", type: "far" },
    { name: "fa-calendar-check", type: "fas" },
    { name: "fa-calendar-day", type: "fas" },
    { name: "fa-calendar-minus", type: "far" },
    { name: "fa-calendar-minus", type: "fas" },
    { name: "fa-calendar-plus", type: "far" },
    { name: "fa-calendar-plus", type: "fas" },
    { name: "fa-calendar-times", type: "far" },
    { name: "fa-calendar-times", type: "fas" },
    { name: "fa-calendar-week", type: "fas" },
    { name: "fa-camera", type: "fas" },
    { name: "fa-camera-retro", type: "fas" },
    { name: "fa-campground", type: "fas" },
    { name: "fa-canadian-maple-leaf", type: "fab" },
    { name: "fa-candy-cane", type: "fas" },
    { name: "fa-cannabis", type: "fas" },
    { name: "fa-capsules", type: "fas" },
    { name: "fa-car", type: "fas" },
    { name: "fa-car-alt", type: "fas" },
    { name: "fa-car-battery", type: "fas" },
    { name: "fa-car-crash", type: "fas" },
    { name: "fa-car-side", type: "fas" },
    { name: "fa-caret-down", type: "fas" },
    { name: "fa-caret-left", type: "fas" },
    { name: "fa-caret-right", type: "fas" },
    { name: "fa-caret-square-down", type: "far" },
    { name: "fa-caret-square-down", type: "fas" },
    { name: "fa-caret-square-left", type: "far" },
    { name: "fa-caret-square-left", type: "fas" },
    { name: "fa-caret-square-right", type: "far" },
    { name: "fa-caret-square-right", type: "fas" },
    { name: "fa-caret-square-up", type: "far" },
    { name: "fa-caret-square-up", type: "fas" },
    { name: "fa-caret-up", type: "fas" },
    { name: "fa-carrot", type: "fas" },
    { name: "fa-cart-arrow-down", type: "fas" },
    { name: "fa-cart-plus", type: "fas" },
    { name: "fa-cash-register", type: "fas" },
    { name: "fa-cat", type: "fas" },
    { name: "fa-cc-amazon-pay", type: "fab" },
    { name: "fa-cc-amex", type: "fab" },
    { name: "fa-cc-apple-pay", type: "fab" },
    { name: "fa-cc-diners-club", type: "fab" },
    { name: "fa-cc-discover", type: "fab" },
    { name: "fa-cc-jcb", type: "fab" },
    { name: "fa-cc-mastercard", type: "fab" },
    { name: "fa-cc-paypal", type: "fab" },
    { name: "fa-cc-stripe", type: "fab" },
    { name: "fa-cc-visa", type: "fab" },
    { name: "fa-centercode", type: "fab" },
    { name: "fa-centos", type: "fab" },
    { name: "fa-certificate", type: "fas" },
    { name: "fa-chair", type: "fas" },
    { name: "fa-chalkboard", type: "fas" },
    { name: "fa-chalkboard-teacher", type: "fas" },
    { name: "fa-charging-station", type: "fas" },
    { name: "fa-chart-area", type: "fas" },
    { name: "fa-chart-bar", type: "far" },
    { name: "fa-chart-bar", type: "fas" },
    { name: "fa-chart-line", type: "fas" },
    { name: "fa-chart-pie", type: "fas" },
    { name: "fa-check", type: "fas" },
    { name: "fa-check-circle", type: "far" },
    { name: "fa-check-circle", type: "fas" },
    { name: "fa-check-double", type: "fas" },
    { name: "fa-check-square", type: "far" },
    { name: "fa-check-square", type: "fas" },
    { name: "fa-cheese", type: "fas" },
    { name: "fa-chess", type: "fas" },
    { name: "fa-chess-bishop", type: "fas" },
    { name: "fa-chess-board", type: "fas" },
    { name: "fa-chess-king", type: "fas" },
    { name: "fa-chess-knight", type: "fas" },
    { name: "fa-chess-pawn", type: "fas" },
    { name: "fa-chess-queen", type: "fas" },
    { name: "fa-chess-rook", type: "fas" },
    { name: "fa-chevron-circle-down", type: "fas" },
    { name: "fa-chevron-circle-left", type: "fas" },
    { name: "fa-chevron-circle-right", type: "fas" },
    { name: "fa-chevron-circle-up", type: "fas" },
    { name: "fa-chevron-down", type: "fas" },
    { name: "fa-chevron-left", type: "fas" },
    { name: "fa-chevron-right", type: "fas" },
    { name: "fa-chevron-up", type: "fas" },
    { name: "fa-child", type: "fas" },
    { name: "fa-chrome", type: "fab" },
    { name: "fa-chromecast", type: "fab" },
    { name: "fa-church", type: "fas" },
    { name: "fa-circle", type: "far" },
    { name: "fa-circle", type: "fas" },
    { name: "fa-circle-notch", type: "fas" },
    { name: "fa-city", type: "fas" },
    { name: "fa-clinic-medical", type: "fas" },
    { name: "fa-clipboard", type: "far" },
    { name: "fa-clipboard", type: "fas" },
    { name: "fa-clipboard-check", type: "fas" },
    { name: "fa-clipboard-list", type: "fas" },
    { name: "fa-clock", type: "far" },
    { name: "fa-clock", type: "fas" },
    { name: "fa-clone", type: "far" },
    { name: "fa-clone", type: "fas" },
    { name: "fa-closed-captioning", type: "far" },
    { name: "fa-closed-captioning", type: "fas" },
    { name: "fa-cloud", type: "fas" },
    { name: "fa-cloud-download-alt", type: "fas" },
    { name: "fa-cloud-meatball", type: "fas" },
    { name: "fa-cloud-moon", type: "fas" },
    { name: "fa-cloud-moon-rain", type: "fas" },
    { name: "fa-cloud-rain", type: "fas" },
    { name: "fa-cloud-showers-heavy", type: "fas" },
    { name: "fa-cloud-sun", type: "fas" },
    { name: "fa-cloud-sun-rain", type: "fas" },
    { name: "fa-cloud-upload-alt", type: "fas" },
    { name: "fa-cloudscale", type: "fab" },
    { name: "fa-cloudsmith", type: "fab" },
    { name: "fa-cloudversify", type: "fab" },
    { name: "fa-cocktail", type: "fas" },
    { name: "fa-code", type: "fas" },
    { name: "fa-code-branch", type: "fas" },
    { name: "fa-codepen", type: "fab" },
    { name: "fa-codiepie", type: "fab" },
    { name: "fa-coffee", type: "fas" },
    { name: "fa-cog", type: "fas" },
    { name: "fa-cogs", type: "fas" },
    { name: "fa-coins", type: "fas" },
    { name: "fa-columns", type: "fas" },
    { name: "fa-comment", type: "far" },
    { name: "fa-comment", type: "fas" },
    { name: "fa-comment-alt", type: "far" },
    { name: "fa-comment-alt", type: "fas" },
    { name: "fa-comment-dollar", type: "fas" },
    { name: "fa-comment-dots", type: "far" },
    { name: "fa-comment-dots", type: "fas" },
    { name: "fa-comment-medical", type: "fas" },
    { name: "fa-comment-slash", type: "fas" },
    { name: "fa-comments", type: "far" },
    { name: "fa-comments", type: "fas" },
    { name: "fa-comments-dollar", type: "fas" },
    { name: "fa-compact-disc", type: "fas" },
    { name: "fa-compass", type: "far" },
    { name: "fa-compass", type: "fas" },
    { name: "fa-compress", type: "fas" },
    { name: "fa-compress-arrows-alt", type: "fas" },
    { name: "fa-concierge-bell", type: "fas" },
    { name: "fa-confluence", type: "fab" },
    { name: "fa-connectdevelop", type: "fab" },
    { name: "fa-contao", type: "fab" },
    { name: "fa-cookie", type: "fas" },
    { name: "fa-cookie-bite", type: "fas" },
    { name: "fa-copy", type: "far" },
    { name: "fa-copy", type: "fas" },
    { name: "fa-copyright", type: "far" },
    { name: "fa-copyright", type: "fas" },
    { name: "fa-couch", type: "fas" },
    { name: "fa-cpanel", type: "fab" },
    { name: "fa-creative-commons", type: "fab" },
    { name: "fa-creative-commons-by", type: "fab" },
    { name: "fa-creative-commons-nc", type: "fab" },
    { name: "fa-creative-commons-nc-eu", type: "fab" },
    { name: "fa-creative-commons-nc-jp", type: "fab" },
    { name: "fa-creative-commons-nd", type: "fab" },
    { name: "fa-creative-commons-pd", type: "fab" },
    { name: "fa-creative-commons-pd-alt", type: "fab" },
    { name: "fa-creative-commons-remix", type: "fab" },
    { name: "fa-creative-commons-sa", type: "fab" },
    { name: "fa-creative-commons-sampling", type: "fab" },
    { name: "fa-creative-commons-sampling-plus", type: "fab" },
    { name: "fa-creative-commons-share", type: "fab" },
    { name: "fa-creative-commons-zero", type: "fab" },
    { name: "fa-credit-card", type: "far" },
    { name: "fa-credit-card", type: "fas" },
    { name: "fa-critical-role", type: "fab" },
    { name: "fa-crop", type: "fas" },
    { name: "fa-crop-alt", type: "fas" },
    { name: "fa-cross", type: "fas" },
    { name: "fa-crosshairs", type: "fas" },
    { name: "fa-crow", type: "fas" },
    { name: "fa-crown", type: "fas" },
    { name: "fa-crutch", type: "fas" },
    { name: "fa-css3", type: "fab" },
    { name: "fa-css3-alt", type: "fab" },
    { name: "fa-cube", type: "fas" },
    { name: "fa-cubes", type: "fas" },
    { name: "fa-cut", type: "fas" },
    { name: "fa-cuttlefish", type: "fab" },
    { name: "fa-d-and-d", type: "fab" },
    { name: "fa-d-and-d-beyond", type: "fab" },
    { name: "fa-dashcube", type: "fab" },
    { name: "fa-database", type: "fas" },
    { name: "fa-deaf", type: "fas" },
    { name: "fa-delicious", type: "fab" },
    { name: "fa-democrat", type: "fas" },
    { name: "fa-deploydog", type: "fab" },
    { name: "fa-deskpro", type: "fab" },
    { name: "fa-desktop", type: "fas" },
    { name: "fa-dev", type: "fab" },
    { name: "fa-deviantart", type: "fab" },
    { name: "fa-dharmachakra", type: "fas" },
    { name: "fa-dhl", type: "fab" },
    { name: "fa-diagnoses", type: "fas" },
    { name: "fa-diaspora", type: "fab" },
    { name: "fa-dice", type: "fas" },
    { name: "fa-dice-d20", type: "fas" },
    { name: "fa-dice-d6", type: "fas" },
    { name: "fa-dice-five", type: "fas" },
    { name: "fa-dice-four", type: "fas" },
    { name: "fa-dice-one", type: "fas" },
    { name: "fa-dice-six", type: "fas" },
    { name: "fa-dice-three", type: "fas" },
    { name: "fa-dice-two", type: "fas" },
    { name: "fa-digg", type: "fab" },
    { name: "fa-digital-ocean", type: "fab" },
    { name: "fa-digital-tachograph", type: "fas" },
    { name: "fa-directions", type: "fas" },
    { name: "fa-discord", type: "fab" },
    { name: "fa-discourse", type: "fab" },
    { name: "fa-divide", type: "fas" },
    { name: "fa-dizzy", type: "far" },
    { name: "fa-dizzy", type: "fas" },
    { name: "fa-dna", type: "fas" },
    { name: "fa-dochub", type: "fab" },
    { name: "fa-docker", type: "fab" },
    { name: "fa-dog", type: "fas" },
    { name: "fa-dollar-sign", type: "fas" },
    { name: "fa-dolly", type: "fas" },
    { name: "fa-dolly-flatbed", type: "fas" },
    { name: "fa-donate", type: "fas" },
    { name: "fa-door-closed", type: "fas" },
    { name: "fa-door-open", type: "fas" },
    { name: "fa-dot-circle", type: "far" },
    { name: "fa-dot-circle", type: "fas" },
    { name: "fa-dove", type: "fas" },
    { name: "fa-download", type: "fas" },
    { name: "fa-draft2digital", type: "fab" },
    { name: "fa-drafting-compass", type: "fas" },
    { name: "fa-dragon", type: "fas" },
    { name: "fa-draw-polygon", type: "fas" },
    { name: "fa-dribbble", type: "fab" },
    { name: "fa-dribbble-square", type: "fab" },
    { name: "fa-dropbox", type: "fab" },
    { name: "fa-drum", type: "fas" },
    { name: "fa-drum-steelpan", type: "fas" },
    { name: "fa-drumstick-bite", type: "fas" },
    { name: "fa-drupal", type: "fab" },
    { name: "fa-dumbbell", type: "fas" },
    { name: "fa-dumpster", type: "fas" },
    { name: "fa-dumpster-fire", type: "fas" },
    { name: "fa-dungeon", type: "fas" },
    { name: "fa-dyalog", type: "fab" },
    { name: "fa-earlybirds", type: "fab" },
    { name: "fa-ebay", type: "fab" },
    { name: "fa-edge", type: "fab" },
    { name: "fa-edit", type: "far" },
    { name: "fa-edit", type: "fas" },
    { name: "fa-egg", type: "fas" },
    { name: "fa-eject", type: "fas" },
    { name: "fa-elementor", type: "fab" },
    { name: "fa-ellipsis-h", type: "fas" },
    { name: "fa-ellipsis-v", type: "fas" },
    { name: "fa-ello", type: "fab" },
    { name: "fa-ember", type: "fab" },
    { name: "fa-empire", type: "fab" },
    { name: "fa-envelope", type: "far" },
    { name: "fa-envelope", type: "fas" },
    { name: "fa-envelope-open", type: "far" },
    { name: "fa-envelope-open", type: "fas" },
    { name: "fa-envelope-open-text", type: "fas" },
    { name: "fa-envelope-square", type: "fas" },
    { name: "fa-envira", type: "fab" },
    { name: "fa-equals", type: "fas" },
    { name: "fa-eraser", type: "fas" },
    { name: "fa-erlang", type: "fab" },
    { name: "fa-ethereum", type: "fab" },
    { name: "fa-ethernet", type: "fas" },
    { name: "fa-etsy", type: "fab" },
    { name: "fa-euro-sign", type: "fas" },
    { name: "fa-evernote", type: "fab" },
    { name: "fa-exchange-alt", type: "fas" },
    { name: "fa-exclamation", type: "fas" },
    { name: "fa-exclamation-circle", type: "fas" },
    { name: "fa-exclamation-triangle", type: "fas" },
    { name: "fa-expand", type: "fas" },
    { name: "fa-expand-arrows-alt", type: "fas" },
    { name: "fa-expeditedssl", type: "fab" },
    { name: "fa-external-link-alt", type: "fas" },
    { name: "fa-external-link-square-alt", type: "fas" },
    { name: "fa-eye", type: "far" },
    { name: "fa-eye", type: "fas" },
    { name: "fa-eye-dropper", type: "fas" },
    { name: "fa-eye-slash", type: "far" },
    { name: "fa-eye-slash", type: "fas" },
    { name: "fa-facebook", type: "fab" },
    { name: "fa-facebook-f", type: "fab" },
    { name: "fa-facebook-messenger", type: "fab" },
    { name: "fa-facebook-square", type: "fab" },
    { name: "fa-fantasy-flight-games", type: "fab" },
    { name: "fa-fast-backward", type: "fas" },
    { name: "fa-fast-forward", type: "fas" },
    { name: "fa-fax", type: "fas" },
    { name: "fa-feather", type: "fas" },
    { name: "fa-feather-alt", type: "fas" },
    { name: "fa-fedex", type: "fab" },
    { name: "fa-fedora", type: "fab" },
    { name: "fa-female", type: "fas" },
    { name: "fa-fighter-jet", type: "fas" },
    { name: "fa-figma", type: "fab" },
    { name: "fa-file", type: "far" },
    { name: "fa-file", type: "fas" },
    { name: "fa-file-alt", type: "far" },
    { name: "fa-file-alt", type: "fas" },
    { name: "fa-file-archive", type: "far" },
    { name: "fa-file-archive", type: "fas" },
    { name: "fa-file-audio", type: "far" },
    { name: "fa-file-audio", type: "fas" },
    { name: "fa-file-code", type: "far" },
    { name: "fa-file-code", type: "fas" },
    { name: "fa-file-contract", type: "fas" },
    { name: "fa-file-csv", type: "fas" },
    { name: "fa-file-download", type: "fas" },
    { name: "fa-file-excel", type: "far" },
    { name: "fa-file-excel", type: "fas" },
    { name: "fa-file-export", type: "fas" },
    { name: "fa-file-image", type: "far" },
    { name: "fa-file-image", type: "fas" },
    { name: "fa-file-import", type: "fas" },
    { name: "fa-file-invoice", type: "fas" },
    { name: "fa-file-invoice-dollar", type: "fas" },
    { name: "fa-file-medical", type: "fas" },
    { name: "fa-file-medical-alt", type: "fas" },
    { name: "fa-file-pdf", type: "far" },
    { name: "fa-file-pdf", type: "fas" },
    { name: "fa-file-powerpoint", type: "far" },
    { name: "fa-file-powerpoint", type: "fas" },
    { name: "fa-file-prescription", type: "fas" },
    { name: "fa-file-signature", type: "fas" },
    { name: "fa-file-upload", type: "fas" },
    { name: "fa-file-video", type: "far" },
    { name: "fa-file-video", type: "fas" },
    { name: "fa-file-word", type: "far" },
    { name: "fa-file-word", type: "fas" },
    { name: "fa-fill", type: "fas" },
    { name: "fa-fill-drip", type: "fas" },
    { name: "fa-film", type: "fas" },
    { name: "fa-filter", type: "fas" },
    { name: "fa-fingerprint", type: "fas" },
    { name: "fa-fire", type: "fas" },
    { name: "fa-fire-alt", type: "fas" },
    { name: "fa-fire-extinguisher", type: "fas" },
    { name: "fa-firefox", type: "fab" },
    { name: "fa-first-aid", type: "fas" },
    { name: "fa-first-order", type: "fab" },
    { name: "fa-first-order-alt", type: "fab" },
    { name: "fa-firstdraft", type: "fab" },
    { name: "fa-fish", type: "fas" },
    { name: "fa-fist-raised", type: "fas" },
    { name: "fa-flag", type: "far" },
    { name: "fa-flag", type: "fas" },
    { name: "fa-flag-checkered", type: "fas" },
    { name: "fa-flag-usa", type: "fas" },
    { name: "fa-flask", type: "fas" },
    { name: "fa-flickr", type: "fab" },
    { name: "fa-flipboard", type: "fab" },
    { name: "fa-flushed", type: "far" },
    { name: "fa-flushed", type: "fas" },
    { name: "fa-fly", type: "fab" },
    { name: "fa-folder", type: "far" },
    { name: "fa-folder", type: "fas" },
    { name: "fa-folder-minus", type: "fas" },
    { name: "fa-folder-open", type: "far" },
    { name: "fa-folder-open", type: "fas" },
    { name: "fa-folder-plus", type: "fas" },
    { name: "fa-font", type: "fas" },
    { name: "fa-font-awesome", type: "fab" },
    { name: "fa-font-awesome-alt", type: "fab" },
    { name: "fa-font-awesome-flag", type: "fab" },
    { name: "fa-font-awesome-logo-full", type: "far" },
    { name: "fa-font-awesome-logo-full", type: "fas" },
    { name: "fa-font-awesome-logo-full", type: "fab" },
    { name: "fa-fonticons", type: "fab" },
    { name: "fa-fonticons-fi", type: "fab" },
    { name: "fa-football-ball", type: "fas" },
    { name: "fa-fort-awesome", type: "fab" },
    { name: "fa-fort-awesome-alt", type: "fab" },
    { name: "fa-forumbee", type: "fab" },
    { name: "fa-forward", type: "fas" },
    { name: "fa-foursquare", type: "fab" },
    { name: "fa-free-code-camp", type: "fab" },
    { name: "fa-freebsd", type: "fab" },
    { name: "fa-frog", type: "fas" },
    { name: "fa-frown", type: "far" },
    { name: "fa-frown", type: "fas" },
    { name: "fa-frown-open", type: "far" },
    { name: "fa-frown-open", type: "fas" },
    { name: "fa-fulcrum", type: "fab" },
    { name: "fa-funnel-dollar", type: "fas" },
    { name: "fa-futbol", type: "far" },
    { name: "fa-futbol", type: "fas" },
    { name: "fa-galactic-republic", type: "fab" },
    { name: "fa-galactic-senate", type: "fab" },
    { name: "fa-gamepad", type: "fas" },
    { name: "fa-gas-pump", type: "fas" },
    { name: "fa-gavel", type: "fas" },
    { name: "fa-gem", type: "far" },
    { name: "fa-gem", type: "fas" },
    { name: "fa-genderless", type: "fas" },
    { name: "fa-get-pocket", type: "fab" },
    { name: "fa-gg", type: "fab" },
    { name: "fa-gg-circle", type: "fab" },
    { name: "fa-ghost", type: "fas" },
    { name: "fa-gift", type: "fas" },
    { name: "fa-gifts", type: "fas" },
    { name: "fa-git", type: "fab" },
    { name: "fa-git-alt", type: "fab" },
    { name: "fa-git-square", type: "fab" },
    { name: "fa-github", type: "fab" },
    { name: "fa-github-alt", type: "fab" },
    { name: "fa-github-square", type: "fab" },
    { name: "fa-gitkraken", type: "fab" },
    { name: "fa-gitlab", type: "fab" },
    { name: "fa-gitter", type: "fab" },
    { name: "fa-glass-cheers", type: "fas" },
    { name: "fa-glass-martini", type: "fas" },
    { name: "fa-glass-martini-alt", type: "fas" },
    { name: "fa-glass-whiskey", type: "fas" },
    { name: "fa-glasses", type: "fas" },
    { name: "fa-glide", type: "fab" },
    { name: "fa-glide-g", type: "fab" },
    { name: "fa-globe", type: "fas" },
    { name: "fa-globe-africa", type: "fas" },
    { name: "fa-globe-americas", type: "fas" },
    { name: "fa-globe-asia", type: "fas" },
    { name: "fa-globe-europe", type: "fas" },
    { name: "fa-gofore", type: "fab" },
    { name: "fa-golf-ball", type: "fas" },
    { name: "fa-goodreads", type: "fab" },
    { name: "fa-goodreads-g", type: "fab" },
    { name: "fa-google", type: "fab" },
    { name: "fa-google-drive", type: "fab" },
    { name: "fa-google-play", type: "fab" },
    { name: "fa-google-plus", type: "fab" },
    { name: "fa-google-plus-g", type: "fab" },
    { name: "fa-google-plus-square", type: "fab" },
    { name: "fa-google-wallet", type: "fab" },
    { name: "fa-gopuram", type: "fas" },
    { name: "fa-graduation-cap", type: "fas" },
    { name: "fa-gratipay", type: "fab" },
    { name: "fa-grav", type: "fab" },
    { name: "fa-greater-than", type: "fas" },
    { name: "fa-greater-than-equal", type: "fas" },
    { name: "fa-grimace", type: "far" },
    { name: "fa-grimace", type: "fas" },
    { name: "fa-grin", type: "far" },
    { name: "fa-grin", type: "fas" },
    { name: "fa-grin-alt", type: "far" },
    { name: "fa-grin-alt", type: "fas" },
    { name: "fa-grin-beam", type: "far" },
    { name: "fa-grin-beam", type: "fas" },
    { name: "fa-grin-beam-sweat", type: "far" },
    { name: "fa-grin-beam-sweat", type: "fas" },
    { name: "fa-grin-hearts", type: "far" },
    { name: "fa-grin-hearts", type: "fas" },
    { name: "fa-grin-squint", type: "far" },
    { name: "fa-grin-squint", type: "fas" },
    { name: "fa-grin-squint-tears", type: "far" },
    { name: "fa-grin-squint-tears", type: "fas" },
    { name: "fa-grin-stars", type: "far" },
    { name: "fa-grin-stars", type: "fas" },
    { name: "fa-grin-tears", type: "far" },
    { name: "fa-grin-tears", type: "fas" },
    { name: "fa-grin-tongue", type: "far" },
    { name: "fa-grin-tongue", type: "fas" },
    { name: "fa-grin-tongue-squint", type: "far" },
    { name: "fa-grin-tongue-squint", type: "fas" },
    { name: "fa-grin-tongue-wink", type: "far" },
    { name: "fa-grin-tongue-wink", type: "fas" },
    { name: "fa-grin-wink", type: "far" },
    { name: "fa-grin-wink", type: "fas" },
    { name: "fa-grip-horizontal", type: "fas" },
    { name: "fa-grip-lines", type: "fas" },
    { name: "fa-grip-lines-vertical", type: "fas" },
    { name: "fa-grip-vertical", type: "fas" },
    { name: "fa-gripfire", type: "fab" },
    { name: "fa-grunt", type: "fab" },
    { name: "fa-guitar", type: "fas" },
    { name: "fa-gulp", type: "fab" },
    { name: "fa-h-square", type: "fas" },
    { name: "fa-hacker-news", type: "fab" },
    { name: "fa-hacker-news-square", type: "fab" },
    { name: "fa-hackerrank", type: "fab" },
    { name: "fa-hamburger", type: "fas" },
    { name: "fa-hammer", type: "fas" },
    { name: "fa-hamsa", type: "fas" },
    { name: "fa-hand-holding", type: "fas" },
    { name: "fa-hand-holding-heart", type: "fas" },
    { name: "fa-hand-holding-usd", type: "fas" },
    { name: "fa-hand-lizard", type: "far" },
    { name: "fa-hand-lizard", type: "fas" },
    { name: "fa-hand-middle-finger", type: "fas" },
    { name: "fa-hand-paper", type: "far" },
    { name: "fa-hand-paper", type: "fas" },
    { name: "fa-hand-peace", type: "far" },
    { name: "fa-hand-peace", type: "fas" },
    { name: "fa-hand-point-down", type: "far" },
    { name: "fa-hand-point-down", type: "fas" },
    { name: "fa-hand-point-left", type: "far" },
    { name: "fa-hand-point-left", type: "fas" },
    { name: "fa-hand-point-right", type: "far" },
    { name: "fa-hand-point-right", type: "fas" },
    { name: "fa-hand-point-up", type: "far" },
    { name: "fa-hand-point-up", type: "fas" },
    { name: "fa-hand-pointer", type: "far" },
    { name: "fa-hand-pointer", type: "fas" },
    { name: "fa-hand-rock", type: "far" },
    { name: "fa-hand-rock", type: "fas" },
    { name: "fa-hand-scissors", type: "far" },
    { name: "fa-hand-scissors", type: "fas" },
    { name: "fa-hand-spock", type: "far" },
    { name: "fa-hand-spock", type: "fas" },
    { name: "fa-hands", type: "fas" },
    { name: "fa-hands-helping", type: "fas" },
    { name: "fa-handshake", type: "far" },
    { name: "fa-handshake", type: "fas" },
    { name: "fa-hanukiah", type: "fas" },
    { name: "fa-hard-hat", type: "fas" },
    { name: "fa-hashtag", type: "fas" },
    { name: "fa-hat-wizard", type: "fas" },
    { name: "fa-hdd", type: "far" },
    { name: "fa-hdd", type: "fas" },
    { name: "fa-heading", type: "fas" },
    { name: "fa-headphones", type: "fas" },
    { name: "fa-headphones-alt", type: "fas" },
    { name: "fa-headset", type: "fas" },
    { name: "fa-heart", type: "far" },
    { name: "fa-heart", type: "fas" },
    { name: "fa-heart-broken", type: "fas" },
    { name: "fa-heartbeat", type: "fas" },
    { name: "fa-helicopter", type: "fas" },
    { name: "fa-highlighter", type: "fas" },
    { name: "fa-hiking", type: "fas" },
    { name: "fa-hippo", type: "fas" },
    { name: "fa-hips", type: "fab" },
    { name: "fa-hire-a-helper", type: "fab" },
    { name: "fa-history", type: "fas" },
    { name: "fa-hockey-puck", type: "fas" },
    { name: "fa-holly-berry", type: "fas" },
    { name: "fa-home", type: "fas" },
    { name: "fa-hooli", type: "fab" },
    { name: "fa-hornbill", type: "fab" },
    { name: "fa-horse", type: "fas" },
    { name: "fa-horse-head", type: "fas" },
    { name: "fa-hospital", type: "far" },
    { name: "fa-hospital", type: "fas" },
    { name: "fa-hospital-alt", type: "fas" },
    { name: "fa-hospital-symbol", type: "fas" },
    { name: "fa-hot-tub", type: "fas" },
    { name: "fa-hotdog", type: "fas" },
    { name: "fa-hotel", type: "fas" },
    { name: "fa-hotjar", type: "fab" },
    { name: "fa-hourglass", type: "far" },
    { name: "fa-hourglass", type: "fas" },
    { name: "fa-hourglass-end", type: "fas" },
    { name: "fa-hourglass-half", type: "fas" },
    { name: "fa-hourglass-start", type: "fas" },
    { name: "fa-house-damage", type: "fas" },
    { name: "fa-houzz", type: "fab" },
    { name: "fa-hryvnia", type: "fas" },
    { name: "fa-html5", type: "fab" },
    { name: "fa-hubspot", type: "fab" },
    { name: "fa-i-cursor", type: "fas" },
    { name: "fa-ice-cream", type: "fas" },
    { name: "fa-icicles", type: "fas" },
    { name: "fa-id-badge", type: "far" },
    { name: "fa-id-badge", type: "fas" },
    { name: "fa-id-card", type: "far" },
    { name: "fa-id-card", type: "fas" },
    { name: "fa-id-card-alt", type: "fas" },
    { name: "fa-igloo", type: "fas" },
    { name: "fa-image", type: "far" },
    { name: "fa-image", type: "fas" },
    { name: "fa-images", type: "far" },
    { name: "fa-images", type: "fas" },
    { name: "fa-imdb", type: "fab" },
    { name: "fa-inbox", type: "fas" },
    { name: "fa-indent", type: "fas" },
    { name: "fa-industry", type: "fas" },
    { name: "fa-infinity", type: "fas" },
    { name: "fa-info", type: "fas" },
    { name: "fa-info-circle", type: "fas" },
    { name: "fa-instagram", type: "fab" },
    { name: "fa-intercom", type: "fab" },
    { name: "fa-internet-explorer", type: "fab" },
    { name: "fa-invision", type: "fab" },
    { name: "fa-ioxhost", type: "fab" },
    { name: "fa-italic", type: "fas" },
    { name: "fa-itch-io", type: "fab" },
    { name: "fa-itunes", type: "fab" },
    { name: "fa-itunes-note", type: "fab" },
    { name: "fa-java", type: "fab" },
    { name: "fa-jedi", type: "fas" },
    { name: "fa-jedi-order", type: "fab" },
    { name: "fa-jenkins", type: "fab" },
    { name: "fa-jira", type: "fab" },
    { name: "fa-joget", type: "fab" },
    { name: "fa-joint", type: "fas" },
    { name: "fa-joomla", type: "fab" },
    { name: "fa-journal-whills", type: "fas" },
    { name: "fa-js javascript", type: "fab" },
    { name: "fa-js-square javascript", type: "fab" },
    { name: "fa-jsfiddle  javascript", type: "fab" },
    { name: "fa-kaaba", type: "fas" },
    { name: "fa-kaggle", type: "fab" },
    { name: "fa-key", type: "fas" },
    { name: "fa-keybase", type: "fab" },
    { name: "fa-keyboard", type: "far" },
    { name: "fa-keyboard", type: "fas" },
    { name: "fa-keycdn", type: "fab" },
    { name: "fa-khanda", type: "fas" },
    { name: "fa-kickstarter", type: "fab" },
    { name: "fa-kickstarter-k", type: "fab" },
    { name: "fa-kiss", type: "far" },
    { name: "fa-kiss", type: "fas" },
    { name: "fa-kiss-beam", type: "far" },
    { name: "fa-kiss-beam", type: "fas" },
    { name: "fa-kiss-wink-heart", type: "far" },
    { name: "fa-kiss-wink-heart", type: "fas" },
    { name: "fa-kiwi-bird", type: "fas" },
    { name: "fa-korvue", type: "fab" },
    { name: "fa-landmark", type: "fas" },
    { name: "fa-language", type: "fas" },
    { name: "fa-laptop", type: "fas" },
    { name: "fa-laptop-code", type: "fas" },
    { name: "fa-laptop-medical", type: "fas" },
    { name: "fa-laravel", type: "fab" },
    { name: "fa-lastfm", type: "fab" },
    { name: "fa-lastfm-square", type: "fab" },
    { name: "fa-laugh", type: "far" },
    { name: "fa-laugh", type: "fas" },
    { name: "fa-laugh-beam", type: "far" },
    { name: "fa-laugh-beam", type: "fas" },
    { name: "fa-laugh-squint", type: "far" },
    { name: "fa-laugh-squint", type: "fas" },
    { name: "fa-laugh-wink", type: "far" },
    { name: "fa-laugh-wink", type: "fas" },
    { name: "fa-layer-group", type: "fas" },
    { name: "fa-leaf", type: "fas" },
    { name: "fa-leanpub", type: "fab" },
    { name: "fa-lemon", type: "far" },
    { name: "fa-lemon", type: "fas" },
    { name: "fa-less", type: "fab" },
    { name: "fa-less-than", type: "fas" },
    { name: "fa-less-than-equal", type: "fas" },
    { name: "fa-level-down-alt", type: "fas" },
    { name: "fa-level-up-alt", type: "fas" },
    { name: "fa-life-ring", type: "far" },
    { name: "fa-life-ring", type: "fas" },
    { name: "fa-lightbulb", type: "far" },
    { name: "fa-lightbulb", type: "fas" },
    { name: "fa-line", type: "fab" },
    { name: "fa-link", type: "fas" },
    { name: "fa-linkedin", type: "fab" },
    { name: "fa-linkedin-in", type: "fab" },
    { name: "fa-linode", type: "fab" },
    { name: "fa-linux", type: "fab" },
    { name: "fa-lira-sign", type: "fas" },
    { name: "fa-list", type: "fas" },
    { name: "fa-list-alt", type: "far" },
    { name: "fa-list-alt", type: "fas" },
    { name: "fa-list-ol", type: "fas" },
    { name: "fa-list-ul", type: "fas" },
    { name: "fa-location-arrow", type: "fas" },
    { name: "fa-lock", type: "fas" },
    { name: "fa-lock-open", type: "fas" },
    { name: "fa-long-arrow-alt-down", type: "fas" },
    { name: "fa-long-arrow-alt-left", type: "fas" },
    { name: "fa-long-arrow-alt-right", type: "fas" },
    { name: "fa-long-arrow-alt-up", type: "fas" },
    { name: "fa-low-vision", type: "fas" },
    { name: "fa-luggage-cart", type: "fas" },
    { name: "fa-lyft", type: "fab" },
    { name: "fa-magento", type: "fab" },
    { name: "fa-magic", type: "fas" },
    { name: "fa-magnet", type: "fas" },
    { name: "fa-mail-bulk", type: "fas" },
    { name: "fa-mailchimp", type: "fab" },
    { name: "fa-male", type: "fas" },
    { name: "fa-mandalorian", type: "fab" },
    { name: "fa-map", type: "far" },
    { name: "fa-map", type: "fas" },
    { name: "fa-map-marked", type: "fas" },
    { name: "fa-map-marked-alt", type: "fas" },
    { name: "fa-map-marker", type: "fas" },
    { name: "fa-map-marker-alt", type: "fas" },
    { name: "fa-map-pin", type: "fas" },
    { name: "fa-map-signs", type: "fas" },
    { name: "fa-markdown", type: "fab" },
    { name: "fa-marker", type: "fas" },
    { name: "fa-mars", type: "fas" },
    { name: "fa-mars-double", type: "fas" },
    { name: "fa-mars-stroke", type: "fas" },
    { name: "fa-mars-stroke-h", type: "fas" },
    { name: "fa-mars-stroke-v", type: "fas" },
    { name: "fa-mask", type: "fas" },
    { name: "fa-mastodon", type: "fab" },
    { name: "fa-maxcdn", type: "fab" },
    { name: "fa-medal", type: "fas" },
    { name: "fa-medapps", type: "fab" },
    { name: "fa-medium", type: "fab" },
    { name: "fa-medium-m", type: "fab" },
    { name: "fa-medkit", type: "fas" },
    { name: "fa-medrt", type: "fab" },
    { name: "fa-meetup", type: "fab" },
    { name: "fa-megaport", type: "fab" },
    { name: "fa-meh", type: "far" },
    { name: "fa-meh", type: "fas" },
    { name: "fa-meh-blank", type: "far" },
    { name: "fa-meh-blank", type: "fas" },
    { name: "fa-meh-rolling-eyes", type: "far" },
    { name: "fa-meh-rolling-eyes", type: "fas" },
    { name: "fa-memory", type: "fas" },
    { name: "fa-mendeley", type: "fab" },
    { name: "fa-menorah", type: "fas" },
    { name: "fa-mercury", type: "fas" },
    { name: "fa-meteor", type: "fas" },
    { name: "fa-microchip", type: "fas" },
    { name: "fa-microphone", type: "fas" },
    { name: "fa-microphone-alt", type: "fas" },
    { name: "fa-microphone-alt-slash", type: "fas" },
    { name: "fa-microphone-slash", type: "fas" },
    { name: "fa-microscope", type: "fas" },
    { name: "fa-microsoft", type: "fab" },
    { name: "fa-minus", type: "fas" },
    { name: "fa-minus-circle", type: "fas" },
    { name: "fa-minus-square", type: "far" },
    { name: "fa-minus-square", type: "fas" },
    { name: "fa-mitten", type: "fas" },
    { name: "fa-mix", type: "fab" },
    { name: "fa-mixcloud", type: "fab" },
    { name: "fa-mizuni", type: "fab" },
    { name: "fa-mobile", type: "fas" },
    { name: "fa-mobile-alt", type: "fas" },
    { name: "fa-modx", type: "fab" },
    { name: "fa-monero", type: "fab" },
    { name: "fa-money-bill", type: "fas" },
    { name: "fa-money-bill-alt", type: "far" },
    { name: "fa-money-bill-alt", type: "fas" },
    { name: "fa-money-bill-wave", type: "fas" },
    { name: "fa-money-bill-wave-alt", type: "fas" },
    { name: "fa-money-check", type: "fas" },
    { name: "fa-money-check-alt", type: "fas" },
    { name: "fa-monument", type: "fas" },
    { name: "fa-moon", type: "far" },
    { name: "fa-moon", type: "fas" },
    { name: "fa-mortar-pestle", type: "fas" },
    { name: "fa-mosque", type: "fas" },
    { name: "fa-motorcycle", type: "fas" },
    { name: "fa-mountain", type: "fas" },
    { name: "fa-mouse-pointer", type: "fas" },
    { name: "fa-mug-hot", type: "fas" },
    { name: "fa-music", type: "fas" },
    { name: "fa-napster", type: "fab" },
    { name: "fa-neos", type: "fab" },
    { name: "fa-network-wired", type: "fas" },
    { name: "fa-neuter", type: "fas" },
    { name: "fa-newspaper", type: "far" },
    { name: "fa-newspaper", type: "fas" },
    { name: "fa-nimblr", type: "fab" },
    { name: "fa-node", type: "fab" },
    { name: "fa-node-js", type: "fab" },
    { name: "fa-not-equal", type: "fas" },
    { name: "fa-notes-medical", type: "fas" },
    { name: "fa-npm", type: "fab" },
    { name: "fa-ns8", type: "fab" },
    { name: "fa-nutritionix", type: "fab" },
    { name: "fa-object-group", type: "far" },
    { name: "fa-object-group", type: "fas" },
    { name: "fa-object-ungroup", type: "far" },
    { name: "fa-object-ungroup", type: "fas" },
    { name: "fa-odnoklassniki", type: "fab" },
    { name: "fa-odnoklassniki-square", type: "fab" },
    { name: "fa-oil-can", type: "fas" },
    { name: "fa-old-republic", type: "fab" },
    { name: "fa-om", type: "fas" },
    { name: "fa-opencart", type: "fab" },
    { name: "fa-openid", type: "fab" },
    { name: "fa-opera", type: "fab" },
    { name: "fa-optin-monster", type: "fab" },
    { name: "fa-osi", type: "fab" },
    { name: "fa-otter", type: "fas" },
    { name: "fa-outdent", type: "fas" },
    { name: "fa-page4", type: "fab" },
    { name: "fa-pagelines", type: "fab" },
    { name: "fa-pager", type: "fas" },
    { name: "fa-paint-brush", type: "fas" },
    { name: "fa-paint-roller", type: "fas" },
    { name: "fa-palette", type: "fas" },
    { name: "fa-palfed", type: "fab" },
    { name: "fa-pallet", type: "fas" },
    { name: "fa-paper-plane", type: "far" },
    { name: "fa-paper-plane", type: "fas" },
    { name: "fa-paperclip", type: "fas" },
    { name: "fa-parachute-box", type: "fas" },
    { name: "fa-paragraph", type: "fas" },
    { name: "fa-parking", type: "fas" },
    { name: "fa-passport", type: "fas" },
    { name: "fa-pastafarianism", type: "fas" },
    { name: "fa-paste", type: "fas" },
    { name: "fa-patreon", type: "fab" },
    { name: "fa-pause", type: "fas" },
    { name: "fa-pause-circle", type: "far" },
    { name: "fa-pause-circle", type: "fas" },
    { name: "fa-paw", type: "fas" },
    { name: "fa-paypal", type: "fab" },
    { name: "fa-peace", type: "fas" },
    { name: "fa-pen", type: "fas" },
    { name: " fa-php", type: " fa-brands" },
    { name: "fa-pen-alt", type: "fas" },
    { name: "fa-pen-fancy", type: "fas" },
    { name: "fa-pen-nib", type: "fas" },
    { name: "fa-pen-square", type: "fas" },
    { name: "fa-pencil-alt", type: "fas" },
    { name: "fa-pencil-ruler", type: "fas" },
    { name: "fa-people-carry", type: "fas" },
    { name: "fa-pepper-hot", type: "fas" },
    { name: "fa-percent", type: "fas" },
    { name: "fa-percentage", type: "fas" },
    { name: "fa-periscope", type: "fab" },
    { name: "fa-person-booth", type: "fas" },
    { name: "fa-phabricator", type: "fab" },
    { name: "fa-phoenix-framework", type: "fab" },
    { name: "fa-phoenix-squadron", type: "fab" },
    { name: "fa-phone", type: "fas" },
    { name: "fa-phone-slash", type: "fas" },
    { name: "fa-phone-square", type: "fas" },
    { name: "fa-phone-volume", type: "fas" },
    { name: "fa-php", type: "fab" },
    { name: "fa-pied-piper", type: "fab" },
    { name: "fa-pied-piper-alt", type: "fab" },
    { name: "fa-pied-piper-hat", type: "fab" },
    { name: "fa-pied-piper-pp", type: "fab" },
    { name: "fa-piggy-bank", type: "fas" },
    { name: "fa-pills", type: "fas" },
    { name: "fa-pinterest", type: "fab" },
    { name: "fa-pinterest-p", type: "fab" },
    { name: "fa-pinterest-square", type: "fab" },
    { name: "fa-pizza-slice", type: "fas" },
    { name: "fa-place-of-worship", type: "fas" },
    { name: "fa-plane", type: "fas" },
    { name: "fa-plane-arrival", type: "fas" },
    { name: "fa-plane-departure", type: "fas" },
    { name: "fa-play", type: "fas" },
    { name: "fa-play-circle", type: "far" },
    { name: "fa-play-circle", type: "fas" },
    { name: "fa-playstation", type: "fab" },
    { name: "fa-plug", type: "fas" },
    { name: "fa-plus", type: "fas" },
    { name: "fa-plus-circle", type: "fas" },
    { name: "fa-plus-square", type: "far" },
    { name: "fa-plus-square", type: "fas" },
    { name: "fa-podcast", type: "fas" },
    { name: "fa-poll", type: "fas" },
    { name: "fa-poll-h", type: "fas" },
    { name: "fa-poo", type: "fas" },
    { name: "fa-poo-storm", type: "fas" },
    { name: "fa-poop", type: "fas" },
    { name: "fa-portrait", type: "fas" },
    { name: "fa-pound-sign", type: "fas" },
    { name: "fa-power-off", type: "fas" },
    { name: "fa-pray", type: "fas" },
    { name: "fa-praying-hands", type: "fas" },
    { name: "fa-prescription", type: "fas" },
    { name: "fa-prescription-bottle", type: "fas" },
    { name: "fa-prescription-bottle-alt", type: "fas" },
    { name: "fa-print", type: "fas" },
    { name: "fa-procedures", type: "fas" },
    { name: "fa-product-hunt", type: "fab" },
    { name: "fa-project-diagram", type: "fas" },
    { name: "fa-pushed", type: "fab" },
    { name: "fa-puzzle-piece", type: "fas" },
    { name: "fa-python", type: "fab" },
    { name: "fa-qq", type: "fab" },
    { name: "fa-qrcode", type: "fas" },
    { name: "fa-question", type: "fas" },
    { name: "fa-question-circle", type: "far" },
    { name: "fa-question-circle", type: "fas" },
    { name: "fa-quidditch", type: "fas" },
    { name: "fa-quinscape", type: "fab" },
    { name: "fa-quora", type: "fab" },
    { name: "fa-quote-left", type: "fas" },
    { name: "fa-quote-right", type: "fas" },
    { name: "fa-quran", type: "fas" },
    { name: "fa-r-project", type: "fab" },
    { name: "fa-radiation", type: "fas" },
    { name: "fa-radiation-alt", type: "fas" },
    { name: "fa-rainbow", type: "fas" },
    { name: "fa-random", type: "fas" },
    { name: "fa-raspberry-pi", type: "fab" },
    { name: "fa-ravelry", type: "fab" },
    { name: "fa-react", type: "fab" },
    { name: "fa-reacteurope", type: "fab" },
    { name: "fa-readme", type: "fab" },
    { name: "fa-rebel", type: "fab" },
    { name: "fa-receipt", type: "fas" },
    { name: "fa-recycle", type: "fas" },
    { name: "fa-red-river", type: "fab" },
    { name: "fa-reddit", type: "fab" },
    { name: "fa-reddit-alien", type: "fab" },
    { name: "fa-reddit-square", type: "fab" },
    { name: "fa-redhat", type: "fab" },
    { name: "fa-redo", type: "fas" },
    { name: "fa-redo-alt", type: "fas" },
    { name: "fa-registered", type: "far" },
    { name: "fa-registered", type: "fas" },
    { name: "fa-renren", type: "fab" },
    { name: "fa-reply", type: "fas" },
    { name: "fa-reply-all", type: "fas" },
    { name: "fa-replyd", type: "fab" },
    { name: "fa-republican", type: "fas" },
    { name: "fa-researchgate", type: "fab" },
    { name: "fa-resolving", type: "fab" },
    { name: "fa-restroom", type: "fas" },
    { name: "fa-retweet", type: "fas" },
    { name: "fa-rev", type: "fab" },
    { name: "fa-ribbon", type: "fas" },
    { name: "fa-ring", type: "fas" },
    { name: "fa-road", type: "fas" },
    { name: "fa-robot", type: "fas" },
    { name: "fa-rocket", type: "fas" },
    { name: "fa-rocketchat", type: "fab" },
    { name: "fa-rockrms", type: "fab" },
    { name: "fa-route", type: "fas" },
    { name: "fa-rss", type: "fas" },
    { name: "fa-rss-square", type: "fas" },
    { name: "fa-ruble-sign", type: "fas" },
    { name: "fa-ruler", type: "fas" },
    { name: "fa-ruler-combined", type: "fas" },
    { name: "fa-ruler-horizontal", type: "fas" },
    { name: "fa-ruler-vertical", type: "fas" },
    { name: "fa-running", type: "fas" },
    { name: "fa-rupee-sign", type: "fas" },
    { name: "fa-sad-cry", type: "far" },
    { name: "fa-sad-cry", type: "fas" },
    { name: "fa-sad-tear", type: "far" },
    { name: "fa-sad-tear", type: "fas" },
    { name: "fa-safari", type: "fab" },
    { name: "fa-salesforce", type: "fab" },
    { name: "fa-sass", type: "fab" },
    { name: "fa-satellite", type: "fas" },
    { name: "fa-satellite-dish", type: "fas" },
    { name: "fa-save", type: "far" },
    { name: "fa-save", type: "fas" },
    { name: "fa-schlix", type: "fab" },
    { name: "fa-school", type: "fas" },
    { name: "fa-screwdriver", type: "fas" },
    { name: "fa-scribd", type: "fab" },
    { name: "fa-scroll", type: "fas" },
    { name: "fa-sd-card", type: "fas" },
    { name: "fa-search", type: "fas" },
    { name: "fa-search-dollar", type: "fas" },
    { name: "fa-search-location", type: "fas" },
    { name: "fa-search-minus", type: "fas" },
    { name: "fa-search-plus", type: "fas" },
    { name: "fa-searchengin", type: "fab" },
    { name: "fa-seedling", type: "fas" },
    { name: "fa-sellcast", type: "fab" },
    { name: "fa-sellsy", type: "fab" },
    { name: "fa-server", type: "fas" },
    { name: "fa-servicestack", type: "fab" },
    { name: "fa-shapes", type: "fas" },
    { name: "fa-share", type: "fas" },
    { name: "fa-share-alt", type: "fas" },
    { name: "fa-share-alt-square", type: "fas" },
    { name: "fa-share-square", type: "far" },
    { name: "fa-share-square", type: "fas" },
    { name: "fa-shekel-sign", type: "fas" },
    { name: "fa-shield-alt", type: "fas" },
    { name: "fa-ship", type: "fas" },
    { name: "fa-shipping-fast", type: "fas" },
    { name: "fa-shirtsinbulk", type: "fab" },
    { name: "fa-shoe-prints", type: "fas" },
    { name: "fa-shopping-bag", type: "fas" },
    { name: "fa-shopping-basket", type: "fas" },
    { name: "fa-shopping-cart", type: "fas" },
    { name: "fa-shopware", type: "fab" },
    { name: "fa-shower", type: "fas" },
    { name: "fa-shuttle-van", type: "fas" },
    { name: "fa-sign", type: "fas" },
    { name: "fa-sign-in-alt", type: "fas" },
    { name: "fa-sign-language", type: "fas" },
    { name: "fa-sign-out-alt", type: "fas" },
    { name: "fa-signal", type: "fas" },
    { name: "fa-signature", type: "fas" },
    { name: "fa-sim-card", type: "fas" },
    { name: "fa-simplybuilt", type: "fab" },
    { name: "fa-sistrix", type: "fab" },
    { name: "fa-sitemap", type: "fas" },
    { name: "fa-sith", type: "fab" },
    { name: "fa-skating", type: "fas" },
    { name: "fa-sketch", type: "fab" },
    { name: "fa-skiing", type: "fas" },
    { name: "fa-skiing-nordic", type: "fas" },
    { name: "fa-skull", type: "fas" },
    { name: "fa-skull-crossbones", type: "fas" },
    { name: "fa-skyatlas", type: "fab" },
    { name: "fa-skype", type: "fab" },
    { name: "fa-slack", type: "fab" },
    { name: "fa-slack-hash", type: "fab" },
    { name: "fa-slash", type: "fas" },
    { name: "fa-sleigh", type: "fas" },
    { name: "fa-sliders-h", type: "fas" },
    { name: "fa-slideshare", type: "fab" },
    { name: "fa-smile", type: "far" },
    { name: "fa-smile", type: "fas" },
    { name: "fa-smile-beam", type: "far" },
    { name: "fa-smile-beam", type: "fas" },
    { name: "fa-smile-wink", type: "far" },
    { name: "fa-smile-wink", type: "fas" },
    { name: "fa-smog", type: "fas" },
    { name: "fa-smoking", type: "fas" },
    { name: "fa-smoking-ban", type: "fas" },
    { name: "fa-sms", type: "fas" },
    { name: "fa-snapchat", type: "fab" },
    { name: "fa-snapchat-ghost", type: "fab" },
    { name: "fa-snapchat-square", type: "fab" },
    { name: "fa-snowboarding", type: "fas" },
    { name: "fa-snowflake", type: "far" },
    { name: "fa-snowflake", type: "fas" },
    { name: "fa-snowman", type: "fas" },
    { name: "fa-snowplow", type: "fas" },
    { name: "fa-socks", type: "fas" },
    { name: "fa-solar-panel", type: "fas" },
    { name: "fa-sort", type: "fas" },
    { name: "fa-sort-alpha-down", type: "fas" },
    { name: "fa-sort-alpha-up", type: "fas" },
    { name: "fa-sort-amount-down", type: "fas" },
    { name: "fa-sort-amount-up", type: "fas" },
    { name: "fa-sort-down", type: "fas" },
    { name: "fa-sort-numeric-down", type: "fas" },
    { name: "fa-sort-numeric-up", type: "fas" },
    { name: "fa-sort-up", type: "fas" },
    { name: "fa-soundcloud", type: "fab" },
    { name: "fa-sourcetree", type: "fab" },
    { name: "fa-spa", type: "fas" },
    { name: "fa-space-shuttle", type: "fas" },
    { name: "fa-speakap", type: "fab" },
    { name: "fa-speaker-deck", type: "fab" },
    { name: "fa-spider", type: "fas" },
    { name: "fa-spinner", type: "fas" },
    { name: "fa-splotch", type: "fas" },
    { name: "fa-spotify", type: "fab" },
    { name: "fa-spray-can", type: "fas" },
    { name: "fa-square", type: "far" },
    { name: "fa-square", type: "fas" },
    { name: "fa-square-full", type: "fas" },
    { name: "fa-square-root-alt", type: "fas" },
    { name: "fa-squarespace", type: "fab" },
    { name: "fa-stack-exchange", type: "fab" },
    { name: "fa-stack-overflow", type: "fab" },
    { name: "fa-stackpath", type: "fab" },
    { name: "fa-stamp", type: "fas" },
    { name: "fa-star", type: "far" },
    { name: "fa-star", type: "fas" },
    { name: "fa-star-and-crescent", type: "fas" },
    { name: "fa-star-half", type: "far" },
    { name: "fa-star-half", type: "fas" },
    { name: "fa-star-half-alt", type: "fas" },
    { name: "fa-star-of-david", type: "fas" },
    { name: "fa-star-of-life", type: "fas" },
    { name: "fa-staylinked", type: "fab" },
    { name: "fa-steam", type: "fab" },
    { name: "fa-steam-square", type: "fab" },
    { name: "fa-steam-symbol", type: "fab" },
    { name: "fa-step-backward", type: "fas" },
    { name: "fa-step-forward", type: "fas" },
    { name: "fa-stethoscope", type: "fas" },
    { name: "fa-sticker-mule", type: "fab" },
    { name: "fa-sticky-note", type: "far" },
    { name: "fa-sticky-note", type: "fas" },
    { name: "fa-stop", type: "fas" },
    { name: "fa-stop-circle", type: "far" },
    { name: "fa-stop-circle", type: "fas" },
    { name: "fa-stopwatch", type: "fas" },
    { name: "fa-store", type: "fas" },
    { name: "fa-store-alt", type: "fas" },
    { name: "fa-strava", type: "fab" },
    { name: "fa-stream", type: "fas" },
    { name: "fa-street-view", type: "fas" },
    { name: "fa-strikethrough", type: "fas" },
    { name: "fa-stripe", type: "fab" },
    { name: "fa-stripe-s", type: "fab" },
    { name: "fa-stroopwafel", type: "fas" },
    { name: "fa-studiovinari", type: "fab" },
    { name: "fa-stumbleupon", type: "fab" },
    { name: "fa-stumbleupon-circle", type: "fab" },
    { name: "fa-subscript", type: "fas" },
    { name: "fa-subway", type: "fas" },
    { name: "fa-suitcase", type: "fas" },
    { name: "fa-suitcase-rolling", type: "fas" },
    { name: "fa-sun", type: "far" },
    { name: "fa-sun", type: "fas" },
    { name: "fa-superpowers", type: "fab" },
    { name: "fa-superscript", type: "fas" },
    { name: "fa-supple", type: "fab" },
    { name: "fa-surprise", type: "far" },
    { name: "fa-surprise", type: "fas" },
    { name: "fa-suse", type: "fab" },
    { name: "fa-swatchbook", type: "fas" },
    { name: "fa-swimmer", type: "fas" },
    { name: "fa-swimming-pool", type: "fas" },
    { name: "fa-symfony", type: "fab" },
    { name: "fa-synagogue", type: "fas" },
    { name: "fa-sync", type: "fas" },
    { name: "fa-sync-alt", type: "fas" },
    { name: "fa-syringe", type: "fas" },
    { name: "fa-table", type: "fas" },
    { name: "fa-table-tennis", type: "fas" },
    { name: "fa-tablet", type: "fas" },
    { name: "fa-tablet-alt", type: "fas" },
    { name: "fa-tablets", type: "fas" },
    { name: "fa-tachometer-alt", type: "fas" },
    { name: "fa-tag", type: "fas" },
    { name: "fa-tags", type: "fas" },
    { name: "fa-tape", type: "fas" },
    { name: "fa-tasks", type: "fas" },
    { name: "fa-taxi", type: "fas" },
    { name: "fa-teamspeak", type: "fab" },
    { name: "fa-teeth", type: "fas" },
    { name: "fa-teeth-open", type: "fas" },
    { name: "fa-telegram", type: "fab" },
    { name: "fa-telegram-plane", type: "fab" },
    { name: "fa-temperature-high", type: "fas" },
    { name: "fa-temperature-low", type: "fas" },
    { name: "fa-tencent-weibo", type: "fab" },
    { name: "fa-tenge", type: "fas" },
    { name: "fa-terminal", type: "fas" },
    { name: "fa-text-height", type: "fas" },
    { name: "fa-text-width", type: "fas" },
    { name: "fa-th", type: "fas" },
    { name: "fa-th-large", type: "fas" },
    { name: "fa-th-list", type: "fas" },
    { name: "fa-the-red-yeti", type: "fab" },
    { name: "fa-theater-masks", type: "fas" },
    { name: "fa-themeco", type: "fab" },
    { name: "fa-themeisle", type: "fab" },
    { name: "fa-thermometer", type: "fas" },
    { name: "fa-thermometer-empty", type: "fas" },
    { name: "fa-thermometer-full", type: "fas" },
    { name: "fa-thermometer-half", type: "fas" },
    { name: "fa-thermometer-quarter", type: "fas" },
    { name: "fa-thermometer-three-quarters", type: "fas" },
    { name: "fa-think-peaks", type: "fab" },
    { name: "fa-thumbs-down", type: "far" },
    { name: "fa-thumbs-down", type: "fas" },
    { name: "fa-thumbs-up", type: "far" },
    { name: "fa-thumbs-up", type: "fas" },
    { name: "fa-thumbtack", type: "fas" },
    { name: "fa-ticket-alt", type: "fas" },
    { name: "fa-times", type: "fas" },
    { name: "fa-times-circle", type: "far" },
    { name: "fa-times-circle", type: "fas" },
    { name: "fa-tint", type: "fas" },
    { name: "fa-tint-slash", type: "fas" },
    { name: "fa-tired", type: "far" },
    { name: "fa-tired", type: "fas" },
    { name: "fa-toggle-off", type: "fas" },
    { name: "fa-toggle-on", type: "fas" },
    { name: "fa-toilet", type: "fas" },
    { name: "fa-toilet-paper", type: "fas" },
    { name: "fa-toolbox", type: "fas" },
    { name: "fa-tools", type: "fas" },
    { name: "fa-tooth", type: "fas" },
    { name: "fa-torah", type: "fas" },
    { name: "fa-torii-gate", type: "fas" },
    { name: "fa-tractor", type: "fas" },
    { name: "fa-trade-federation", type: "fab" },
    { name: "fa-trademark", type: "fas" },
    { name: "fa-traffic-light", type: "fas" },
    { name: "fa-train", type: "fas" },
    { name: "fa-tram", type: "fas" },
    { name: "fa-transgender", type: "fas" },
    { name: "fa-transgender-alt", type: "fas" },
    { name: "fa-trash", type: "fas" },
    { name: "fa-trash-alt", type: "far" },
    { name: "fa-trash-alt", type: "fas" },
    { name: "fa-trash-restore", type: "fas" },
    { name: "fa-trash-restore-alt", type: "fas" },
    { name: "fa-tree", type: "fas" },
    { name: "fa-trello", type: "fab" },
    { name: "fa-tripadvisor", type: "fab" },
    { name: "fa-trophy", type: "fas" },
    { name: "fa-truck", type: "fas" },
    { name: "fa-truck-loading", type: "fas" },
    { name: "fa-truck-monster", type: "fas" },
    { name: "fa-truck-moving", type: "fas" },
    { name: "fa-truck-pickup", type: "fas" },
    { name: "fa-tshirt", type: "fas" },
    { name: "fa-tty", type: "fas" },
    { name: "fa-tumblr", type: "fab" },
    { name: "fa-tumblr-square", type: "fab" },
    { name: "fa-tv", type: "fas" },
    { name: "fa-twitch", type: "fab" },
    { name: "fa-twitter", type: "fab" },
    { name: "fa-twitter-square", type: "fab" },
    { name: "fa-typo3", type: "fab" },
    { name: "fa-uber", type: "fab" },
    { name: "fa-ubuntu", type: "fab" },
    { name: "fa-uikit", type: "fab" },
    { name: "fa-umbrella", type: "fas" },
    { name: "fa-umbrella-beach", type: "fas" },
    { name: "fa-underline", type: "fas" },
    { name: "fa-undo", type: "fas" },
    { name: "fa-undo-alt", type: "fas" },
    { name: "fa-uniregistry", type: "fab" },
    { name: "fa-universal-access", type: "fas" },
    { name: "fa-university", type: "fas" },
    { name: "fa-unlink", type: "fas" },
    { name: "fa-unlock", type: "fas" },
    { name: "fa-unlock-alt", type: "fas" },
    { name: "fa-untappd", type: "fab" },
    { name: "fa-upload", type: "fas" },
    { name: "fa-ups", type: "fab" },
    { name: "fa-usb", type: "fab" },
    { name: "fa-user", type: "far" },
    { name: "fa-user", type: "fas" },
    { name: "fa-user-alt", type: "fas" },
    { name: "fa-user-alt-slash", type: "fas" },
    { name: "fa-user-astronaut", type: "fas" },
    { name: "fa-user-check", type: "fas" },
    { name: "fa-user-circle", type: "far" },
    { name: "fa-user-circle", type: "fas" },
    { name: "fa-user-clock", type: "fas" },
    { name: "fa-user-cog", type: "fas" },
    { name: "fa-user-edit", type: "fas" },
    { name: "fa-user-friends", type: "fas" },
    { name: "fa-user-graduate", type: "fas" },
    { name: "fa-user-injured", type: "fas" },
    { name: "fa-user-lock", type: "fas" },
    { name: "fa-user-md", type: "fas" },
    { name: "fa-user-minus", type: "fas" },
    { name: "fa-user-ninja", type: "fas" },
    { name: "fa-user-nurse", type: "fas" },
    { name: "fa-user-plus", type: "fas" },
    { name: "fa-user-secret", type: "fas" },
    { name: "fa-user-shield", type: "fas" },
    { name: "fa-user-slash", type: "fas" },
    { name: "fa-user-tag", type: "fas" },
    { name: "fa-user-tie", type: "fas" },
    { name: "fa-user-times", type: "fas" },
    { name: "fa-users", type: "fas" },
    { name: "fa-users-cog", type: "fas" },
    { name: "fa-usps", type: "fab" },
    { name: "fa-ussunnah", type: "fab" },
    { name: "fa-utensil-spoon", type: "fas" },
    { name: "fa-utensils", type: "fas" },
    { name: "fa-vaadin", type: "fab" },
    { name: "fa-vector-square", type: "fas" },
    { name: "fa-venus", type: "fas" },
    { name: "fa-venus-double", type: "fas" },
    { name: "fa-venus-mars", type: "fas" },
    { name: "fa-viacoin", type: "fab" },
    { name: "fa-viadeo", type: "fab" },
    { name: "fa-viadeo-square", type: "fab" },
    { name: "fa-vial", type: "fas" },
    { name: "fa-vials", type: "fas" },
    { name: "fa-viber", type: "fab" },
    { name: "fa-video", type: "fas" },
    { name: "fa-video-slash", type: "fas" },
    { name: "fa-vihara", type: "fas" },
    { name: "fa-vimeo", type: "fab" },
    { name: "fa-vimeo-square", type: "fab" },
    { name: "fa-vimeo-v", type: "fab" },
    { name: "fa-vine", type: "fab" },
    { name: "fa-vk", type: "fab" },
    { name: "fa-vnv", type: "fab" },
    { name: "fa-volleyball-ball", type: "fas" },
    { name: "fa-volume-down", type: "fas" },
    { name: "fa-volume-mute", type: "fas" },
    { name: "fa-volume-off", type: "fas" },
    { name: "fa-volume-up", type: "fas" },
    { name: "fa-vote-yea", type: "fas" },
    { name: "fa-vr-cardboard", type: "fas" },
    { name: "fa-vuejs javascript", type: "fab" },
    { name: "fa-walking", type: "fas" },
    { name: "fa-wallet", type: "fas" },
    { name: "fa-warehouse", type: "fas" },
    { name: "fa-water", type: "fas" },
    { name: "fa-wave-square", type: "fas" },
    { name: "fa-waze", type: "fab" },
    { name: "fa-weebly", type: "fab" },
    { name: "fa-weibo", type: "fab" },
    { name: "fa-weight", type: "fas" },
    { name: "fa-weight-hanging", type: "fas" },
    { name: "fa-weixin", type: "fab" },
    { name: "fa-whatsapp", type: "fab" },
    { name: "fa-whatsapp-square", type: "fab" },
    { name: "fa-wheelchair", type: "fas" },
    { name: "fa-whmcs", type: "fab" },
    { name: "fa-wifi", type: "fas" },
    { name: "fa-wikipedia-w", type: "fab" },
    { name: "fa-wind", type: "fas" },
    { name: "fa-window-close", type: "far" },
    { name: "fa-window-close", type: "fas" },
    { name: "fa-window-maximize", type: "far" },
    { name: "fa-window-maximize", type: "fas" },
    { name: "fa-window-minimize", type: "far" },
    { name: "fa-window-minimize", type: "fas" },
    { name: "fa-window-restore", type: "far" },
    { name: "fa-window-restore", type: "fas" },
    { name: "fa-windows", type: "fab" },
    { name: "fa-wine-bottle", type: "fas" },
    { name: "fa-wine-glass", type: "fas" },
    { name: "fa-wine-glass-alt", type: "fas" },
    { name: "fa-wix", type: "fab" },
    { name: "fa-wizards-of-the-coast", type: "fab" },
    { name: "fa-wolf-pack-battalion", type: "fab" },
    { name: "fa-won-sign", type: "fas" },
    { name: "fa-wordpress", type: "fab" },
    { name: "fa-wordpress-simple", type: "fab" },
    { name: "fa-wpbeginner", type: "fab" },
    { name: "fa-wpexplorer", type: "fab" },
    { name: "fa-wpforms", type: "fab" },
    { name: "fa-wpressr", type: "fab" },
    { name: "fa-wrench", type: "fas" },
    { name: "fa-x-ray", type: "fas" },
    { name: "fa-xbox", type: "fab" },
    { name: "fa-xing", type: "fab" },
    { name: "fa-xing-square", type: "fab" },
    { name: "fa-y-combinator", type: "fab" },
    { name: "fa-yahoo", type: "fab" },
    { name: "fa-yammer", type: "fab" },
    { name: "fa-yandex", type: "fab" },
    { name: "fa-yandex-international", type: "fab" },
    { name: "fa-yarn", type: "fab" },
    { name: "fa-yelp", type: "fab" },
    { name: "fa-yen-sign", type: "fas" },
    { name: "fa-yin-yang", type: "fas" },
    { name: "fa-yoast", type: "fab" },
    { name: "fa-youtube", type: "fab" },
    { name: "fa-youtube-square", type: "fab" },
    { name: "fa-zhihu", type: "fab" },
];
