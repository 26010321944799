import {
  Box,
  Divider,
  Drawer,
  IconButton,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import MkButton from "../../../app/assets/theme/MkButton";
import MkAutoComplete from "../../../app/assets/theme/MkAutoComplete";
import InputFormComponent from "../../../app/components/Form/InputFormComponent";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { citiesOptions } from "../../../features/location/CityApi";
import { handleCitiesOptions } from "../../../features/location/CitySlice";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

export default function CityDrawer({
  open: drawerOpen,
  onClose: drawerOnClose,
  inputValues = null,
  action,
  children,
}) {
  const [country, setCountry] = useState({
    id: "644a27f554115945e338282a",
    name: "Myanmar",
  });
  const [divisionName, setDivisionName] = useState(null);
  const [cityName, setCityName] = useState(null);
  const [code, setCode] = useState("");
  const dispatch = useDispatch();

  // extra
  const { paginationData, cityOptions } = useSelector(
    (state) => state.CitiesListSlice
  );

  const { divisionOptions } = useSelector((state) => state.DivisionListSlice);
  // console.log(divisionOptions);
  const closeNow = () => {
    drawerOnClose();
    setCode("");
    setCityName(null);
    setCountry({ id: "644a27f554115945e338282a", name: "Myanmar" });
    setDivisionName(null);
    dispatch(handleCitiesOptions());
  };

  const addCity = () => {
    console.log(divisionName);
    const data = {
      country_id: country?.id,
      division_id: divisionName?._id,
      name: cityName?.name ?? cityName,
      short_code: code,
    };
    const updateData = {
      country_id: country?.id,
      division_id: divisionName?._id ?? divisionName?.id,
      name: cityName?.name ?? cityName,
      short_code: code,
    };
    if ((country, name, code, divisionName)) {
      // For update
      if (inputValues?._id) {
        dispatch(
          action({
            data: updateData,
            id: inputValues?._id,
            page: paginationData.page,
            limit: paginationData.limit,
          })
        );
        closeNow();
        return;
      }
      // For add
      dispatch(
        action({
          data: data,
          page: paginationData.page,
          limit: paginationData.limit,
        })
      );
    }
    closeNow();
  };

  useEffect(() => {
    if (inputValues) {
      console.log(inputValues);
      setCountry({
        id: inputValues.country._id,
        name: inputValues.country.country_name,
      });
      setDivisionName({
        id: inputValues.division._id,
        name: inputValues.division.name,
      });
      dispatch(
        citiesOptions({
          id: inputValues.division._id,
        })
      );
      setCityName(inputValues.name);
      setCode(inputValues.short_code);
    } else {
      dispatch(handleCitiesOptions());
    }
  }, [drawerOpen]);

  return (
    <div>
      {children}
      <Drawer anchor="right" open={drawerOpen} onClose={closeNow}>
        <DrawerHeader>
          <IconButton onClick={closeNow}>
            <CloseIcon />
          </IconButton>

          {/* here is as your desire */}

          <Typography variant="subtitle2" sx={{ marginRight: "40px" }}>
            {inputValues?.name ? `Update ${inputValues?.name} ` : "Add "}
            City
          </Typography>

          {/* --------------------------------------------  */}
        </DrawerHeader>
        <Divider />
        <Box sx={{ width: 450 }}>
          <Stack
            direction="column"
            spacing={2}
            justifyContent="center"
            sx={{ padding: 5 }}
          >
            <InputLabel htmlFor="select" sx={{ fontSize: "14.5px" }}>
              Select Country
            </InputLabel>
            <MkAutoComplete
              disabled={true}
              name="countries"
              noOptionsText="No related Country"
              fullWidth
              placeholder="Country"
              options={[{ id: "644a27f554115945e338282a", name: "Myanmar" }]}
              getOptionLabel={(option) => option?.name ?? option}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                setCountry(newValue);
              }}
              value={{ id: "644a27f554115945e338282a", name: "Myanmar" }}
            />
            <InputLabel htmlFor="select" sx={{ fontSize: "14.5px" }}>
              Select Division
            </InputLabel>
            <MkAutoComplete
              freeSolo={true}
              name="divisions"
              noOptionsText="No related Division"
              fullWidth
              placeholder="Division"
              options={divisionOptions}
              getOptionLabel={(option) => option?.name ?? option}
              // getOptionLabel={(option) => console.log(option)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue && newValue._id === divisionName?._id) {
                  dispatch(
                    citiesOptions({
                      id: newValue._id,
                    })
                  );
                  setDivisionName(newValue);
                  // return;
                }
                if (newValue === null) {
                  dispatch(handleCitiesOptions());
                  setCityName(null);
                  setCode("");
                  setDivisionName(null);
                  return;
                }

                dispatch(
                  citiesOptions({
                    id: newValue._id,
                  })
                );
                setCityName(null);
                setCode("");
                setDivisionName(newValue);
              }}
              value={divisionName}
            />
            <InputLabel htmlFor="select" sx={{ fontSize: "14.5px" }}>
              Select City
            </InputLabel>
            <MkAutoComplete
              name="city"
              noOptionsText="No related City"
              fullWidth
              freeSolo={true}
              placeholder="City"
              options={cityOptions}
              getOptionLabel={(option) => option?.name ?? option}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  setCityName(newValue);
                  return;
                }
                if (newValue === null) {
                  setCityName(null);
                  setCode("");
                  return;
                }
              }}
              onTextChange={(e) => {
                setCityName(e.target.value);
              }}
              value={cityName}
            />
            <InputFormComponent
              name="City Short Code"
              placeholder="City Short Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              label="City Short Code"
            />
            <MkButton
              mkcolor={`linear-gradient(310deg, #2152ff, #02c6f3)`}
              size="small"
              onClick={addCity}
            >
              {inputValues ? `Update` : "Add"}
            </MkButton>
          </Stack>

          {/* --------------------------------------------  */}
        </Box>
      </Drawer>
    </div>
  );
}
