import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../app/hooks";

export const RegisterApi = createAsyncThunk("admin/register", async (data) => {
  // console.log(data);
  const response = await fetch(`${api}/admin/register`, {
    method: "POST",
    // body: JSON.stringify(data),
    body: data,
  });
  const result = await response.json();
  console.log(result);
  return result;
});
