import {
  Box,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addSelectedBook } from "../../features/book/BookSlice";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InputFormComponent from "../../app/components/Form/InputFormComponent";
import SimpleInput from "../../app/components/SimpleInput";
import { formatMoney } from "../../app/helper/formatMoney";

export default function SaleBookTableItem({ item }) {
  const dispatch = useDispatch();
  const { selectedBooks } = useSelector((state) => state.AllBookSlice);
  const [qty, setQty] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [editQty, setEditQty] = useState(false);
  const [editDis, setEditDis] = useState(false);
  const [focus, setFocus] = useState(false);

  const handleRemove = (id) => {
    dispatch(addSelectedBook(selectedBooks.filter((i) => i._id !== id)));
  };

  useEffect(() => {
    if (editQty || editDis) {
      setFocus(true);
    } else {
      setFocus(false);
    }
  }, [editQty, editDis]);

  const handleIncrementQty = (item, val) => {
    const books = selectedBooks.map((i) => {
      if (i._id === item._id) {
        return {
          ...i,
          qty: Number(val),
        };
      } else {
        return i;
      }
    });
    dispatch(addSelectedBook(books));
  };

  const handleIncrementDiscount = (item, val) => {
    const books = selectedBooks.map((i) => {
      if (i._id === item._id) {
        return {
          ...i,
          discount: Number(val),
        };
      } else {
        return i;
      }
    });
    dispatch(addSelectedBook(books));
  };

  return (
    <TableRow>
      <TableCell>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <HighlightOffIcon
            sx={{ color: "red", cursor: "pointer" }}
            onClick={() => handleRemove(item._id)}
          />
          <Stack direction={"column"}>
            <Typography
              variant="caption"
              fontSize={"18px"}
              fontWeight={"bold"}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
            >
              {item?.book_title}
            </Typography>
            <Typography
              variant="subtitl2"
              mt={1}
              fontSize={"12px"}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
                lineHeight: 2,
              }}
            >
              {item?.authors?.map((i, index) => {
                if (item?.authors?.length === index + 1) {
                  return i.name;
                } else {
                  return i.name + ",";
                }
              })}
            </Typography>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell align="center">
        <Typography variant="caption">
          {formatMoney(item?.sale_price)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Box
          sx={{
            width: "70px",
            margin: "auto",
          }}
        >
          <SimpleInput
            type="number"
            name="qty"
            inputProps={{ min: 1 }}
            placeholder="Qty"
            value={qty ?? item.qty}
            fullWidth
            error={false}
            onBlur={() => setEditQty(false)}
            autoFocus={focus}
            onChange={(e) => {
              handleIncrementQty(item, e.target.value);
              setQty(e.target.value);
            }}
          />
        </Box>
      </TableCell>
      <TableCell align="right">
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            display: editDis ? "none" : "",
          }}
        >
          <Typography variant="caption" onClick={() => setEditDis(true)}>
            {formatMoney(discount ?? item.discount)}
          </Typography>
        </Stack>
        <Box
          sx={{
            width: "70px",
            margin: "auto",
            display: editDis ? "" : "none",
          }}
        >
          <SimpleInput
            name="discount"
            placeholder="Discount"
            value={discount ?? item.discount}
            fullWidth
            error={false}
            onBlur={() => setEditDis(false)}
            autoFocus={focus}
            onChange={(e) => {
              handleIncrementDiscount(item, e.target.value);
              setDiscount(e.target.value);
            }}
            onKeyPress={(event) => {
              if (event.code === "Enter") {
                setEditDis(false);
                if (discount === "") {
                  handleIncrementDiscount(item, 0);
                  setDiscount(0);
                }
              }
            }}
          />
        </Box>
      </TableCell>
      <TableCell align="right">
        <Typography variant="caption">
          {formatMoney(
            item?.sale_price * (qty ?? item.qty) - (discount ?? item.discount)
          )}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
