import { Tabs, Tab, Box } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

const STab = styled(Tab)(({ theme }) => {
  return {
    fontSize: "14px",
    fontWeight: "bold",
  };
});

export default function CompanyLinkTab({ setTabIndex, tabIndex }) {
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="secondary tabs example"
      >
        <STab value={0} label="Books" sx={{ textTransform: "capitalize" }} />
        <STab value={1} label="Users" sx={{ textTransform: "capitalize" }} />
        <STab value={2} label="Couriers" sx={{ textTransform: "capitalize" }} />
        <STab value={3} label="Setting" sx={{ textTransform: "capitalize" }} />

        {/* <STab
                    value={2}
                    label="Activity"
                    sx={{ textTransform: "capitalize" }}
                />
                <STab
                    value={3}
                    label="User Info"
                    sx={{
                        display: tabIndex == 3 ? "block" : "none",
                        textTransform: "capitalize",
                    }}
                />
                <STab
                    value={4}
                    label="Campaign Info"
                    sx={{
                        display:
                            tabIndex == 4 ||
                            tabIndex == 5 ||
                            tabIndex == 6 ||
                            tabIndex == 7
                                ? "block"
                                : "none",
                        textTransform: "capitalize",
                    }}
                />
                <STab
                    value={5}
                    label="Promotion Info"
                    sx={{
                        display: tabIndex == 5 ? "block" : "none",
                        textTransform: "capitalize",
                    }}
                />
                <STab
                    value={6}
                    label="Coupon Info"
                    sx={{
                        display: tabIndex == 6 ? "block" : "none",
                        textTransform: "capitalize",
                    }}
                />
                <STab
                    value={7}
                    label="Terms and Conditions"
                    sx={{
                        display: tabIndex == 7 ? "block" : "none",
                        textTransform: "capitalize",
                    }}
                /> */}
      </Tabs>
    </Box>
  );
}
