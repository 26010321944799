import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Stack,
  IconButton,
  CircularProgress,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import MkButton from "../app/assets/theme/MkButton/index";
import TableToolbar from "../app/components/Table/TableToolbar";
import ProductTableBody from "../components/company/product/ProductTableBody";
import TableFooterPagination from "../app/components/Table/TableFooterPagination";
import { allBookList } from "../features/book/BookApi";
import { changePaginationAllBookData } from "../features/book/BookSlice";
import SimpleInput from "../app/components/SimpleInput";
import YearPicker from "../app/components/YearPicker";
import SimpleSelect from "../app/components/SimpleSelect";
import MkAutoComplete from "../app/assets/theme/MkAutoComplete";
import id from "date-fns/esm/locale/id/index.js";

function ProductTable() {
  const dispatch = useDispatch();
  const { book_list, publisher_list, pagination, allBookStatus } = useSelector(
    (state) => state.AllBookSlice
  );
  const { genres_dropdown } = useSelector((state) => state.GenreSlice);
  console.log(allBookStatus);
  //   const { publisher_list, loading } = useSelector(
  //     (state) => state.companyDetail
  //   );

  // console.log("all Publisher list ", publisher_list);
  /* +++++++++++++++++ Pagination +++++++++++++++++ */

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [bookTitle, setBookTitle] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [releaseYear, setReleaseYear] = useState(null);
  const [publisher, setPublisher] = useState(null);
  const [genreOption, setGenreOption] = useState(null);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? rowsPerPage - book_list?.length
      : rowsPerPage - book_list?.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(
      allBookList({
        page: page + 1,
        limit: rowsPerPage,
        publisher_id: "",
        book_title: "",
        author_name: "",
        released_year: "",
        genre: "",
      })
    );

    // mark to where page reach
    dispatch(
      changePaginationAllBookData({ page: page + 1, limit: rowsPerPage })
    );
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (book_list?.length === 0) {
      setRowsPerPage(10);
      setPage(0);
    }
  }, [pagination]);

  /* --------------------------------------------  */

  useEffect(() => {
    dispatch(
      allBookList({
        page: page + 1,
        limit: rowsPerPage,
        book_title: bookTitle ?? "",
        author_name: authorName ?? "",
        released_year: releaseYear?.$y ?? "",
        publisher_id: publisher?._id ?? "",
        genre: genreOption ?? "",
      })
    );
  }, []);

  return (
    <Box component={Paper} sx={{ padding: "0px 25px 25px 25px" }}>
      <TableToolbar>
        <Stack direction="row" alignItems={"center"} sx={{ flex: "1 1 100%" }}>
          {/* <Typography variant="h4" component="div">
            <Typography variant="subtitle1"> Books </Typography>
          </Typography> */}

          <Box sx={{ width: "200px", marginLeft: "20px", fontSize: "10px" }}>
            <MkAutoComplete
              name="publisher"
              placeholder="Choose Publisher"
              noOptionsText="No Publisher"
              fullWidth
              options={publisher_list}
              getOptionLabel={(publisher_list) =>
                publisher_list?.name ?? publisher_list
              }
              isOptionEqualToValue={(publisher_list, value) =>
                publisher_list._id === value.id
              }
              onChange={(event, newValue) => {
                setPublisher(newValue);
                if (newValue == null || newValue.length < 0) {
                  dispatch(
                    allBookList({
                      publisher_id: "",
                      page: page + 1,
                      limit: rowsPerPage,
                      book_title: bookTitle ?? "",
                      author_name: authorName ?? "",
                      released_year: releaseYear?.$y ?? "",
                      genre: "",
                    })
                  );
                }
              }}
              value={publisher}
            />
          </Box>

          {/* <Box sx={{ width: "230px", marginLeft: "20px" }}>
            <MkAutoComplete
              name="genre"
              placeholder="Choose Genre"
              noOptionsText="No Genre"
              fullWidth
              options={genres_dropdown}
              getOptionLabel={(genres_dropdown) =>
                genres_dropdown?.name ?? genres_dropdown
              }
              isOptionEqualToValue={(genres_dropdown, value) =>
                genres_dropdown.id === value.id
              }
              onChange={(event, newValue) => {
                setGenreOption(newValue);
                if (newValue == null || newValue.length < 0) {
                  dispatch(
                    allBookList({
                      publisher_id: publisher?.id ?? "",
                      page: page + 1,
                      limit: rowsPerPage,
                      book_title: bookTitle ?? "",
                      author_name: authorName ?? "",
                      released_year: releaseYear?.$y ?? "",
                      genre: "",
                    })
                  );
                }
              }}
              value={genreOption}
            />
          </Box> */}

          <Box sx={{ width: "200px", marginLeft: "20px" }}>
            {/* <SimpleInput
              name="genre"
              placeholder="Type Genre"
              value={genreOption}
              onChange={(e) => setGenreOption(e.target.value)}
              endAdornment={
                <IconButton
                  sx={{
                    visibility: genreOption ? "visible" : "hidden",
                  }}
                  onClick={() => {
                    setGenreOption("");
                    dispatch(
                      allBook({
                        id: publisherDetail.id,
                        page: page + 1,
                        limit: rowsPerPage,
                        book_title: bookTitle ?? "",
                        author_name: authorName ?? "",
                        released_year: releaseYear?.$y ?? "",
                        genre: "",
                      })
                    );
                  }}
                >
                  <ClearIcon
                    sx={{
                      fontSize: "14.5px",
                    }}
                  />
                </IconButton>
              }
            /> */}
            <MkAutoComplete
              name="genres"
              fullWidth
              placeholder="Choose Genres"
              options={genres_dropdown}
              isOptionEqualToValue={(option, value) =>
                option?._id === value?.id
              }
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                console.log("from on change", newValue);
                setGenreOption(newValue);
                if (newValue === null) {
                  setGenreOption("");
                  dispatch(
                    allBookList({
                      publisher_id: publisher?._id ?? "",
                      page: page + 1,
                      limit: rowsPerPage,
                      book_title: bookTitle ?? "",
                      author_name: authorName ?? "",
                      released_year: releaseYear?.$y ?? "",
                      genre: "",
                    })
                  );
                }
              }}
              value={genreOption || null}
            />
          </Box>

          <Box sx={{ width: "150px", marginLeft: "20px" }}>
            <SimpleInput
              name="book_title"
              placeholder="Book Name"
              value={bookTitle}
              onChange={(e) => setBookTitle(e.target.value)}
              endAdornment={
                <IconButton
                  sx={{
                    visibility: bookTitle ? "visible" : "hidden",
                  }}
                  onClick={() => {
                    setBookTitle("");
                    dispatch(
                      allBookList({
                        page: page + 1,
                        limit: rowsPerPage,
                        book_title: "",
                        author_name: authorName ?? "",
                        released_year: releaseYear?.$y ?? "",
                        publisher_id: publisher?._id ?? "",
                        genre: genreOption?._id ?? "",
                      })
                    );
                  }}
                >
                  <ClearIcon
                    sx={{
                      fontSize: "14.5px",
                    }}
                  />
                </IconButton>
              }
            />
          </Box>

          <Box sx={{ width: "150px", marginLeft: "20px" }}>
            <SimpleInput
              name="author_name"
              placeholder="Author Name"
              value={authorName}
              onChange={(e) => setAuthorName(e.target.value)}
              endAdornment={
                <IconButton
                  sx={{
                    visibility: authorName ? "visible" : "hidden",
                  }}
                  onClick={() => {
                    setAuthorName("");
                    dispatch(
                      allBookList({
                        page: page + 1,
                        limit: rowsPerPage,
                        book_title: bookTitle ?? "",
                        author_name: "",
                        released_year: releaseYear?.$y ?? "",
                        publisher_id: publisher?._id ?? "",
                        genre: genreOption?._id ?? "",
                      })
                    );
                  }}
                >
                  <ClearIcon
                    sx={{
                      fontSize: "14.5px",
                    }}
                  />
                </IconButton>
              }
            />
          </Box>

          <Box
            sx={{
              width: "155px",
              marginLeft: "20px",
            }}
          >
            <YearPicker
              views={["year"]}
              placeholder="Release Year"
              value={releaseYear}
              onChange={(newValue) => {
                setReleaseYear(newValue);
                if (newValue == null || newValue.length < 0) {
                  dispatch(
                    allBookList({
                      publisher_id: "",
                      page: page + 1,
                      limit: rowsPerPage,
                      book_title: bookTitle ?? "",
                      author_name: authorName ?? "",
                      released_year: "",
                      genre: genreOption?._id ?? "",
                    })
                  );
                }
              }}
            />
          </Box>

          <MkButton
            sx={{ marginLeft: "10px" }}
            mkcolor={`linear-gradient(310deg, #0adc7f, #02f377)`}
            size="large"
            textTransform="capitalize"
            onClick={() => {
              console.log(genreOption);
              dispatch(
                allBookList({
                  page: page + 1,
                  limit: rowsPerPage,
                  book_title: bookTitle ?? "",
                  author_name: authorName ?? "",
                  released_year: releaseYear?.$y ?? "",
                  publisher_id: publisher?._id ?? "",
                  genre: genreOption?._id ?? "",
                })
              );
            }}
          >
            Search
          </MkButton>
        </Stack>
      </TableToolbar>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">Cover Image</Typography>
              </TableCell>
              <TableCell sx={{ width: "200px" }}>
                <Typography variant="subtitle2">Title</Typography>
              </TableCell>

              <TableCell>
                <Typography variant="subtitle2">Publisher</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">ISBN/စာစဥ်</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Edition</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Release</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Release Date</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" textAlign="center">
                  COGS <br />
                  (Cost Of Goods Sold)
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Units</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">UCost</Typography>
              </TableCell>
              {/* <TableCell>
                <Typography variant="subtitle2">Expenses</Typography>
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {allBookStatus === "pending" ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 10,
                  marginLeft: 40,
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                {book_list?.map((row, index) => (
                  <ProductTableBody
                    item={row}
                    key={index}
                    companyDetail={null}
                  />
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53.3 * emptyRows }}>
                    <TableCell colSpan={5} />
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
          {allBookStatus === "pending" ? (
            <></>
          ) : (
            <>
              {book_list?.length > 0 && (
                <TableFooterPagination
                  rowsPerPageOptions={[10, 20, 30]}
                  tableList={pagination?.total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}

export default ProductTable;
