import {
  AdminPanelSettings,
  Business,
  Dashboard,
  AutoStories,
  MenuBook,
  MonetizationOn,
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Routes = [
  {
    text: "Dashboard",
    route: "dashboard",
    icon: <Dashboard style={{ color: "#000000" }} />,
  },
  {
    text: "Publishers",
    route: "publishers",
    icon: <Business style={{ color: "#000000" }} />,
  },
  {
    text: "Books",
    route: "books",
    icon: <AutoStories style={{ color: "#000000" }} />,
  },
  {
    text: "Expense Category",
    route: "expense_category",
    icon: <FontAwesomeIcon icon="puzzle-piece" size="xl" color="#000000" />,
  },
  {
    text: "Genre",
    route: "genre",
    icon: <MenuBook style={{ color: "#000000" }} />,
  },
  {
    text: "Author",
    route: "author",
    icon: <Dashboard style={{ color: "#000000" }} />,
  },
  {
    text: "Admins",
    route: "admins",
    icon: <AdminPanelSettings style={{ color: "#000000" }} />,
  },
  {
    text: "Sales",
    route: "sales",
    icon: <MonetizationOn style={{ color: "#000000" }} />,
  },
];
