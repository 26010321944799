import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Divider,
  Drawer,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SimpleInput from "../../app/components/SimpleInput";
import MkButton from "../../app/assets/theme/MkButton";
import IconPicker from "../../app/components/IconPicker";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import IconWrap from "../../app/components/IconWrap";
import MkAutoComplete from "../../app/assets/theme/MkAutoComplete";
import InputFormComponent from "../../app/components/Form/InputFormComponent";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

export default function AuthorDrawer({
  open: drawerOpen,
  onClose: drawerOnClose,
  inputValues = null,
  action,
  children,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();

  // extra
  const { paginationData } = useSelector((state) => state.AuthorSlice);

  /* +++++++++++++++++ Author +++++++++++++++++ */

  const [alias, setAlias] = useState(null);
  const [nameTag, setNameTag] = useState([]);
  const [authorName, setAuthorName] = useState(null);
  const [registrationNo, setRegistrationNo] = useState(null);
  const [note, setNode] = useState(null);

  const closeNow = () => {
    setAlias(null);
    setNameTag([]);
    setAuthorName(null);
    setRegistrationNo(null);
    drawerOnClose();
    setNode(null);
  };

  const authorClick = () => {
    const data = {
      alias: alias,
      name_tag: inputValues ? `#${authorName}#${nameTag.join("#")}` : null,
      name: authorName,
      registration_id: registrationNo,
      note: note,
    };
    // console.log(data);
    if (authorName) {
      // For update
      if (inputValues?._id) {
        dispatch(
          action({
            data: data,
            id: inputValues?._id,
            page: paginationData.page,
            limit: paginationData.limit,
          })
        );
        closeNow();
        return;
      }
      // For add
      dispatch(
        action({
          data: data,
          page: paginationData.page,
          limit: paginationData.limit,
        })
      );
      closeNow();
    }
  };

  /* ----------------------------------------------------  */

  /* +++++++++++++++++ This will set up default value +++++++++++++++++ */

  useEffect(() => {
    // console.log(inputValues);
    if (inputValues) {
      // console.log(inputValues);
      setRegistrationNo(inputValues.registrationNo);
      setAlias(inputValues.alias);
      setNameTag(inputValues?.name_tag?.split("#").slice(2) || []);
      setAuthorName(inputValues.name);
      // setRegistrationNo(inputValues.registration_id);
    }
  }, [drawerOpen]);

  /* --------------------------------------------  */
  // console.log(inputValues);
  return (
    <div>
      {children}
      <Drawer anchor="right" open={drawerOpen} onClose={closeNow}>
        <DrawerHeader>
          <IconButton onClick={closeNow}>
            <CloseIcon />
          </IconButton>

          {/* here is as your desire */}

          <Typography variant="subtitle2" sx={{ marginRight: "40px" }}>
            {inputValues?.name ? `Update ${inputValues?.name} ` : "Add "}
            New Author
          </Typography>

          {/* --------------------------------------------  */}
        </DrawerHeader>
        <Divider />
        <Box sx={{ width: 450 }}>
          {/* here is as your desire */}

          <Stack
            direction="column"
            spacing={2}
            justifyContent="center"
            sx={{ padding: 5 }}
          >
            <InputLabel htmlFor="name" sx={{ fontSize: "14.5px" }}>
              Author Registration No
            </InputLabel>
            <SimpleInput
              name="name"
              placeholder="Author Registration No"
              autoFocus={true}
              value={registrationNo || ""}
              onChange={(e) => setRegistrationNo(e.target.value)}
            />
            <InputLabel htmlFor="name" sx={{ fontSize: "14.5px" }}>
              Author Name
            </InputLabel>
            <SimpleInput
              name="name"
              placeholder="Author Name"
              value={authorName || ""}
              onChange={(e) => setAuthorName(e.target.value)}
              required
            />

            <InputLabel htmlFor="name" sx={{ fontSize: "14.5px" }}>
              Author Alias
            </InputLabel>
            <SimpleInput
              name="alias"
              required
              placeholder="Author Alias"
              value={alias || ""}
              onChange={(e) => setAlias(e.target.value)}
            />

            <InputFormComponent
              multiline={true}
              rows={3}
              value={note}
              onChange={(e) => setNode(e.currentTarget.value)}
              label="Author Note"
              name="author_note"
              placeholder="Enter Author Note"
            />

            {inputValues && (
              <Box>
                <InputLabel htmlFor="alias" sx={{ fontSize: "14.5px" }}>
                  Search Author
                </InputLabel>
                <MkAutoComplete
                  fullWidth={true}
                  multiple
                  freeSolo
                  name="author"
                  placeholder="Search author"
                  options={nameTag}
                  onChange={(event, newValue) => {
                    setNameTag(newValue);
                  }}
                  value={nameTag}
                />
              </Box>
            )}

            <MkButton
              mkcolor={`linear-gradient(310deg, #2152ff, #02c6f3)`}
              size="small"
              onClick={authorClick}
            >
              {inputValues ? "Update" : "Add"}
            </MkButton>
          </Stack>

          {/* --------------------------------------------  */}
        </Box>
      </Drawer>
    </div>
  );
}
