import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../app/hooks";
import { localStorageHandler, onSetValid } from "./LoginSlice";

export const getToken = createAsyncThunk(
  "getToken/login",
  async (data, { dispatch }) => {
    const response = await fetch(`${api}/admin/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    console.log(result);
    if (!result.message) {
      dispatch(onSetValid(result));
      const expiredDate = result.admin.token_expired_at;
      const expiredTime = new Date(expiredDate).getTime();
      console.log(expiredTime);
      dispatch(localStorageHandler(expiredTime));
    }
    console.log(result);
    return result;
  }
);
